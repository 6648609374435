import { Platform } from '@/config'
import { Message } from 'element-ui'

let _vueRouter = null

const commonRouter = {
  /**
   * Operation和teller 公用页面的跳转
   * @param {*} teller  { id: string, subId?: string, name: string, data?: any  }
   * @param {*} operation  参考 $router.push
   * @returns
   */
  push: function (options) {
    if (Platform === 'teller') {
      if (!window.Teller__WorkspaceView) {
        Message.error('Route error occurred, please contact system admin')
        return
      }
      if (!options.teller) {
        console.error('please provide teller page id')
        return
      }
      window.Teller__WorkspaceView.openContsPage({
        type: 'open-conts-page',
        pageHandler: options.teller.id,
        pageArg: options.teller.subId,
        pageDPName: options.teller.name,
        pageInitialize: true,
        pageRenderInfo: options.teller.data || {}
      })
    } else if (Platform === 'operation') {
      if (!_vueRouter) {
        Message.error('Route error occurred, please contact system admin')
        return
      }
      _vueRouter.push(options.operation)
    } else if (Platform === 'merchant') {
      if (!_vueRouter) {
        Message.error('Route error occurred, please contact system admin')
        return
      }
      _vueRouter.push(options.operation)
    }
  }
}

export default commonRouter

export function initCommonRouter(vueRouter) {
  _vueRouter = vueRouter
  return commonRouter
}
