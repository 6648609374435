<template>
  <span v-if="type === 'text'">{{ textContent }}</span>
  <my-select
    v-else-if="level === 'country'"
    :value="value"
    :disabled="disabled"
    @input="onInput"
    filterable
    :options="countryList"
  ></my-select>
  <el-cascader
    v-else-if="loaded"
    :props="addressPickerProps"
    :value="value"
    :disabled="disabled"
    @input="onInput"
    filterable
  ></el-cascader>
  <div v-else></div>
</template>

<script>
import { Cascader } from 'element-ui'
import api from '@/api'
const cacheMap = {}
const cachePromiseMap = {
  level0: null,
  level1: {},
  level2: {}
}
const regionLoader = async (node, resolve) => {
  const { level, value } = node
  if (level === 0) {
    if (!cachePromiseMap.level0) {
      cachePromiseMap.level0 = api.post({
        url: '/mbrshpLvl/addressArea/listCountry',
        data: {}
      })
    }
    const res = await cachePromiseMap.level0
    res.sort((a, b) => {
      return a.name > b.name ? 1 : -1
    })
    const nodes = []
    for (let item of res) {
      if (item.code !== 'MM') {
        nodes.push({
          value: item.code,
          label: item.name,
          leaf: true
        })
      } else {
        nodes.unshift({
          value: item.code,
          label: item.name,
          leaf: false
        })
      }
    }
    cacheMap.level0 = nodes
    setTimeout(() => {
      resolve(nodes)
    }, 1000)
  } else if (level === 1) {
    if (!cachePromiseMap.level1[value]) {
      cachePromiseMap.level1[value] = api.post({
        url: '/mbrshpLvl/addressArea/listProvince',
        data: {
          countryCode: value
        }
      })
    }
    const res = await cachePromiseMap.level1[value]

    const nodes = res
      .map(item => {
        return {
          value: item.code,
          label: item.name,
          leaf: false
        }
      })
      .sort((a, b) => {
        return a.label > b.label ? 1 : -1
      })
    cacheMap[value] = nodes
    resolve(nodes)
  } else if (level === 2) {
    if (!cachePromiseMap.level2[value]) {
      cachePromiseMap.level2[value] = api.post({
        url: '/mbrshpLvl/addressArea/listCity',
        data: {
          provinceCode: value
        }
      })
    }
    const res = await cachePromiseMap.level2[value]
    const nodes = res
      .map(item => {
        return {
          value: item.code,
          label: item.name,
          leaf: true
        }
      })
      .sort((a, b) => {
        return a.label > b.label ? 1 : -1
      })
    cacheMap[value] = nodes
    resolve(nodes)
  }
}
export default {
  name: 'AddressPikcer',
  // 'valueChanged': 当value改变时，触发reload element级联组件 props value改变时没有显示label
  props: ['type', 'value', 'disabled', 'level', 'valueChanged'],
  components: {
    'el-cascader': Cascader
  },
  data() {
    return {
      addressPickerProps: {
        lazy: true,
        lazyLoad: regionLoader
      },
      loaded: true,
      countryList: [],
      textContent: ''
    }
  },
  watch: {
    value(val) {
      if (this.type === 'text') {
        this.getValueTextById(val)
      }
    },
    valueChanged(changed) {
      if (changed) {
        this.reload()
      }
    }
  },
  created() {
    if (this.level === 'country') {
      regionLoader({ level: 0 }, countryList => {
        this.countryList = countryList
      })
    }
    if (this.type === 'text') {
      this.getValueTextById(this.value)
    }
  },
  methods: {
    async getValueTextById(value) {
      if (!value || value.length === 0) {
        this.textContent = ''
        return
      }
      if (Array.isArray(value)) {
        let country = await this.getTextByIdAndLevel('', value[0], 0)
        this.textContent = country
        if (value[1]) {
          let provName = await this.getTextByIdAndLevel(value[0], value[1], 1)
          this.textContent = this.textContent + '/' + provName
        }
        if (value[2]) {
          let cityName = await this.getTextByIdAndLevel(value[1], value[2], 2)
          this.textContent = this.textContent + '/' + cityName
        }
      } else {
        this.textContent = await this.getTextByIdAndLevel('', value, 0)
      }
    },
    async getTextByIdAndLevel(pvalue, value, level) {
      if (!value) return ''
      return new Promise(resolve => {
        regionLoader({ level: level, value: pvalue }, list => {
          let text = this.$util.getLabel(value, list)
          resolve(text)
        })
      })
    },
    onInput(value) {
      this.$emit('input', value)
      if (this.level === 'country') {
        return
      }
      const name = {
        cityName: '',
        provName: '',
        counName: ''
      }
      if (value[0]) {
        for (let item of cacheMap.level0) {
          if (item.value === value[0]) {
            name.counName = item.label
            break
          }
        }
      }
      if (value[1]) {
        for (let item of cacheMap[value[0]]) {
          if (item.value === value[1]) {
            name.provName = item.label
            break
          }
        }
      }
      if (value[2]) {
        for (let item of cacheMap[value[1]]) {
          if (item.value === value[2]) {
            name.cityName = item.label
            break
          }
        }
      }
      this.$emit('update:name', name)
    },
    reload() {
      this.loaded = false
      this.$nextTick(() => {
        this.loaded = true
      })
    }
  }
}
</script>

<style></style>
