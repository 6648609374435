export default [
  // {
  //   path: '/live_demo',
  //   name: 'live_demo',
  //   meta: {
  //     auth: false
  //   },
  //   component: () => import(/* webpackChunkName: "live" */ './Live.vue')
  // },
  {
    path: '/live_monitoring',
    name: 'live_monitoring',
    component: () =>
      import(/* webpackChunkName: "live" */ './LiveMonitoring.vue')
  },
  {
    path: '/live_replay_manager',
    name: 'live_replay_manager',
    component: () =>
      import(/* webpackChunkName: "live" */ './LiveReplayManager.vue')
  },
  {
    path: '/live_time_manager',
    name: 'live_time_manager',
    component: () =>
      import(/* webpackChunkName: "live" */ './LiveTimeManager.vue')
  },
  {
    path: '/live_gift',
    name: 'live_gift',
    component: () =>
      import(/* webpackChunkName: "live_gift" */ './LiveGift.vue')
  },
  {
    path: '/trusty_video',
    name: 'trusty_video',
    component: () =>
      import(/* webpackChunkName: "trusty_video" */ './TrustyVideo.vue')
  }
]
