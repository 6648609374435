<template>
  <el-avatar :size="size" :src="avatarurl" @error="errorHandler">
    <i class="el-icon-user-solid" :style="{ fontSize: iconSize + 'px' }"></i>
  </el-avatar>
</template>

<script>
export default {
  name: 'chat-avatar',
  props: {
    size: {
      type: Number,
      default: 40
    },
    username: {
      type: String
    },
    url: {
      type: String
    }
  },
  computed: {
    iconSize() {
      return this.size * 0.5
    },
    avatarurl() {
      if (this.url) return this.url
      if (!this.$store) return ''
      let user = this.$store.state.chat.usersInfo[this.username] || {}
      return user.avatarurl
    }
  },
  methods: {
    errorHandler() {
      return true
    }
  }
}
</script>
