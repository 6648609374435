import Vue from 'vue'
import { Platform } from '@/config'
import api from '@/api'
import * as fileApi from '@/api/file'
import * as util from '@/util'
import * as filters from '@/util/filters'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import plugins from '@/util/plugins'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import { getPgCnt } from '@/components/MyPagination/util'
import { installDialog } from '@/mixins/Dialog'
import {
  Pagination,
  Dialog,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Input,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Switch,
  Select,
  Option,
  OptionGroup,
  Button,
  ButtonGroup,
  Table,
  TableColumn,
  DatePicker,
  TimePicker,
  Popover,
  Tooltip,
  Form,
  FormItem,
  Tabs,
  TabPane,
  Alert,
  Icon,
  Row,
  Col,
  Card,
  Container,
  Header,
  Aside,
  Main,
  Divider,
  Image,
  Loading,
  MessageBox,
  Message,
  Scrollbar,
  Empty,
  Descriptions,
  DescriptionsItem,
  Rate,
  Avatar,
  Result,
  Upload,
  Tree,
  Transfer,
  InputNumber,
  Link
} from 'element-ui'

Vue.use(Aside)
Vue.use(Result)
Vue.use(Rate)
Vue.use(Avatar)
Vue.use(DescriptionsItem)
Vue.use(Descriptions)
Vue.use(Empty)
Vue.use(Scrollbar)
// Vue.use(InfiniteScroll)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Input)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Checkbox)
Vue.use(CheckboxButton)
Vue.use(CheckboxGroup)
Vue.use(Switch)
Vue.use(Select)
Vue.use(Option)
Vue.use(OptionGroup)
Vue.use(Button)
Vue.use(ButtonGroup)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(DatePicker)
Vue.use(TimePicker)
Vue.use(Popover)
Vue.use(Tooltip)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Alert)
Vue.use(Icon)
Vue.use(Row)
Vue.use(Col)
Vue.use(Card)
Vue.use(Container)
Vue.use(Header)
Vue.use(Main)
Vue.use(Divider)
Vue.use(Image)
Vue.use(Upload)
Vue.use(Tree)
Vue.use(Transfer)
Vue.use(InputNumber)
Vue.use(Link)
// 设置语言
locale.use(lang)

const requireComponents = require.context('@/components', true, /.vue$/)
requireComponents.keys().forEach(file => {
  const name = file.split('/').pop().replace('.vue', '')
  const comp = requireComponents(file).default
  Vue.component(name, comp)
})

// 配置全局过滤器
for (let key in filters) {
  if (Object.hasOwnProperty.call(filters, key)) {
    Vue.filter(key, filters[key])
  }
}

// 添加公用函数引用
Vue.prototype.$util = util
Vue.prototype.$filters = filters
Vue.prototype.$platform = Platform
Vue.prototype.$getPgCnt = getPgCnt

Vue.use(Loading.directive)
Vue.use(plugins)
Vue.prototype.$loading = function (options = {}) {
  return Loading.service({
    text: 'loading',
    background: 'rgba(0,0,0,0.3)',
    ...options
  })
}
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$message = Message

Vue.prototype.$api = api
Vue.prototype.$fileApi = fileApi
dayjs.extend(calendar)
Vue.prototype.dayjs = dayjs
Vue.prototype.$ELEMENT = { size: 'mini' }
Dropdown.props.size.default = 'middle'
Table.props.border.default = true
TableColumn.props.resizable.default = true
TableColumn.props.align.default = 'center'

Form.props.labelPosition.default = 'left'
DatePicker.props.defaultTime = {
  type: [Array, String],
  default: () => ['00:00:00', '23:59:59']
}
DatePicker.props.format = {
  type: String,
  default: 'yyyy/MM/dd'
}
Dialog.props.closeOnClickModal.default = false
Dialog.props.closeOnPressEscape.default = false
installDialog()
