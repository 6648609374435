export default [
  {
    name: 'customer_management',
    path: 'customer_management',
    component: () =>
      import(/* webpackChunkName: "customer" */ './CustomerList.vue')
  },
  {
    name: 'customer_detail',
    path: 'customer_detail',
    component: () =>
      import(
        /* webpackChunkName: "customer" */ './CustomerDetail/CustomerDetail.vue'
      )
  },
  {
    name: 'imReport',
    path: 'imReport',
    component: () =>
      import(/* webpackChunkName: "ManualSettings" */ './IMReport/IMReport.vue')
  },
  {
    name: 'imReportList',
    path: 'imReport/list',
    meta: {},
    component: () =>
      import(
        /* webpackChunkName: "ManualSettings" */ './IMReport/DetailList.vue'
      )
  },
  {
    name: 'thirdPartLoginReport',
    path: 'thirdPartLoginReport',
    component: () =>
      import(
        /* webpackChunkName: "thirdPartLoginReport" */ './ThirdPartLoginReport.vue'
      )
  },
  {
    name: 'inquire_account',
    path: '/inquire_account',
    component: () =>
      import(/* webpackChunkName: "customer" */ './Account/Index.vue')
  },
  {
    name: 'inquire_bonus_serial',
    path: 'inquire_bonus_serial',
    component: () =>
      import(/* webpackChunkName: "customer" */ './InquireBonusSerial.vue')
  },
  {
    name: 'customer_exception',
    path: 'customer_exception',
    component: () =>
      import(
        /* webpackChunkName: "customer" */ './Exception/CustomerException.vue'
      )
  }
]
