export default [
  {
    name: 'messageManages',
    path: 'messageManages',
    component: () =>
      import(/* webpackChunkName: "messageManages" */ './MessageSending.vue')
  },
  {
    name: 'systemMessageList',
    path: '/messageManages/histroy',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "systemMessageList" */ './MessageList.vue')
  }
]
