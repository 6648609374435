export default [
  {
    name: 'schedule',
    path: 'schedule',
    component: () =>
      import(/* webpackChunkName: "schedule" */ './StaffSchedule.vue')
  },
  {
    name: 'emotionUpload',
    path: 'emotionUpload',
    component: () =>
      import(/* webpackChunkName: "emotionUpload" */ './Emotion/index.vue')
  },
  {
    name: 'emotionUploadDetail',
    path: '/emotionUpload/detail',
    meta: {},
    component: () =>
      import(
        /* webpackChunkName: "emotionUpload" */ './Emotion/EmotionDetail.vue'
      )
  },
  {
    name: 'levelRuleManagement',
    path: 'levelRuleManagement',
    component: () =>
      import(/* webpackChunkName: "LevelRuleManagement" */ './MemberLevel.vue')
  },
  {
    name: 'realNamePageManages',
    path: 'realNamePageManages',
    component: () =>
      import(/* webpackChunkName: "realNamePageManages" */ './RealNameAuth.vue')
  },
  {
    name: 'autoreply',
    path: 'autoreply',
    component: () =>
      import(/* webpackChunkName: "autoreply" */ './AutoReply/index.vue')
  }
]
