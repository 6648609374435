import i18n from '@/i18n'
i18n.mergeLocaleMessage('en', {
  "chat.unknownError": "Unknown error",
  "notExist": "File does not exist",
  "recallTimeOut": "Recall time out",
  "unknownError": "Unknown error"
})
i18n.mergeLocaleMessage('zh', {
  "chat.unknownError": "未知出错误",
  "notExist": "文件不存在",
  "recallTimeOut": "撤回超时",
  "unknownError": "未知错误"
})
i18n.mergeLocaleMessage('mm', {
  "chat.unknownError": "Unknown Error",
  "notExist": "ဖိုင်မရှိပါ",
  "recallTimeOut": "ပြန်လည်ရုတ်သိမ်းရန်အချိန်ကုန်ခြင်း",
  "unknownError": "Unknown error"
})
  