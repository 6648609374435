export default [
  {
    name: 'transactionManage', // 交易管理
    path: 'transactionManage',
    component: () =>
      import(/* webpackChunkName: "openApi" */ './TransactionManage.vue')
  },
  {
    name: 'fundSettlement', // 结算资金列表
    path: 'fundSettlement',
    component: () =>
      import(/* webpackChunkName: "openApi" */ './FundSettlement.vue')
  },
  {
    name: 'accountStatement', // 对账单列表
    path: 'fundSettlement/account',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "openApi" */ './AccountStatement.vue')
  },
  {
    name: 'statementSummary', // 对账单汇总
    path: 'statementSummary',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "openApi" */ './StatementSummary.vue')
  }
]
