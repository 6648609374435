<template>
  <div
    :class="{
      'id-card-input': true,
      'id-card-input--mm': isMMIdCardType
    }"
  >
    <template v-if="isMMIdCardType">
      <el-cascader
        :value="mmIDValue.pickData"
        :disabled="disabled"
        :options="options"
        :props="{ expandTrigger: 'hover' }"
        @input="onIdCardInput"
        filterable
      ></el-cascader>
      <el-input
        :value="mmIDValue.extraCode"
        :disabled="disabled"
        @input="onIdCardNumberInput"
        maxlength="6"
      ></el-input>
    </template>
    <el-input
      v-else
      :value="value"
      :disabled="disabled"
      @input="onInput"
    ></el-input>
  </div>
</template>

<script>
import { Cascader } from 'element-ui'
function getRegularData(value) {
  let pickData = []
  let extraCode = ''
  if (value) {
    let s = value.replace(/\s/g, '')
    s = s.replace(/[\/\(\)]/g, ';')
    let [region, town, extra, code] = s.split(';')
    if (region) {
      pickData.push(region)
    }
    if (town) {
      pickData.push(town)
    }
    if (extra) {
      pickData.push(extra)
    }
    extraCode = code
    if (pickData.length !== 3) {
      pickData = []
      extraCode = ''
    }
  }
  return {
    pickData,
    extraCode
  }
}
export default {
  name: 'IdCardInput',
  props: ['value', 'disabled', 'nation', 'idCardType'],
  components: {
    'el-cascader': Cascader
  },
  computed: {
    mmIDValue() {
      return getRegularData(this.value)
    },
    isMMIdCardType() {
      return this.nation === 'MM' && this.idCardType === '01'
    }
  },
  data() {
    let options = []
    let pickData = getRegularData(this.value).pickData
    if (pickData.length === 3) {
      let v1 = pickData[0]
      let v2 = pickData[1]
      let v3 = pickData[2]
      options = [
        {
          value: v1,
          label: v1,
          children: [
            {
              value: v2,
              label: v2,
              children: [{ value: v3, label: v3 }]
            }
          ]
        }
      ]
    }
    return {
      options: options
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      let res = await this.$api.post({
        url: 'common/nrcdict/queryNrcDict',
        data: {}
      })
      if (res) {
        let options = []
        let nrcNationalCds = res.nrcNationalCds || []
        let townshipCodeArr = nrcNationalCds.map(item => item.code)
        let extraArr = townshipCodeArr.map(i => {
          return { value: i, label: i }
        })
        res.list.forEach(element => {
          let id = element.stateCd.replace('/', '')
          options.push({
            value: id,
            label: id,
            children: element.townshipCds.map(i => {
              return {
                value: i,
                label: i,
                children: extraArr
              }
            })
          })
        })
        this.options = options.sort((a, b) => {
          return a.value - b.value
        })
      }
    },
    onIdCardInput(value) {
      let extraCode = this.mmIDValue.extraCode
      this.$emit('input', `${value[0]}/${value[1]}(${value[2]})${extraCode}`)
    },
    onIdCardNumberInput(value) {
      if (/^\d*$/.test(value) === false) return
      let pickData = this.mmIDValue.pickData
      this.$emit(
        'input',
        `${pickData[0]}/${pickData[1]}(${pickData[2]})${value}`
      )
    },
    onInput(value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss">
.id-card-input {
  &.id-card-input--mm {
    display: flex;
    flex-direction: row;
    .el-cascader {
      min-width: 100px;
      flex: 1;
    }
    > .el-input {
      flex: 1;
      min-width: 50px;
      max-width: 100px;
      width: 80px;
      margin-left: 4px;
      .el-input__inner {
        padding-left: 6px;
        padding-right: 6px;
      }
    }
  }
}
</style>
