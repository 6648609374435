export default [
  {
    name: 'questionnaireList',
    path: '/questionnaireList',
    component: () =>
      import(
        /* webpackChunkName: "questionnaireList" */ './QuestionnaireList.vue'
      )
  },
  {
    name: 'answerQuestionnaireList',
    path: '/questionnaireList/answer',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "questionnaireList" */ './Answer.vue')
  },
  {
    name: 'rewardList',
    path: '/questionnaireList/rewardList',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "questionnaireList" */ './RewardList.vue')
  },
  {
    name: 'qnaireStatistics',
    path: '/questionnaireList/qnaireStatistics',
    meta: {},
    component: () =>
      import(
        /* webpackChunkName: "questionnaireList" */ './Statistics/index.vue'
      )
  }
]
