import api from '@/api'
import { cbpDate } from '@/util'
import WebIM from '../../webim/WebIM'
import { Message } from 'element-ui'

export default {
  namespaced: true,
  state: {
    conversation: null,
    loading: false,
    conversationList: [],
    callConversation: null // 正在视频语音的聊天
  },
  getters: {
    statistics(state) {
      let waiting = 0
      let connected = 0
      for (let item of state.conversationList) {
        switch (item.reqSts) {
          case '01':
            waiting++
            break
          case '02':
            connected++
            break
          case '03':
            break
          case '04':
            break
        }
      }
      return { waiting, connected }
    }
  },
  actions: {
    sendConnect(context, data) {
      context.dispatch(
        'message/sendMessage',
        {
          msg: {
            channel: data.reqChatId,
            to: data.reqChatId,
            chatType: 'chatRoom',
            messageType: 'event',
            ext: {
              messageType: 'event',
              eventType: 'ServiceConnected',
              reqId: data.reqId,
              userList: [context.rootState.user.imId]
            }
          }
        },
        { root: true }
      )
    },
    async enterConversation(context, data) {
      context.commit('updateConversation', null)
      if (context.state.loading) return
      context.commit('loading', true)
      try {
        // waitng connect 需要加入聊天室
        if (data.reqSts === '01' || data.reqSts === '02') {
          await WebIM.conn.joinChatRoom({ roomId: data.reqChatId })
        }
      } catch (err) {
        context.commit('loading', false)
        Message.error('Join ChatRoom fail')
        console.log('joinChatRoom fail')
        return
      }
      context.commit('updateConversation', data)
      context.commit('loading', false)
      await context.dispatch(
        'message/fetchMessage',
        {
          id: data.reqChatId,
          first: true
        },
        { root: true }
      )
      if (data.reqSts === '01') {
        context.dispatch('sendConnect', data)
        context.commit('updateConversationStatus', { [data.ownerId]: '02' })
      }
    },
    newConversataion({ state, commit, dispatch }, conversation) {
      let list = state.conversationList
      let tempArr = [conversation]
      for (let c of list) {
        if (c.ownerId !== conversation.ownerId) {
          tempArr.push(c)
        }
      }
      dispatch(
        'chat/getUsersInfo',
        { usernames: [conversation.ownerId] },
        { root: true }
      )
      commit('updateConversationList', tempArr)
    },
    async endConversation({ dispatch, commit, state, rootState }) {
      function quitChatRoom() {
        WebIM.conn
          .quitChatRoom({ roomId: state.conversation.reqChatId })
          .then(res => {
            console.log(res)
          })
        commit('updateConversationStatus', {
          [state.conversation.ownerId]: '04'
        })
      }
      dispatch(
        'message/sendMessage',
        {
          msg: {
            channel: state.conversation.reqChatId,
            to: state.conversation.reqChatId,
            chatType: 'chatRoom',
            messageType: 'event',
            ext: {
              messageType: 'event',
              eventType: 'ServiceDisconnected',
              reqId: state.conversation.reqId,
              userList: [rootState.user.imId]
            }
          },
          onSendSuccess: quitChatRoom,
          onSendError: quitChatRoom
        },
        { root: true }
      )
    },
    async customerEndConversation({ commit, state }, msg) {
      WebIM.conn.quitChatRoom({ roomId: msg.ext.roomId }).then(res => {})
      commit('updateConversationStatus', {
        [msg.from]: '04'
      })
    },
    async getConversationList(context) {
      let res = await api.post({
        url: 'custsrvc/chat/staffChatList',
        data: {
          userId: context.rootState.user.userId
        }
      })
      console.log(res, '---getConversationList')
      if (res) {
        let usernames = res.map(item => {
          // item.reqSts = '01'
          return item.ownerId
        })
        context.dispatch('chat/getUsersInfo', { usernames }, { root: true })
        context.commit('updateConversationList', res)
      }
    },
    async checkTimeout({ state, rootState, commit, dispatch }) {
      let conversationList = state.conversationList
      let statusMap = {}
      for (let c of conversationList) {
        let timeout = false
        let closeType = ''
        if (c.reqSts === '01') {
          let waitingDelta = 30 * 60000
          let time = c.reqTime || cbpDate(c.reqDt + c.reqHms)
          time = new Date(time)
          console.log(time, c, '========== ServiceNoResponse check')
          time = time.getTime()
          if (time + waitingDelta < Date.now()) {
            console.log('========== send ServiceNoResponse')
            timeout = true
            closeType = 'ServiceNoResponse'
          }
        } else if (c.reqSts === '02') {
          let tiemoutDelta = 10 * 60000
          let msg = rootState.message.latestMsg[c.reqChatId]
          if (msg && msg.time + tiemoutDelta < Date.now()) {
            timeout = true
            closeType = 'ServiceTimeout'
          }
        }
        if (!timeout) continue
        statusMap[c.ownerId] = '04'
        await WebIM.conn.joinChatRoom({ roomId: c.reqChatId })
        let quitChatRoom = () => {
          WebIM.conn.quitChatRoom({ roomId: c.reqChatId })
        }
        dispatch(
          'message/sendMessage',
          {
            msg: {
              channel: c.reqChatId,
              to: c.reqChatId,
              chatType: 'chatRoom',
              messageType: 'event',
              ext: {
                messageType: 'event',
                eventType: closeType,
                reqId: c.reqId,
                userList: [rootState.user.imId]
              }
            },
            onSendSuccess: quitChatRoom,
            onSendError: quitChatRoom
          },
          { root: true }
        )
      }
      if (Object.keys(statusMap).length > 0) {
        commit('updateConversationStatus', statusMap)
      }
    },
    async onExit({ state, rootState, commit, dispatch }) {
      // 退出登录 结束所有已接入会话
      let conversationList = state.conversationList
      let quitChatRoom = () => {
        console.log('quitChatRoom')
        WebIM.conn
          .quitChatRoom({ roomId: state.conversation.reqChatId })
          .catch(e => {
            console.log(e)
          })
      }
      for (let c of conversationList) {
        if (c.reqSts !== '02') continue
        dispatch(
          'message/sendMessage',
          {
            msg: {
              channel: c.reqChatId,
              to: c.reqChatId,
              chatType: 'chatRoom',
              messageType: 'event',
              ext: {
                messageType: 'event',
                eventType: 'ServiceTimeout',
                reqId: c.reqId,
                userList: [rootState.user.imId]
              }
            },
            onSendSuccess: quitChatRoom,
            onSendError: quitChatRoom
          },
          { root: true }
        )
      }
      commit('clear')
    },
    async updateCallConversation(context, data) {
      context.commit('updateCallConversation', data)
    }
  },
  mutations: {
    clear(state) {
      state.conversationList = []
      state.conversation = null
      state.callConversation = null
    },
    loading(state, loading) {
      state.loading = loading
    },
    updateConversationStatus(state, statusMap) {
      let conversationList = state.conversationList.map(item => {
        if (statusMap[item.ownerId]) {
          return { ...item, reqSts: statusMap[item.ownerId] }
        }
        return item
      })
      if (statusMap[state.conversation?.ownerId]) {
        state.conversation = {
          ...state.conversation,
          reqSts: statusMap[state.conversation.ownerId]
        }
      }
      state.conversationList = conversationList
    },
    updateConversationList(state, data) {
      state.conversationList = data
    },
    updateConversation(state, data) {
      state.conversation = data
    },
    updateCallConversation(state, data) {
      state.callConversation = data
    }
  }
}
