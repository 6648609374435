export default [
  {
    path: '/rental/report/naypyitaw/home_rental',
    name: 'NayPyiTawHomeRental',
    component: () =>
      import(
        /* webpackChunkName: "rental_naypyitaw_report" */ './HomeRental.vue'
      )
  },
  {
    path: '/rental/report/naypyitaw/home_bill',
    name: 'NayPyiTawCivilServent',
    component: () =>
      import(/* webpackChunkName: "rental_naypyitaw_report" */ './HomeBill.vue')
  },
  {
    path: '/rental/report/naypyitaw/deposit',
    name: 'NayPyiTawDepositReceivedList',
    component: () =>
      import(
        /* webpackChunkName: "rental_naypyitaw_report" */ './DepositReceivedList.vue'
      )
  },
  {
    path: '/rental/report/naypyitaw/home_due',
    name: 'NayPyiTawMonthlyDue',
    component: () =>
      import(
        /* webpackChunkName: "rental_naypyitaw_report" */ './MonthlyDue.vue'
      )
  },
  {
    path: '/rental/report/naypyitaw/debt',
    name: 'NayPyiTawMonthlyDebtList',
    component: () =>
      import(
        /* webpackChunkName: "rental_naypyitaw_report" */ './MonthlyDebtList.vue'
      )
  },
  {
    path: '/rental/report/naypyitaw/prepaid',
    name: 'NayPyiTawMonthlyPrepaidList',
    component: () =>
      import(
        /* webpackChunkName: "rental_naypyitaw_report" */ './MonthlyPrepaidList.vue'
      )
  },
  {
    path: '/rental/report/naypyitaw/account_head',
    name: 'NayPyiTawAccountHead',
    component: () =>
      import(
        /* webpackChunkName: "rental_naypyitaw_report" */ './AccountHead.vue'
      )
  },
  {
    path: '/rental/report/naypyitaw/na2',
    name: 'NayPyiTawNA2',
    component: () =>
      import(/* webpackChunkName: "rental_naypyitaw_report" */ './NA2.vue')
  }
]
