import i18n from '@/i18n'
i18n.mergeLocaleMessage('en', {
  "NeverExpire": "Never expire"
})
i18n.mergeLocaleMessage('zh', {
  "NeverExpire": "永不过期"
})
i18n.mergeLocaleMessage('mm', {
  "NeverExpire": "Never expire"
})
  