import i18n from '@/i18n'
i18n.mergeLocaleMessage('en', {
  "loginExpired": "Login Time expired, please login again"
})
i18n.mergeLocaleMessage('zh', {
  "loginExpired": "登录状态已超时，请重新登录"
})
i18n.mergeLocaleMessage('mm', {
  "loginExpired": "Login Time expired, please login again"
})
  