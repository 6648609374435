<template>
  <span v-if="type === 'text'">{{ $util.getLabel(value, options) }}</span>
  <el-select
    v-else
    :value="value"
    @input="$emit('input', $event)"
    :loading="loading"
    @change="$emit('change', $event)"
    @blur="$emit('blur', $event)"
    @focus="$emit('focus', $event)"
    v-bind="$attrs"
  >
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    >
    </el-option>
  </el-select>
</template>
<script>
import api from '@/api/index'
const cacheMap = {}
export async function getCodeList(code) {
  if (!cacheMap[code]) {
    cacheMap[code] = api.requestCBB('PCM0038400', {
      CmnCdSvc06In: {
        cdNbr: code
      }
    })
  }
  let res = await cacheMap[code]
  let list = res.CmnCdSvcGetCdListByCdNbrOut?.tbl || []
  const Currency = {
    T0001: true,
    A0440: true
  }
  return list.map(item => {
    return {
      value: item.cd,
      label: Currency[code] ? item.cd : item.cdNm
    }
  })
}
export default {
  props: ['type', 'value', 'code'],
  data() {
    return {
      loading: true,
      options: []
    }
  },
  async created() {
    if (!this.code) {
      this.loading = false
      return
    }
    this.loading = true
    this.options = await getCodeList(this.code)
    this.loading = false
  }
}
</script>
