<i18n src="@/i18n/template/components/QuarterPicker.json"></i18n>
<template>
  <el-popover
    placement="bottom"
    title=""
    content=""
    :width="isRange ? '800' : '400'"
    :value="showCard"
    @input="popoverChange"
  >
    <div
      slot="reference"
      class="el-date-editor el-range-editor el-input__inner el-date-editor--monthrange el-range-editor--mini"
    >
      <i class="el-input__icon el-range__icon el-icon-date"></i>
      <input
        type="text"
        readonly="readonly"
        autocomplete="off"
        class="el-range-input"
        v-model="showText"
      />
      <template v-if="isRange">
        <span class="el-range-separator">-</span>
        <input
          type="text"
          readonly="readonly"
          autocomplete="off"
          class="el-range-input"
          v-model="showText2"
        />
      </template>
      <!-- <i class="el-input__icon el-range__close-icon"></i> -->
    </div>
    <!-- 范围季度选择 -->
    <div v-if="isRange" class="quarter-picker">
      <div class="quarter-picker-year">
        <el-button
          icon="el-icon-d-arrow-left"
          type="text"
          style="color: #303133"
          @click="changeYear('prev', 'start')"
        ></el-button>
        <div class="quarter-picker-year__text">{{ yearStart }}</div>
        <el-button
          v-if="yearStart < yearStart2 - 1"
          icon="el-icon-d-arrow-right"
          type="text"
          style="color: #303133"
          @click="changeYear('next', 'start')"
        ></el-button>
        <el-button
          v-if="yearStart2 > yearStart + 1"
          icon="el-icon-d-arrow-left"
          type="text"
          style="color: #303133"
          @click="changeYear('prev', 'end')"
        ></el-button>
        <div class="quarter-picker-year__text">{{ yearStart2 }}</div>
        <el-button
          icon="el-icon-d-arrow-right"
          type="text"
          style="color: #303133"
          @click="changeYear('next', 'end')"
        ></el-button>
      </div>
      <div class="quarter-picker-range-quarter">
        <div class="quarter-picker-quarter">
          <div
            v-for="(item, index) in quarterList"
            :key="index"
            :class="{
              'quarter-picker-quarter-item': true,
              'quarter-picker-quarter-item--selected': isSelected(
                yearStart + item.value
              ),
              'quarter-picker-quarter-item--disabled': isDisabled(
                yearStart + item.value
              )
            }"
            @click="changeRangeQuarter(yearStart, item)"
          >
            <span>{{ item.name }}</span>
          </div>
        </div>
        <div class="quarter-picker-quarter">
          <div
            v-for="(item, index) in quarterList"
            :key="index"
            :class="{
              'quarter-picker-quarter-item': true,
              'quarter-picker-quarter-item--selected': isSelected(
                yearStart2 + item.value
              ),
              'quarter-picker-quarter-item--disabled': isDisabled(
                yearStart2 + item.value
              )
            }"
            @click="changeRangeQuarter(yearStart2, item)"
          >
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="quarter-picker">
      <!-- 年份切换 -->
      <div class="quarter-picker-year">
        <el-button
          icon="el-icon-d-arrow-left"
          type="text"
          style="color: #303133"
          @click="changeYear('prev')"
        ></el-button>
        <div class="quarter-picker-year__text">{{ yearStart }}</div>
        <el-button
          icon="el-icon-d-arrow-right"
          type="text"
          style="color: #303133"
          @click="changeYear('next')"
        ></el-button>
      </div>

      <!-- 季度切换 -->
      <div class="quarter-picker-quarter">
        <div
          v-for="(item, index) in quarterList"
          :key="index"
          :class="{
            'quarter-picker-quarter-item': true,
            'quarter-picker-quarter-item--selected': isSelected(
              yearStart + item.value
            ),
            'quarter-picker-quarter-item--disabled': isDisabled(
              yearStart + item.value
            )
          }"
          @click="changeQuarter(item)"
        >
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
  </el-popover>
</template>

<script>
export default {
  name: 'QuarterPicker',
  props: {
    value: {
      type: [String, Array],
      default: ''
    },
    max: {
      type: [String, Number, Date],
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showCard: false,
      yearStart: null,
      yearStart2: null,
      rangeValueStart: null,
      quarterList: [
        { value: '01', name: this.$t('Quarter1') },
        { value: '04', name: this.$t('Quarter2') },
        { value: '07', name: this.$t('Quarter3') },
        { value: '10', name: this.$t('Quarter4') }
      ]
    }
  },
  computed: {
    isRange() {
      return this.type === 'range'
    },
    //格式化当前选中的时间并展示
    showText() {
      if (!this.value) {
        return ''
      } else {
        let value = this.value
        if (this.isRange) {
          value = this.value[0] || ''
        }
        if (!value) return ''
        let y = value.substring(0, 4)
        let q = value.substring(4, 6)
        q = this.getQuarterByMonth(q)
        return y + '-Q' + q
      }
    },
    //格式化当前选中的时间并展示
    showText2() {
      if (!this.value) {
        return ''
      } else {
        let value = ''
        if (this.isRange) {
          value = this.value[1] || ''
        }
        if (!value) return ''
        let y = value.substring(0, 4)
        let q = value.substring(4, 6)
        q = this.getQuarterByMonth(q)
        return y + '-Q' + q
      }
    },
    maxDate() {
      if (this.max) {
        let d = new Date(this.max)
        let y = d.getFullYear() || 99999
        let m = d.getMonth() + 1
        if (m < 10) {
          m = '0' + m
        }
        return Number(y + '' + m)
      }
      return 999910
    }
  },
  created() {
    if (!this.value) {
      if (this.isRange) {
        this.yearStart2 = new Date().getFullYear()
        this.yearStart = this.yearStart2 - 1
      } else {
        this.yearStart = new Date().getFullYear()
      }
    } else {
      if (this.isRange) {
        let [start, end] = this.value
        if (start) {
          start = start + ''
          start = Number(start.substring(0, 4))
        } else {
          start = new Date().getFullYear() - 1
        }
        if (end) {
          end = end + ''
          end = Number(end.substring(0, 4))
        } else {
          end = new Date().getFullYear()
        }
        if (start && start === end) {
          start = start - 1
        }
        this.yearStart = start
        this.yearStart2 = end
      } else {
        this.yearStart = Number(this.value.substring(0, 4))
      }
    }
  },
  methods: {
    getQuarterByMonth(month) {
      let m = Number(month)
      if (m < 4) {
        return 1
      }
      if (m < 7) {
        return 2
      }
      if (m < 10) {
        return 3
      }
      return 4
    },
    isSelected(value) {
      if (this.isRange) {
        if (this.rangeValueStart) {
          return this.rangeValueStart === value
        }
        let valueArr = this.value || []
        return valueArr[0] === value || valueArr[1] === value
      }
      return this.value === value
    },
    isDisabled(value) {
      return Number(value) > this.maxDate
    },
    popoverChange(val) {
      this.showCard = val
      if (val) {
        this.rangeValueStart = null
      }
    },
    //切换年份
    changeYear(type = '', startOrEnd) {
      if (startOrEnd === 'end') {
        if (type == 'next') {
          this.yearStart2 = this.yearStart2 + 1
        } else if (type == 'prev') {
          this.yearStart2 = this.yearStart2 - 1
        }
      } else {
        if (type == 'next') {
          this.yearStart = this.yearStart + 1
        } else if (type == 'prev') {
          this.yearStart = this.yearStart - 1
        }
      }
    },
    changeQuarter(quarter) {
      let value = this.yearStart + quarter.value
      if (this.isDisabled(value)) return
      this.showCard = false
      this.$emit('input', value)
      this.$emit('change', value)
    },
    changeRangeQuarter(year, quarter) {
      if (this.isDisabled(year + quarter.value)) return
      if (this.rangeValueStart) {
        let start = this.rangeValueStart
        let end = year + quarter.value
        let valueArr = []
        if (Number(start) > Number(end)) {
          let temp = start
          start = end
          end = temp
        }
        valueArr = [start, end]
        this.$emit('input', valueArr)
        this.$emit('change', valueArr)
        this.showCard = false
        this.rangeValueStart = null
      } else {
        this.rangeValueStart = year + quarter.value
      }
    }
  }
}
</script>

<style lang="scss">
.quarter-picker-input {
  width: 200px;
}
.quarter-picker-year {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e4e7ed;
  .quarter-picker-year__text {
    flex: 1;
    text-align: center;
    line-height: 35px;
    font-size: 16px;
    font-weight: 500;
    color: #333;
  }
}
.quarter-picker-range-quarter {
  display: flex;
  flex-direction: row;
  .quarter-picker-quarter {
    flex: 1;
  }
  .quarter-picker-quarter:first-of-type {
    margin-right: 10px;
    border-right: 1px solid #e5e5e5;
  }
}
.quarter-picker-quarter {
  margin-top: 10px;
  padding: 30px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.quarter-picker-quarter-item {
  flex: 1;
  font-size: 14px;
  color: #333;
  text-align: center;
  padding-right: 12px;
  cursor: pointer;
  > span {
    display: block;
    padding: 4px 6px;
  }
}
.quarter-picker-quarter-item:hover > span {
  background-color: #e5e5e5;
  border-radius: 4px;
}
.quarter-picker-quarter-item--selected > span,
.quarter-picker-quarter-item--selected:hover > span {
  background-color: $--color-primary;
  color: #ffffff;
  border-radius: 4px;
}
.quarter-picker-quarter-item--disabled > span {
  background-color: inherit !important;
  color: #ccc !important;
  cursor: not-allowed;
}
</style>
