import ChatCall from './ChatCall'

let chatCallInstance
let timer
function getChatCallInstance() {
  chatCallInstance = chatCallInstance || ChatCall.newInstance({})
  return chatCallInstance
}

function update(options) {
  let instance = getChatCallInstance()
  instance.update(options)
}

function hide() {
  update({
    show: false
  })
  let instance = getChatCallInstance()
  chatCallInstance = null
  instance.destroy()
}

export default {
  show() {
    update({
      show: true
    })
  },
  hide() {
    update({
      show: false
    })
    hide()
  }
}
