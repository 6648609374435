import axios from 'axios'
import aes from './aes'
import { BaseURL, client_id, client_secret } from '@/config'
import apiClient, { setGetAuthDataFun } from './index'
import { getReqId } from '@/util/index'

let authData = null
// 刷新token的请求单例
let refreshTokenReq = null

let authDataRefreshListener = null

function getToken(grantType, data) {
  let options = {
    url: 'sso/oauth/token',
    data: {
      client_id: client_id,
      client_secret: client_secret,
      grant_type: grantType,
      data: data
    },
    showError: true,
    rawData: true
  }
  return apiClient.post(options).then(res => {
    console.log('tokenApi ', res)
    if (res.data?.expires_in) {
      res.data.expires_time = Date.now() + res.data.expires_in * 1000
    }
    if (res.code) {
      // if (res.code !== BizReturnCode.SUCCESS) {
      //   Pop.showFailPop({
      //     content: res.msg || 'Login Failed'
      //   })
      // }
      return res
    }
    let tData = res
    // 非 {code:'0000', data: ''}结构， 需要转换一下
    if (tData.access_token) {
      if (tData.expires_in) {
        tData.expires_time = Date.now() + tData.expires_in * 1000
      }
      return {
        code: '0000',
        data: tData,
        msg: ''
      }
    }
    return { ...res, msg: res.msg || 'Token is null' }
  })
}

export function setAuthData(data) {
  authData = data
}
export function clearAuthData() {
  if (!authData) return
  let options = {
    url: BaseURL + 'sso/oauth/logout',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Bearer ' + authData.access_token
    },
    data: JSON.stringify({
      reqId: getReqId(),
      data: aes.encrypt('{}', authData.user_secret)
    })
  }
  authData = null
  return axios(options).then(res => {
    console.log(res.data, '----clear token')
  })
}
export function onAuthDateRefresh(fn) {
  authDataRefreshListener = fn
}
function refreshToken(accessToken, secret, refreshToken) {
  let data = {
    grant_type: 'refresh_token',
    client_id: client_id,
    client_secret: client_secret,
    refresh_token: refreshToken
  }
  let options = {
    url: BaseURL + 'sso/oauth/token',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Bearer ' + accessToken
    },
    data: {
      reqId: getReqId(),
      data: aes.encrypt(JSON.stringify(data), secret)
    },
    timeout: 600000
  }

  return axios(options)
    .then(res => {
      if (res.data && typeof res.data.data === 'string') {
        let dataStr = aes.decrypt(res.data.data, secret)
        try {
          let data = JSON.parse(dataStr)
          return data
        } catch (e) {
          console.warn('parse json response error', e)
        }
      }
      return false
    })
    .catch(e => {
      return null
    })
}

// 获取授权token信息 且在过期之前刷新token
async function getAndRefreshAuthData() {
  if (authData) {
    // 1小时候后即将过期
    if (authData.expires_time < Date.now() + 3600000) {
      if (!refreshTokenReq) {
        refreshTokenReq = refreshToken(
          authData.access_token,
          authData.user_secret,
          authData.refresh_token
        )
      }
      let tokenRes = await refreshTokenReq
      tokenRes = tokenRes?.data || tokenRes
      if (tokenRes && tokenRes.access_token) {
        authData = { ...authData, ...tokenRes }
        authData.expires_time = Date.now() + tokenRes.expires_in * 1000
        authDataRefreshListener && authDataRefreshListener(authData)
      }
      refreshTokenReq = null
    }
    return [authData.access_token, authData.user_secret]
  }
  return ['', '']
}

setGetAuthDataFun(getAndRefreshAuthData)
// 10秒钟检查一次token过期时间
setInterval(() => {
  getAndRefreshAuthData()
}, 10000)

function getBrowserName() {
  let userAgent = navigator.userAgent
  let isOpera = userAgent.indexOf('Opera') > -1
  if (isOpera) {
    return 'Opera'
  }
  if (userAgent.indexOf('Firefox') > -1) {
    return 'FireFox'
  }
  if (userAgent.indexOf('Chrome') > -1) {
    return 'Chrome'
  }
  if (userAgent.indexOf('Safari') > -1) {
    return 'Safari'
  }
  if (
    userAgent.indexOf('compatible') > -1 &&
    userAgent.indexOf('MSIE') > -1 &&
    !isOpera
  ) {
    return 'IE'
  }
  return 'Unknown Browser'
}
export default {
  operationPasswordLogin(data) {
    return getToken('password', {
      loinIdNbr: data.loinIdNbr,
      password: data.password,
      code: data.code,
      uuid: data.uuid
    })
  },
  merchantPasswordLogin(data) {
    let deviceNm = `Web (${getBrowserName()})`
    return getToken('password', {
      loinIdNbr: data.loinIdNbr,
      password: data.password,
      loinTpCd: '01',
      code: data.code,
      uuid: data.uuid,
      deviceNm: deviceNm
    })
  },
  merchantQrcodeLogin(qrcode) {
    let deviceNm = `Web (${getBrowserName()})`
    return getToken('qrcode', {
      qrcode: qrcode, // 二维码uuid
      deviceNm: deviceNm
    })
  },
  tellerLoginByToken({ staffId, data }) {
    return getToken('exchange', {
      staffId: staffId,
      data: data // teller 登录的密钥加密数据
    })
  }
}
