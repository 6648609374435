<template>
  <el-date-picker
    :value="value"
    @input="$emit('input', $event)"
    :type="type"
    :placeholder="placeholder"
    :value-format="valueFormat"
    :format="format"
    :picker-options="options"
    :default-time="defaultTime"
    :disabled="disabled"
    :clearable="clearable"
  >
  </el-date-picker>
</template>

<script>
import dayjs from 'dayjs'
export default {
  props: {
    type: String,
    value: String,
    endTime: String,
    startTime: String,
    valueFormat: String,
    format: String,
    placeholder: String,
    defaultTime: String,
    disabled: Boolean,
    clearable: Boolean,
    intervalDays: Number // 相隔天数
  },
  data() {
    return {
      options: {
        disabledDate: time => {
          if (this.startTime) {
            let sT = 0
            if (this.type === 'date') {
              sT = new Date(this.startTime + ' 00:00:00').getTime()
            } else {
              sT = new Date(this.startTime)
              sT.setHours(0)
              sT.setMinutes(0)
              sT.setSeconds(0)
              sT.setMilliseconds(0)
              sT = sT.getTime()
            }
            let t = time.getTime()
            if (this.intervalDays) {
              return (
                t < sT || t > sT + (this.intervalDays - 1) * 1000 * 60 * 60 * 24
              )
            }
            return t < sT
          }
          if (this.endTime) {
            let eT = this.endTime
            if (this.type === 'date') {
              eT = this.endTime + ' 00:00:00'
            }
            if (this.intervalDays) {
              return (
                time.getTime() > new Date(eT).getTime() ||
                time.getTime() <
                  new Date(eT).getTime() -
                    this.intervalDays * 1000 * 60 * 60 * 24
              )
            }
            return time.getTime() > new Date(eT).getTime()
          }
        }
      }
    }
  }
}
</script>
