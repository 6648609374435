<template>
  <el-select
    :value="value"
    @input="$emit('input', $event)"
    :disabled="disabled"
    :clearable="clearable"
    :multiple="multiple"
    :loading="loading"
    :filterable="filterable"
    :collapse-tags="collapseTags"
    @change="$emit('change', $event)"
    @blur="$emit('blur', $event)"
    @focus="$emit('focus', $event)"
  >
    <template v-if="group">
      <el-option-group
        v-for="group in optionsData"
        :key="group.value"
        :label="(group.getLabel && group.getLabel()) || group.label"
      >
        <el-option
          v-for="item in group.options"
          :key="item.value"
          :label="(item.getLabel && item.getLabel()) || item.label"
          :value="item.value"
          :disabled="item.disabled"
        >
        </el-option>
      </el-option-group>
    </template>
    <template v-else>
      <el-option
        v-for="item in optionsData"
        :key="item.value"
        :label="(item.getLabel && item.getLabel()) || item.label"
        :value="item.value"
        :disabled="item.disabled"
      >
      </el-option>
    </template>
  </el-select>
</template>
<script>
export default {
  props: [
    'value',
    'options',
    'clearable',
    'disabled',
    'multiple',
    'group',
    'filterable',
    'getOptions',
    'collapseTags'
  ],
  computed: {
    optionsData() {
      return this.remoteOptions || this.options || []
    }
  },
  data() {
    return {
      loading: false,
      remoteOptions: null
    }
  },
  async created() {
    if (this.getOptions) {
      this.loading = true
      let options = await this.getOptions()
      this.loading = false
      this.remoteOptions = options || null
    }
  }
}
</script>
