export default [
  {
    name: 'customer_black_list',
    path: '/customer_black_list',
    component: () =>
      import(/* webpackChunkName: "black_list" */ './CustomerBlackList.vue')
  },
  {
    name: 'merchant_black_list',
    path: '/merchant_black_list',
    component: () =>
      import(/* webpackChunkName: "black_list" */ './MerchantBlackList.vue')
  },
  {
    name: 'mobile_number_black_list',
    path: '/mobile_number_black_list',
    component: () =>
      import(/* webpackChunkName: "black_list" */ './MobileNumberBlackList.vue')
  }
]
