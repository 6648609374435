<template>
  <el-submenu
    v-if="data.childrenMenu && data.childrenMenu.length > 0"
    :index="data.mnId"
  >
    <template slot="title">
      <el-badge
        :max="99"
        :value="(badgeData && badgeData[data.mnId]) || ''"
        type="danger"
      >
        <i v-if="data.icon" :class="data.icon"></i>
        <span>
          {{ $t(data.i18nKey) }}
        </span>
      </el-badge>
    </template>
    <template v-for="children in data.childrenMenu">
      <my-menu-item
        :key="children.mnId"
        :data="children"
        :badgeData="badgeData"
      />
    </template>
  </el-submenu>
  <el-menu-item v-else :index="data.mnUrl">
    <i v-if="data.icon" :class="data.icon"></i>
    <template slot="title">
      <el-badge
        :max="99"
        :value="(badgeData && badgeData[data.mnId]) || ''"
        type="danger"
      >
        <span>
          {{ $t(data.i18nKey) }}
        </span>
      </el-badge>
    </template>
  </el-menu-item>
</template>

<script>
import { Badge } from 'element-ui'
export default {
  name: 'my-menu-item',
  props: ['data', 'badgeData'],
  components: { [Badge.name]: Badge },
  mounted() {
    // el-submenu $parent need to point to el-menu
    this.$children[0].$parent = this.$parent
  }
}
</script>

<style>
.el-menu--collapse .el-badge .el-badge__content {
  right: 16px !important;
}
</style>
