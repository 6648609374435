<template>
  <el-select
    :value="value"
    @input="$emit('input', $event)"
    :disabled="disabled"
    @change="$emit('change', $event)"
  >
    <el-option
      v-for="item in options"
      :key="item.value"
      :disabled="item.disabled"
      :label="item.label"
      :value="item.value"
    >
    </el-option>
  </el-select>
</template>
<script>
import { ResiterIDCardTP } from '@/constant/index'
export default {
  props: ['value', 'disabled'],
  data() {
    return {
      loading: true,
      options: ResiterIDCardTP.map(item => {
        return {
          ...item,
          disabled: item.value !== '01' && item.value !== '04'
        }
      })
    }
  }
}
</script>
