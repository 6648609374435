<template>
  <el-select
    :value="value"
    v-bind="$attrs"
    @input="$emit('input', $event)"
    @change="onChange"
    @blur="$emit('blur', $event)"
    @focus="$emit('focus', $event)"
  >
    <el-option
      v-for="item in options"
      :key="item.acctNbr"
      :label="hideBalance ? item.acctNbr : item.label"
      :value="item.acctNbr"
    ></el-option>
  </el-select>
</template>
<script>
import api from '@/api/index'
export const AccountTransactionType = {
  ALL: '17',
  Payment: '1',
  Transfer: '2',
  BankTransfer: '3',
  FXTransfer: '4',
  RequestMoney: '5',
  CashIn: '6',
  CashOut: '7',
  RedPacket: '8',
  PaymentPriority: '9',
  FXBuy: '10',
  FXSell: '11',
  Assets: '12',
  AdvanceRepay: '13',
  AdvanceApply: '14',
  CoinsBuy: '15',
  FastTransfer: '16',
  BalanceTopup: '18',
  CoinsSell: '19'
}
/**
 * get account list by type in customer app
 * @param type TransactionType
 * @param currency currency
 * @returns AccountItem[]
 */
export function getCustomerAccountList(type, currency) {
  let currencyList = []
  if (currency) {
    currencyList = Array.isArray(currency) ? currency : [currency]
  }
  return api.post({
    url: 'app/customer/queryTxAcctListByTxTp',
    data: {
      txTp: type, // 交易类型
      crncyCdList: currencyList
    }
  })
}
/**
 * get Counterparty's account list by type in customer app
 * @param type TransactionType
 * @param currency currency
 * @returns AccountItem[]
 */
export function getCustomerCounterpartyAccountList(custId, type, currency) {
  let currencyList = []
  if (currency) {
    currencyList = Array.isArray(currency) ? currency : [currency]
  }
  return api.post({
    url: 'app/customer/queryCpTxAcctListByTxTp',
    data: {
      custId,
      txTp: type, // 交易类型
      crncyCdList: currencyList
    }
  })
}
/**
 * for merchant app
 */
export function getMerchantAccountList(type, currency) {
  let currencyList = []
  if (currency) {
    currencyList = Array.isArray(currency) ? currency : [currency]
  }
  return api.post({
    url: 'app/merchant/queryTxAcctListByTxTp',
    data: {
      txTp: type, // 交易类型
      crncyCdList: currencyList
    }
  })
}
/**
 * for merchant app
 */
export function getMerchantCounterpartyAccountList(custId, type, currency) {
  let currencyList = []
  if (currency) {
    currencyList = Array.isArray(currency) ? currency : [currency]
  }
  return api.post({
    url: 'app/merchant/queryCpTxAcctListByTxTp',
    data: {
      custId,
      txTp: type, // 交易类型
      crncyCdList: currencyList
    }
  })
}
/**
 * for operation
 */
export function operationGetCustomerAccountList(custId, type, currency) {
  let currencyList = []
  if (currency) {
    currencyList = Array.isArray(currency) ? currency : [currency]
  }
  return api.post({
    url: 'merApi/custInfo/queryTxAcctListByTxTp',
    data: {
      custId,
      txTp: type, // 交易类型
      crncyCdList: currencyList
    }
  })
}

export default {
  /**
   * platform: 'customer' | 'merchant' | 'operation',
   * // 交易类型
   * type: AccountTransactionType,
   * // 是否查看交易对象的列表
   * couterparty?: boolean,
   * currency?: 'MMK' | 'USD',
   * // 交易对象的custId, platform为operation 或者 couterparty为true时 必填,
   * custId?: string,
   * hideBalance?: bool, 隐藏余额显示， 默认显示
   */
  props: [
    'value',
    'platform',
    'type',
    'couterparty',
    'custId',
    'currency',
    'set-default',
    'hideBalance'
  ],
  data() {
    return {
      loading: true,
      options: []
    }
  },
  watch: {
    custId() {
      this.fetchData()
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      console.log('---')
      this.loading = true
      let res = {}
      if (this.platform === 'operation') {
        res = await operationGetCustomerAccountList(
          this.custId,
          this.type,
          this.currency
        )
      } else if (this.platform === 'merchant') {
        if (this.couterparty) {
          res = await getCustomerCounterpartyAccountList(
            this.custId,
            this.type,
            this.currency
          )
        } else {
          res = await getCustomerAccountList(this.type, this.currency)
        }
      } else {
        if (this.couterparty) {
          res = await getMerchantCounterpartyAccountList(
            this.custId,
            this.type,
            this.currency
          )
        } else {
          res = await getMerchantAccountList(this.type, this.currency)
        }
      }
      if (res.acctList) {
        this.options = res.acctList.map(item => {
          return {
            ...item,
            label: `${item.acctNbr} (${this.$filters.money(item.balAmt)} ${
              item.crncyCd
            })`
          }
        })
        this.$emit('loaded', res.acctList)
        if (this.setDefault && this.options[0]) {
          this.$emit('input', this.options[0].acctNbr)
        }
      }
      this.loading = false
    },
    onChange(value) {
      for (let item of this.options) {
        if (item.acctNbr === value) {
          this.$emit('change', item)
          return
        }
      }
    }
  }
}
</script>
