<i18n src="@/i18n/template/components/GoogleMap.json"></i18n>
<template>
  <div :id="id" class="google-map-wrap" v-loading="loading"></div>
</template>

<script>
let mapLoader = null
let _geocoder = null
export function loadMap() {
  if (window.google) {
    return Promise.resolve()
  }
  if (!mapLoader) {
    mapLoader = new Promise(resolve => {
      const script = document.createElement('script')
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBeiCotKkN3mOVSmc3HftzwcTMDBiEHsi8&callback=initMap&signature=TRBoM03Sj6_sK0ieFGdYVawKTF0=`
      script.async = true
      window.initMap = () => resolve()
      document.head.appendChild(script)
    })
  }
  return mapLoader
}
export async function getPlaceService() {
  await loadMap()
  // const { Place, AutocompleteSessionToken, AutocompleteSuggestion } =
  return google.maps.importLibrary('places')
}
export function getGeocoder() {
  if (!_geocoder) {
    _geocoder = new google.maps.Geocoder()
  }
  return _geocoder
}
export function getCurrentPosition(options) {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      position => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
        options.onSuccess(pos)
      },
      err => {
        options.onError(true, err)
      },
      { timeout: 60000 }
    )
  } else {
    options.onError(false, "Browser doesn't support Geolocation")
  }
}

export async function geocodeLocation(position) {
  await loadMap()
  return getGeocoder()
    .geocode({ location: position })
    .then(response => {
      if (response.results[0]) {
        let address = response.results[0].formatted_address
        return address
      }
    })
}

export async function geocodeAddress(address) {
  await loadMap()
  return getGeocoder()
    .geocode({ address: address })
    .then(response => {
      if (response.results[0]) {
        let location = response.results[0].geometry.location
        return location
      }
    })
}

let id = 1
export default {
  name: 'google-map',
  props: ['value', 'disabled', 'address'],
  data() {
    id++
    return {
      id: 'map-' + id,
      loading: true
    }
  },
  mounted() {
    this.available = true
    loadMap().then(() => {
      this.initMap()
    })
  },
  beforeDestroy() {
    this.available = false
  },
  methods: {
    initMap() {
      if (!this.available) return
      const initLatlng = this.value || {
        lat: 16.841129085554105,
        lng: 96.17345816568438
      }
      const initAddress = this.address || 'Yangon'
      const map = new google.maps.Map(document.getElementById(this.id), {
        center: initLatlng,
        disableDefaultUI: true,
        zoomControl: true,
        scaleControl: true,
        zoom: 13
      })
      this.map = map
      this.marker = new google.maps.Marker({
        position: initLatlng,
        draggable: this.disabled ? false : true,
        animation: google.maps.Animation.DROP,
        map: map
      })
      if (!this.disabled) {
        const infoWindow = new google.maps.InfoWindow({
          content: this.$t('GooleMapClickTip')
        })
        infoWindow.open({
          anchor: this.marker,
          map
        })
      }
      this.circle = new google.maps.Circle({
        center: initLatlng,
        fillColor: '#409EFF',
        strokeColor: '#409EFF',
        fillOpacity: 0.3,
        strokeOpacity: 1,
        strokeWeight: 1,
        radius: 1000,
        map: map
      })
      map.addListener('click', e => {
        if (this.disabled) return
        const latLng = { lat: e.latLng.lat(), lng: e.latLng.lng() }
        this.onAddressChange(latLng)
      })
      this.marker.addListener('dragend', e => {
        if (this.disabled) return
        const latLng = { lat: e.latLng.lat(), lng: e.latLng.lng() }
        this.onAddressChange(latLng)
      })
      const locationButton = document.createElement('div')
      locationButton.innerHTML = '<i class="el-icon-aim"></i>'
      locationButton.classList.add('googlemap-location')
      locationButton.addEventListener('click', this.getCurrentPosition)
      map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(
        locationButton
      )
      this.loading = false
      if (!this.value) {
        this.$emit('input', initLatlng)
        this.$emit('update:address', initAddress)
        this.geocodeLatLng(initLatlng)
      }
    },
    onAddressChange(position, panToMarker = false) {
      if (panToMarker) {
        this.map.setCenter(position)
      }
      this.marker.setPosition(position)
      this.circle.setCenter(position)
      this.$emit('input', position)
      this.geocodeLatLng(position)
    },
    getCurrentPosition() {
      if (this.disabled) return
      getCurrentPosition({
        onSuccess: position => {
          this.onAddressChange(position, true)
        },
        onError: (browserHasGeolocation, err) => {
          this.handleLocationError(browserHasGeolocation, this.map.getCenter())
        }
      })
    },
    geocodeLatLng(latlng) {
      console.log('geocodeLatLng1', latlng)
      getGeocoder()
        .geocode({ location: latlng })
        .then(response => {
          console.log('geocodeLatLng2', response.results[0])
          if (response.results[0]) {
            let address = response.results[0].formatted_address
            this.$emit('update:address', address)
          } else {
            this.$message.error('No results found')
          }
        })
        .catch(e => {
          console.log('geocodeLatLng3', e)
          this.$message.error('Geocoder failed due to: ' + e)
        })
    },
    handleLocationError(browserHasGeolocation, pos) {
      const infoWindow = new google.maps.InfoWindow()
      infoWindow.setPosition(pos)
      infoWindow.setContent(
        browserHasGeolocation
          ? 'Error: The Geolocation service failed.'
          : "Error: Your browser doesn't support geolocation."
      )
      infoWindow.open(this.map)
    }
  }
}
</script>

<style>
.google-map-wrap {
  width: 100%;
  height: 300px;
}
.googlemap-location {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-right: 10px;
  font-size: 30px;
  cursor: pointer;
  text-align: center;
  color: #666666;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}
.googlemap-location:hover {
  color: #000;
}
</style>
