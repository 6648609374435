import { ButtonPermissionMap } from '../../constant/ButtonPermission'
export default [
  {
    path: '/task',
    name: 'task',
    meta: {
      permission: ButtonPermissionMap.ExportTask
    },
    component: () => import(/* webpackChunkName: "task" */ './Index.vue')
  }
]
