import AES from 'crypto-js/aes'
import ECB from 'crypto-js/mode-ecb'
import Utf8 from 'crypto-js/enc-utf8'
import Pkcs7 from 'crypto-js/pad-pkcs7'

/**
 * 加密
 * @param text 文本
 * @param key 秘钥
 * @return {string}
 */
function encrypt(text, key) {
  if (typeof text !== 'string') {
    text = JSON.stringify(text)
  }
  let _key = Utf8.parse(key) //token 只能16位
  let srcs = Utf8.parse(text)
  let encrypted = AES.encrypt(srcs, _key, { mode: ECB, padding: Pkcs7 })
  return encrypted.toString()
}

/**
 * 解密
 * @param text 文本
 * @param key 秘钥
 * @returns {*}
 */
function decrypt(text, key) {
  if (typeof text !== 'string') {
    text = JSON.stringify(text)
  }
  let _key = Utf8.parse(key)
  let decrypt = AES.decrypt(text, _key, { mode: ECB, padding: Pkcs7 })
  return Utf8.stringify(decrypt).toString()
}

window.__encrypt = encrypt
window.__decrypt = decrypt

export default {
  encrypt,
  decrypt
}
