<i18n src="@/i18n/template/components/ListDetailMoreInfo.json"></i18n>
<template>
  <div v-if="data" style="margin-top: 40px">
    <el-divider @click="showMoreInfo = !showMoreInfo">
      <span style="cursor: pointer; cursor: pointer">
        {{ $t('MoreInfo') }}
        <i
          :class="{
            'el-icon-arrow-up': showMoreInfo,
            'el-icon-arrow-down': !showMoreInfo
          }"
        />
      </span>
    </el-divider>
    <el-descriptions
      v-if="showMoreInfo"
      :column="2"
      border
      :labelStyle="{ width: '120px' }"
      :contentStyle="{ color: '#333', fontSize: '14px' }"
      :size="size || 'mini'"
    >
      <el-descriptions-item
        v-for="item in config"
        :key="item.key"
        :label="item.label"
      >
        <span :style="item.style">
          <span v-if="item.format === 'time'">{{ data[item.key] | time }}</span>
          <span v-else-if="item.format === 'money'">{{
            data[item.key] | money
          }}</span>
          <span v-else-if="item.format === 'cbpDate'">{{
            data[item.key] | cbpDate
          }}</span>
          <span v-else>{{ item.content || data[item.key] }}</span>
        </span>
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
export default {
  props: ['data', 'config', 'size'],
  data() {
    return {
      showMoreInfo: false
    }
  }
}
</script>

<style></style>
