import '@/i18n/template/projects/operation/webim/IMListener.js'
import WebIM from './WebIM'
import i18n from '../../../i18n'
import store from '../store'
import dayjs from 'dayjs'
import { Message } from 'element-ui'
import { convertServerMessage } from '@/util/messageUtil'
import { addEventListener, emitEvent } from '@/util/event'
let msgListeners = [
  /**
   * customer service
   * @returns
   */
  msgs => store.dispatch('message/onReceiveMessage', msgs)
]
export function addOnMessageListener(fn) {
  msgListeners.push(fn)
  return () => {
    msgListeners = msgListeners.filter(item => {
      return fn !== item
    })
  }
}

let msgQueue = {
  timer: null,
  interval: 200,
  msgs: []
}

let listenerHelper = {
  onMessage(type, msg) {
    addToMsgQueue(msg)
  },
  onCommandMessage(msg) {
    let id = WebIM.conn.getUniqueId()
    let localMsg = convertServerMessage(id, msg)
    console.log(localMsg, '======onCommandMessage')
    switch (localMsg.action) {
      case 'autoTurnManualReq':
        store.dispatch('conversation/newConversataion', {
          ownerId: localMsg.ext.fromCustId,
          reqChatId: localMsg.ext.chatRoomId,
          reqDt: dayjs().format('YYYYMMDD'),
          reqExt: '',
          reqHms: dayjs().format('HHmmss'),
          reqId: localMsg.ext.reqId,
          reqOptTp: '8',
          reqSts: '01',
          reqTime: Date.now(),
          updateRmk: null,
          updateTime: null,
          userId: localMsg.ext.toStaffImId
        })
        break
      case 'rtc':
        onRtcMessage(msg)
        break
    }
  }
}

function onRtcMessage(msg) {
  switch (msg.ext.action) {
    case 'leave':
    case 'refuse':
    case 'join':
    case 'accept':
    case 'cancel':
    case 'hangup':
    case 'busy':
      emitEvent('RTCInvitationStatusChange', {
        status: msg.ext.action,
        calltype: msg.ext.calltype,
        from: msg.ext.from,
        conversation: msg.ext.conversation
      })
      break
  }
}

function addToMsgQueue(msg) {
  msgQueue.msgs.unshift(msg)
  if (!msgQueue.timer) {
    msgQueue.timer = setTimeout(() => {
      let msgs = [...msgQueue.msgs]
      msgQueue.timer = null
      msgQueue.msgs = []
      dispatchMsgUpdate(msgs)
    }, msgQueue.interval)
  }
}

function dispatchMsgUpdate(msgs) {
  console.log('==================dispatchMsgUpdate==================', msgs)
  for (let fn of msgListeners) {
    fn(msgs)
  }
}
WebIM.conn.listen({
  onOpened: () => {
    console.log('onOpened')
    listenerHelper.onOpened?.()
  },
  onClosed: error => {
    console.log('onClosed')
    WebIM.conn && WebIM.conn.close()
    // Toast.show({
    //   text: 'You has been offline, Please check the network'
    // })
    listenerHelper.onClosed?.(error)
  },
  onTextMessage: msg => {
    // let quoteInfo = msg.ext?.quote
    // 包含引用消息
    // if (quoteInfo) {
    //   //消息被删除后，可能查不到消息
    //   let quoteMsg = await messageRealm.getMsgByMId(quoteInfo.mid)
    //   if (quoteMsg) {
    //     quoteInfo = {
    //       ...quoteMsg
    //     }
    //     if (quoteInfo.ext?.quote) {
    //       quoteInfo.ext = {
    //         ...quoteInfo.ext,
    //         quote: null
    //       }
    //     }
    //   }
    //   await chatStore.getUsersInfo(quoteInfo.from)
    //   let fromName = chatStore.getFriendShowName(chatStore.userInfo[quoteInfo.from])
    //   quoteInfo.fromName = fromName
    //   msg.ext.quote = quoteInfo
    // }
    listenerHelper.onMessage('text', msg)
  },
  onPictureMessage: msg => {
    listenerHelper.onMessage('image', msg)
  },
  onAudioMessage: msg => {
    listenerHelper.onMessage('voice', msg)
  },
  onFileMessage: msg => {
    listenerHelper.onMessage('file', msg)
  },
  onVideoMessage: msg => {
    listenerHelper.onMessage('video', msg)
  },
  onCustomMessage: async msg => {
    if (msg.ext.messageType === 'event') {
      if (msg.ext.eventType === 'CustomerDisconnected') {
        // 用户退出聊天室
        store.dispatch('conversation/customerEndConversation', msg)
      }
    }
    listenerHelper.onMessage('custom', msg)
  },
  onCmdMessage: msg => {
    listenerHelper.onCommandMessage(msg)
  },
  onPresence: msg => {
    listenerHelper.onPresence?.(msg)
    console.log(msg, 'onPresence')
  },
  // 收到好友请求
  onContactInvited: msg => {},
  onContactDeleted: msg => {},
  onContactAdded: msg => {},
  onRoster: msg => {
    console.log(msg, 'onRoster')
  }, //处理好友申请
  onInviteMessage: msg => {
    console.log(msg, 'onInviteMessage')
  }, //处理群组邀请
  //本机网络连接成功
  onOnline: function () {
    console.log('onOnline')
    listenerHelper.onOnline?.()
  },
  //本机网络掉线
  onOffline: function () {
    console.log('onOffline')
    listenerHelper.onOffline?.()
  },
  onError: msg => {
    let text = ''
    let type = msg.data ? msg.data.type : ''
    switch (type) {
      case 17:
        Message.error(msg.data.data)
        break
      case 206:
        // alert(i18n.t('loginFromOther'))
        return
      case 504:
        text = i18n.t('messageRecallFailed')
        break
    }
    console.log(msg, 'onError', text)
    listenerHelper.onError?.(msg)
  }, //失败回调
  onRecallMessage: msg => {}, //收到撤回消息回调
  onReadMessage: msg => {}, //收到消息已读回执
  onMutedMessage: msg => {}, //如果用户在A群组被禁言，在A群发消息会走这个回调并且消息不会传递给群其它成员
  onChannelMessage: msg => {} //收到整个会话已读的回执，在对方发送channel ack时会在这个回调里收到消息
})
