let config = {
  appkey: '1111210520040519#trustyecommercecustomer',
  restServer: 'https://a1-sgp.easemob.com',
  socketServer: 'https://im-api-sgp-v2.easemob.com/ws',
  https: true, // 是否使用https
  isHttpDNS: true, // 3.0 SDK支持，防止DNS劫持从服务端获取XMPPUrl、restUrl

  isMultiLoginSessions: false, // 是否开启多页面同步收消息，注意，需要先联系商务开通此功能

  isDebug: true, // 打开调试，会自动打印log，在控制台的console中查看log

  heartBeatWait: 30000, // 心跳间隔（只在小程序中使用）

  delivery: false, // 是否发送已读回执

  useOwnUploadFun: true, // 是否使用自己的上传方式（如将图片文件等上传到自己的服务器，构建消息时只传url）

  Host: 'easemob.com',

  isWindowSDK: false,
  /**
   * @parameter {Boolean} true or false
   */
  isSandBox: true,

  /**
   * Whether to show logs in strophe
   * @parameter {Boolean} true or false
   */
  isStropheLog: false,
  /**
   * will auto connect the xmpp server autoReconnectNumMax times in background when client is offline.
   * won't auto connect if autoReconnectNumMax=0.
   */
  autoReconnectNumMax: 3,
  /**
   * the interval secons between each atuo reconnectting.
   * works only if autoReconnectMaxNum >= 2.
   */
  autoReconnectInterval: 5000,
  /**
   * webrtc supports WebKit and https only
   */
  isWebRTC: false,

  /**
   * 5 actual logging methods, ordered and available:
   * 'TRACE', 'DEBUG', 'INFO', 'WARN', 'ERROR'
   */

  loglevel: 'SILENT'
}

export default config
