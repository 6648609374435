<template>
  <span v-if="type === 'text'">{{ $util.getLabel(value, options) }}</span>
  <el-select
    v-else
    :value="value"
    @input="$emit('input', $event)"
    :disabled="disabled"
    :clearable="clearable"
    :loading="loading"
    :multiple="multiple"
    @change="$emit('change', $event)"
    @blur="$emit('blur', $event)"
    @focus="$emit('focus', $event)"
  >
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    >
    </el-option>
  </el-select>
</template>
<script>
import api from '@/api/index'
let pendingPromise = null
let lastFetchTime = 0
async function _getIndustryList() {
  let res = await api.post({
    url: 'common/mrchntIndsCtgry/mgmt/query',
    data: {
      querySumYn: 'N'
    }
  })
  let list = res?.dataList || []
  return list.map(item => {
    return { value: item.id, label: item.ctgryNm }
  })
}
export async function getIndustryList() {
  const now = Date.now()
  if (!pendingPromise || now - lastFetchTime > 5000) {
    pendingPromise = _getIndustryList()
    lastFetchTime = now
  }
  return pendingPromise
}
export default {
  props: ['type', 'value', 'clearable', 'disabled', 'multiple'],
  data() {
    return {
      loading: true,
      options: []
    }
  },
  async created() {
    this.loading = true
    this.options = await getIndustryList()
    this.loading = false
  }
}
</script>
