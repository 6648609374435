<i18n src="@/i18n/template/components/SMSButton.json"></i18n>
<template>
  <el-popover
    popper-class="sms-verify"
    placement="bottom"
    trigger="manual"
    v-model="robotVisible"
  >
    <el-button slot="reference" :disabled="countdown !== 0" @click="smsClick">
      {{ countdown <= 0 ? 'SMS' : `${countdown} s ` }}
    </el-button>

    <i class="el-icon-close" @click="robotVisible = false"></i>
    <div class="sms-verify-container">
      <div
        class="img"
        :style="{ width: imgWidth + 'px', height: imgHeight + 'px' }"
        v-loading="loading"
      >
        <img
          v-if="imgData"
          :src="imgData.img"
          alt="img"
          @click="onImageClick"
          draggable="false"
        />
        <span v-else-if="!loading" @click="requestImage"
          >Load failed, Click to refresh</span
        >
        <i v-if="pointer" :style="pointer" class="el-icon-location-outline"></i>
      </div>
      <p v-if="verifying">
        <i class="el-icon-loading"></i>
        Verifying...
      </p>
      <p v-else :style="{ color: statusColor }">
        {{ statusText }}
      </p>
    </div>
  </el-popover>
</template>

<script>
import { Host } from '@/config'
import axios from 'axios'
let ConstCountdown = 0
let CountdownPeriod = 60
export default {
  name: 'sms-button',
  props: [
    // 手机号
    'mobile',
    // 区号
    'area'
  ],
  data() {
    return {
      robotVisible: false,
      statusText: 'Click the button in the image to continue',
      statusColor: '#666',
      verifying: false,
      countdown: ConstCountdown,
      imgHeight: 203,
      imgWidth: 360,
      imgData: null,
      pointer: null,
      loading: false
    }
  },
  created() {
    this.startCountdown(ConstCountdown)
  },
  beforeDestroy() {
    this.stopCountdown()
  },
  methods: {
    smsClick() {
      if (!this.area || !this.mobile) {
        return this.$message.error(this.$t('mobileEmpty'))
      }
      if (this.countdown === 0) {
        this.robotVisible = !this.robotVisible
        this.statusText = 'Click the button in the image to continue'
        this.statusColor = '#666'
        if (this.robotVisible) {
          this.requestImage()
        }
      }
    },
    async requestImage() {
      if (!this.area || !this.mobile) {
        return this.$message.error(this.$t('mobileEmpty'))
      }
      this.pointer = null
      this.imgData = null
      this.loading = true
      this.verifying = false
      let res = await this.$api.post({
        url: 'alioss/smsOtp/send/verifyImg'
      })
      this.loading = false
      if (res) {
        let data = res
        this.imgData = data
        this.imgHeight = (data.height / data.width) * this.imgWidth
      }
    },
    async onImageClick(event) {
      if (this.loading) return
      if (this.verifying) return
      this.verifying = true
      this.pointer = {
        left: event.offsetX - 15 + 'px',
        top: event.offsetY - 30 + 'px'
      }
      let scale = this.imgData.width / this.imgWidth
      let x = event.offsetX * scale
      let y = event.offsetY * scale
      let body = {
        uuid: this.imgData.uuid,
        xy: Math.floor(x) + ',' + Math.floor(y),
        intrntnlNbr: this.area,
        dtlCntctPntCntnt: this.mobile,
        authTrgtTpCd: '03',
        authCd: '00002'
      }
      let res = await this.$api.post({
        url: 'alioss/smsOtp/check/verifyImg',
        data: body,
        showError: false
      })
      this.verifying = false
      if (res) {
        this.$emit('done', res.selfAuthId)
        this.status = 'countdown'
        this.startCountdown(CountdownPeriod)
      } else {
        this.requestImage()
        this.$message.error('Failed to verify, please try again')
        this.statusText = 'Failed to verify, please try again'
        this.statusColor = 'red'
      }
    },
    startCountdown(t) {
      ConstCountdown = t
      this.ConstCountdown = ConstCountdown
      this.robotVisible = false
      this.timer = setInterval(() => {
        ConstCountdown = ConstCountdown - 1
        if (ConstCountdown <= 0) {
          ConstCountdown = 0
          clearInterval(this.timer)
          this.timer = null
        }
        this.countdown = ConstCountdown
      }, 1000)
    },
    stopCountdown() {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
    }
  }
}
</script>

<style lang="scss">
.sms-verify {
  position: relative;
  .el-icon-close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
    font-size: 16px;
    color: red;
    cursor: pointer;
  }
  .sms-verify-container {
    margin-top: 4px;
    .img {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f5f5f5;
      img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
      span {
        font-size: 14px;
        color: red;
        cursor: pointer;
      }
      i {
        position: absolute;
        font-size: 30px;
        color: #fff;
      }
    }
    > p {
      margin-top: 12px;
      font-size: 14px;
      color: #888;
      &.error {
        color: red;
      }
    }
  }
}
</style>
