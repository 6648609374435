import '@/i18n/template/projects/operation/store/modules/message.js'
import WebIM from '../../webim/WebIM'
import i18n from '@/i18n'

import { convertServerMessage } from '@/util/messageUtil'

async function uploadFile(fileInfo) {
  return new Promise(resove => {
    let str = WebIM.config.appkey.split('#')
    let options = {
      apiUrl: WebIM.conn.apiUrl,
      appName: str[1],
      orgName: str[0],
      appKey: WebIM.config.appkey,
      file: fileInfo,
      accessToken: WebIM.conn.context.accessToken,
      onFileUploadComplete: function (data) {
        console.log(data, 'uploadFileToIM Success')
        if (data.entities) {
          resove({
            url: data.uri + '/' + data.entities[0].uuid,
            secret: data.entities[0]['share-secret']
          })
        }
      },
      onFileUploadError: function (e) {
        //upload file error
        resove()
        console.log(e, '-----')
      }
    }

    WebIM.utils.uploadFile(options)
  })
}

export default {
  namespaced: true,
  state: {
    loading: false,
    hasMore: false,
    list: [],
    status: {},
    // the latest message of conversation
    latestMsg: {}
  },
  actions: {
    /**
     * 构建和发送消息
     * @param {Object} msg 必须包含以下字段
     * id: 'int', // 消息的id 可以为空，不为空的时候表示重发消息
     * channel: 'string', // 聊天的频道
     * to: 'string', // 发送对象 一般等于channel
     * from: 'string', // 发送者
     * time: 'int', // 发送时间
     * chatType: 'string', // 聊天类型 groupchat / singlechat / chatroom
     * 。。其他查考 database/msg.js的定义
     * @param {Function} onCreated 当消息创建成功 已经保存到数据库时候的回调
     * @param {Function} onFileUploaded 当消息的文件上传到服务器成功
     * @param {Function} onSendSuccess 当消息发送成功
     * @param {Function} onSendError 当消息发送失败
     * @returns
     */
    async sendMessage(
      context,
      { msg, onCreated, onFileUploaded, onSendSuccess, onSendError }
    ) {
      // new or resend
      let id = msg.id || WebIM.conn.getUniqueId()
      let msgInstance
      // for database
      let realMsg = {
        ...msg,
        id,
        from: context.rootState.user.imId,
        time: msg.time || Date.now(),
        url: msg.file?.url
      }
      realMsg.ext = realMsg.ext || {}
      // for imsdk
      let msgOptions = {
        to: realMsg.to,
        from: realMsg.from,
        chatType: realMsg.chatType,
        ext: { ...realMsg.ext }
      }
      msgOptions.ext.messageType = msg.messageType
      console.log('----', msg, realMsg)

      switch (msg.messageType) {
        case 'text':
          msgInstance = new WebIM.message('txt', id)
          msgOptions.msg = msg.msg || ''
          break
        case 'image':
          msgInstance = new WebIM.message('img', id)
          msgOptions.body = {
            type: 'img',
            size: {
              width: '',
              height: ''
            },
            url: '',
            filetype: msg.ext.filetype || '',
            filename: msg.ext.filename || '',
            filelength: ''
          }
          context.commit('updateStatus', { id, status: 'sending' })
          break
        case 'voice':
          msgInstance = new WebIM.message('audio', id)
          msgOptions.body = {
            type: 'audio',
            url: '',
            filetype: '',
            filename: '',
            filelength: ''
          }
          context.commit('updateStatus', { id, status: 'sending' })
          break
        case 'video':
          msgInstance = new WebIM.message('video', id)
          msgOptions.body = {
            type: 'video',
            url: '',
            filetype: '',
            filename: '',
            filelength: ''
          }
          context.commit('updateStatus', { id, status: 'sending' })
          break
        case 'file':
          msgInstance = new WebIM.message('file', id)
          msgOptions.body = {
            type: 'file',
            url: '',
            filetype: '',
            filename: '',
            filelength: ''
          }
          context.commit('updateStatus', { id, status: 'sending' })
          break
        case 'redpacket':
        case 'friend':
        case 'group':
        case 'transfer':
        case 'requestmoney':
        case 'phonecall':
        case 'location':
        case 'shareProduct':
        case 'event':
          msgInstance = new WebIM.message('custom', id)
          break
        case 'rtc':
          // 实时音视频和命令类通知，直接发送不存数据库
          msgInstance = new WebIM.message('cmd', id)
          // IM 有undefined值会报错
          for (let key in msgOptions.ext) {
            if (!msgOptions.ext[key]) {
              msgOptions.ext[key] = ''
            }
          }
          msgOptions.action = 'rtc'
          msgOptions.success = (msgId, mid) => {
            realMsg.mid = mid
            console.log(realMsg)
            onSendSuccess && onSendSuccess(realMsg)
          }
          msgOptions.fail = function (err) {
            let text = 'send fail'
            let type = err.type + ''
            switch (type) {
              case '506': // 没有在群组或聊天室白名单
              case '503':
                text = i18n.t('chat.unknownError')
                break
            }
            onSendError && onSendError(err, realMsg)
          }
          msgInstance.set(msgOptions)
          WebIM.conn.send(msgInstance.body)
          return
        default:
          msgInstance = new WebIM.message('custom', id)
          return
      }
      // IM 有undefined值会报错
      for (let key in msgOptions.ext) {
        if (!msgOptions.ext[key]) {
          msgOptions.ext[key] = ''
        }
      }

      // 没有id 则为新消息， 否则是重发
      if (!msg.id) {
        onCreated && onCreated(realMsg)
        if (
          realMsg.channel ===
          context.rootState.conversation.conversation?.reqChatId
        ) {
          context.commit('appendMessage', realMsg)
        }
        context.commit('updateLatestMessage', {
          conversationId: realMsg.channel,
          message: realMsg
        })
      }
      if (realMsg.file) {
        let res = await uploadFile(realMsg.file)
        if (!res) {
          context.commit('updateStatus', { id, status: 'error' })
          return
        }
        msgOptions.body.url = res.url
        msgOptions.secret = res.secret
        realMsg.secret = res.secret
        onFileUploaded && onFileUploaded(realMsg)
      }
      msgOptions.success = (msgId, mid) => {
        realMsg.mid = mid
        console.log(id, 'onSendSuccess')
        if (context.state.status[id]) {
          context.commit('updateStatus', { id, status: '' })
        }
        onSendSuccess && onSendSuccess(realMsg, msgId, mid)
      }
      msgOptions.fail = function (err) {
        context.commit('updateStatus', { id, status: 'error' })
        console.log('Send Error', err, realMsg)
        let text = 'send fail'
        let type = err.type + ''
        switch (type) {
          case 'empty_file':
            text = i18n.t('notExist')
            break
          case '504': //  撤回消息时超出撤回时间
          case '505': // 未开通消息撤回
            text = i18n.t('recallTimeOut')
            break
          case '503':
            text = i18n.t('unknownError')
            break
        }
        onSendError && onSendError(err, realMsg)
      }
      msgInstance.set(msgOptions)
      WebIM.conn.send(msgInstance.body)
    },
    onReceiveMessage(context, messages) {
      let msgArr = []
      let usernames = {}
      messages = Array.isArray(messages) ? messages : [messages]
      for (let msg of messages) {
        // 直播聊天消息不处理
        if (msg.ext?.live) continue
        let id = WebIM.conn.getUniqueId()
        let localMsg = convertServerMessage(id, msg)
        usernames[localMsg.from] = true
        context.commit('updateLatestMessage', {
          conversationId: localMsg.channel,
          message: localMsg
        })
        if (
          localMsg.channel ===
          context.rootState.conversation.conversation?.reqChatId
        ) {
          msgArr.push(localMsg)
        }
      }
      usernames = Object.keys(usernames)
      // 获取发消息的用户信息
      context.dispatch(
        'chat/getUsersInfo',
        { usernames: usernames },
        { root: true }
      )
      context.commit('appendMessage', msgArr)
    },
    async fetchMessage(
      { state, commit, dispatch },
      { first = false, id, isGroup = true }
    ) {
      return new Promise((resolve, reject) => {
        commit('loading', true)
        let count = 20
        if (first) {
          WebIM.conn.mr_cache = []
          commit('clear')
          commit('hasMore', true)
        }
        WebIM.conn.fetchHistoryMessages({
          queue: id,
          isGroup: isGroup,
          count: count,
          success: messages => {
            console.log(messages, 'fetchHistoryMessages')
            commit('loading', false)
            commit('hasMore', messages.length === count)
            let usernames = {}
            let list = []
            for (let msg of messages) {
              let id = WebIM.conn.getUniqueId()
              let localMsg = convertServerMessage(id, msg)
              usernames[localMsg.from] = true
              list.push(localMsg)
            }
            list = list.concat(state.list)
            usernames = Object.keys(usernames)
            // 获取发消息的用户信息
            dispatch(
              'chat/getUsersInfo',
              { usernames: usernames },
              { root: true }
            )
            commit('updateMessage', list)
            let lastMsg = list[list.length - 1]
            if (lastMsg) {
              commit('updateLatestMessage', {
                conversationId: lastMsg.channel,
                message: lastMsg
              })
            }
            resolve(list)
          },
          fail: function () {
            commit('loading', false)
            console.log('fetchHistoryMessages fail')
            reject()
          }
        })
      })
    }
  },
  mutations: {
    clear(state) {
      state.list = []
    },
    hasMore(state, hasMore) {
      state.hasMore = hasMore
    },
    loading(state, loading) {
      state.loading = loading
    },
    updateMessage(state, message) {
      state.list = message
    },
    appendMessage(state, message) {
      if (!message) return
      state.list = state.list.concat(message)
    },
    updateStatus(state, { id, status }) {
      state.status = {
        ...state.status,
        [id]: status
      }
    },
    updateLatestMessage(state, { conversationId, message }) {
      state.latestMsg = {
        ...state.latestMsg,
        [conversationId]: message
      }
    }
  }
}
