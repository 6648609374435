export default [
  {
    path: '/rental/report/yangon/home_rental_and_water',
    name: 'YangonDailyHomeRentalAndWater',
    component: () =>
      import(
        /* webpackChunkName: "rental_yangon_report" */ './DailyHomeRentalAndWater.vue'
      )
  },
  {
    path: '/rental/report/yangon/home_rental_charges',
    name: 'YangonDailyHomeRentalCharges',
    component: () =>
      import(
        /* webpackChunkName: "rental_yangon_report" */ './DailyHomeRentalCharges.vue'
      )
  },
  {
    path: '/rental/report/yangon/income_water',
    name: 'YangonDailyIncomeOfWater',
    component: () =>
      import(
        /* webpackChunkName: "rental_yangon_report" */ './DailyIncomeOfWater.vue'
      )
  },
  {
    path: '/rental/report/yangon/income_develop_secuity',
    name: 'YangonDailyIncomeOfDevelopAndSecuity',
    component: () =>
      import(
        /* webpackChunkName: "rental_yangon_report" */ './DailyIncomeOfDevelopAndSecuity.vue'
      )
  },
  {
    path: '/report/report/yangon/rental_charges',
    name: 'YangonMothlyRentalCharges',
    component: () =>
      import(
        /* webpackChunkName: "rental_yangon_report" */ './MothlyRentalCharges.vue'
      )
  },
  {
    path: '/report/report/yangon/income_others',
    name: 'YangonMothlyIncomeOfOthers',
    component: () =>
      import(
        /* webpackChunkName: "rental_yangon_report" */ './MothlyIncomeOfOthers.vue'
      )
  },
  {
    path: '/report/report/yangon/balance_due_home_rental',
    name: 'YangonMothlyBalanceDueListForHomeRental',
    component: () =>
      import(
        /* webpackChunkName: "rental_yangon_report" */ './MothlyBalanceDueListForHomeRental.vue'
      )
  }
]
