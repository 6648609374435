<template>
  <el-input
    :value="formatedNumber"
    @input="onInput"
    :type="type"
    :placeholder="placeholder"
    :clearable="clearable"
    :disabled="disabled"
    :size="size"
    :prefix-icon="prefixIcon"
    :suffix-icon="suffixIcon"
    :rows="rows"
    :name="name"
    :autofocus="autofocus"
    :maxlength="maxlength || 25"
    @change="$emit('change', $event)"
    @blur="onBlur"
    @focus="onFocus"
  >
    <template slot="append" v-if="append">{{ append }}</template>
  </el-input>
</template>
<script>
export default {
  props: [
    'type',
    'value',
    // 不格式化
    'not-format',
    // 支持小数输入位数
    'fixed',
    // example：the final value will be (value % step)
    'step',
    'placeholder',
    'clearable',
    'disabled',
    'maxlength',
    'size',
    'prefix-icon',
    'suffix-icon',
    'rows',
    'name',
    'max',
    'min',
    'autofocus',
    'append'
  ],
  computed: {
    formatedNumber() {
      let value = this.value
      if (this.notFormat) return value
      if (this.foucsed) return value
      if (value || typeof value === 'number') {
        value = value + ''
      } else {
        value = ''
      }
      let [v, fixed] = value.split('.')
      if (fixed) {
        fixed = '.' + fixed
      } else {
        fixed = ''
      }
      return v.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + fixed
    }
  },
  data() {
    return {
      foucsed: this.autofocus === true
    }
  },
  methods: {
    onInput(value) {
      if (this.fixed) {
        let reg = new RegExp(`^(\\d*|(\\d+\\.\\d{0,${this.fixed}}))$`)
        if (reg.test(value)) {
          this.$emit('input', value)
        }
      } else {
        if (/^\d*$/.test(value)) {
          this.$emit('input', value)
        }
      }
    },
    onFocus(event) {
      this.$emit('focus', event)
      this.foucsed = true
    },
    onBlur(event) {
      this.foucsed = false
      this.$emit('blur', event)
      let value = this.value || ''
      if (this.step) {
        value = parseInt(value / this.step) * this.step
      }
      if (this.min !== undefined) {
        value = Number(value) < Number(this.min) ? this.min : value
      }
      if (this.max !== undefined) {
        value = Number(value) < Number(this.max) ? value : this.max
      }
      if (value !== this.value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
