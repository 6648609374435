export default [
  {
    name: 'GiftCardManagement',
    path: '/gift_card/list',
    component: () =>
      import(
        /* webpackChunkName: "giftCard" */ './GiftCard/GiftCardManagement.vue'
      )
  },
  {
    name: 'GiftCardDetail',
    path: '/gift_card/list/detail',
    component: () =>
      import(/* webpackChunkName: "giftCard" */ './GiftCard/GiftCardDetail.vue')
  },
  {
    name: 'GiftCardAndGameShopBusinessRule',
    path: '/gift_card_game_shop/rule',
    component: () =>
      import(/* webpackChunkName: "giftCard" */ './BusinessRule.vue')
  },
  {
    name: 'GiftCardAndGameShopSettlement',
    path: '/gift_card_game_shop/settlement',
    component: () =>
      import(/* webpackChunkName: "giftCard" */ './Settlement.vue')
  },
  {
    name: 'GiftCardSettlementDetail',
    path: '/gift_card_game_shop/settlement/gift_cardsettlement_detail',
    component: () =>
      import(/* webpackChunkName: "giftCard" */ './SettlementDetailList.vue')
  },
  {
    name: 'GameShopSettlementDetail',
    path: '/gift_card_game_shop/settlement/game_shop_settlement_detail',
    component: () =>
      import(/* webpackChunkName: "giftCard" */ './SettlementDetailList.vue')
  },
  {
    name: 'GameShopManagement',
    path: '/game_shop/list',
    component: () =>
      import(
        /* webpackChunkName: "giftCard" */ './GameShop/GameShopManagement.vue'
      )
  },
  {
    name: 'GameShopRegionServiceDetails',
    path: '/game_shop/list/region_service',
    component: () =>
      import(
        /* webpackChunkName: "giftCard" */ './GameShop/GameShopDetailList.vue'
      )
  },
  {
    name: 'GameShopProductBasicInformation',
    path: '/game_shop/list/region_service_detail',
    component: () =>
      import(/* webpackChunkName: "giftCard" */ './GameShop/GameShopDetail.vue')
  }
]
