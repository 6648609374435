let SockJS
export async function init() {
  await import(/* webpackChunkName: "sockjs" */ './sockjs.js').then(module => {
    SockJS = module.default
  })
  await import(/* webpackChunkName: "sockjs" */ './stomp.js')
}

let stompClient = null

function startConnet(url, staffId, listener) {
  console.log(url, 'startConnet')
  if (!staffId) return
  const socket = new SockJS(url) //连接SockJS的endpoint名称为"endpointOyzc"

  stompClient = Stomp.over(socket) //使用STMOP子协议的WebSocket客户端
  stompClient.connect({ Authorization: staffId }, function (frame) {
    stompClient.subscribe('/user/' + staffId + '/msg', function (response) {
      const data = JSON.parse(response.body)
      listener && listener(data)
    })
  })
}

//关闭双通道
function disconnect() {
  if (stompClient != null) {
    stompClient.disconnect()
    stompClient = null
    console.log('Disconnected')
  }
}

export default {
  init,
  startConnet,
  disconnect
}
