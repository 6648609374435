import WebIM from 'easemob-websdk'
import config from './WebIMConfig'
import { API_ENV } from '@/config'
let env = API_ENV
const IMEVN = {
  dev: {
    appkey: '1111210520040519#customer-dev',
    restServer: 'https://a1.easemob.com',
    socketServer: 'https://im-api-v2.easemob.com/ws'
  },
  uat: {
    appkey: '1111210520040519#demo',
    restServer: 'https://a1.easemob.com',
    socketServer: 'https://im-api-v2.easemob.com/ws'
  },
  newuat: {
    appkey: '1111210520040519#customer-newuat',
    restServer: 'https://a1.easemob.com',
    socketServer: 'https://im-api-v2.easemob.com/ws'
  },
  production: {
    appkey: '1111210520040519#trustyecommercecustomer',
    restServer: 'https://a1-sgp.easemob.com',
    socketServer: 'https://im-api-sgp-v2.easemob.com/ws'
  }
}

config.appkey = IMEVN[env].appkey
config.restServer = IMEVN[env].restServer
config.socketServer = IMEVN[env].socketServer
WebIM.config = config
WebIM.conn = new WebIM.connection({
  isDebug: config.isDebug,
  appKey: config.appkey,
  isHttpDNS: config.isHttpDNS,
  isMultiLoginSessions: config.isMultiLoginSessions,
  https: config.https,
  url: config.socketServer,
  apiUrl: config.restServer,
  isAutoLogin: config.isAutoLogin,
  autoReconnectNumMax: config.autoReconnectNumMax,
  autoReconnectInterval: config.autoReconnectInterval,
  delivery: config.delivery,
  useOwnUploadFun: config.useOwnUploadFun
})
WebIM.logger.disableAll()

export default WebIM
