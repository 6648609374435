import { ButtonPermissionMap } from '../../constant/ButtonPermission'
export default [
  {
    name: 'home',
    path: 'home',
    component: () => import(/* webpackChunkName: "home" */ './index.vue')
  },
  {
    name: 'profile',
    path: 'profile',
    meta: {
      permission: ButtonPermissionMap.MyInfrormation
    },
    component: () => import(/* webpackChunkName: "profile" */ './profile.vue')
  }
]
