import ChatCall from './ChatCall.vue'
import Vue from 'vue'

ChatCall.newInstance = properties => {
  const _props = properties || {}
  const Instance = new Vue({
    data: _props,
    render(h) {
      return h(ChatCall, {
        props: _props
      })
    }
  })
  const component = Instance.$mount()
  document.body.appendChild(component.$el)
  const chatCall = Instance.$children[0]

  return {
    update(options) {
      if ('show' in options) {
        chatCall.show = options.show
      }
    },
    component: chatCall,
    destroy() {
      document.body.removeChild(
        document.getElementsByClassName('chat-window')[0]
      )
    }
  }
}

export default ChatCall
