<i18n src="@/i18n/template/components/ExportToolbar.json"></i18n>
<template>
  <div style="margin: 20px 0; text-align: right">
    <slot></slot>
    <el-button v-if="showExport" @click="exportClick" :disabled="disabled">{{
      $t('export')
    }}</el-button>
    <el-button v-if="showHistory" @click="exportVisible = true">
      {{ $t('ExportHis') }}
    </el-button>
    <slot name="append"></slot>
    <export-task-dialog :visible.sync="exportVisible" :busTp="busTp" />
  </div>
</template>

<script>
import { exportFile } from '@/api/export'
export default {
  props: {
    busTp: String,
    params: Object,
    disabled: Boolean,
    showExport: {
      type: Boolean,
      default: true
    },
    showHistory: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      exportVisible: false
    }
  },
  methods: {
    async exportClick() {
      let loading = this.$loading()
      let res = await exportFile(this.busTp, this.params)
      loading.close()
      if (res) {
        this.exportVisible = true
      }
    }
  }
}
</script>

<style></style>
