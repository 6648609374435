<template>
  <el-checkbox-group :style="style" v-model="checkboxArr" :disabled="disabled">
    <el-checkbox
      v-for="item in optionsData"
      :key="item.value"
      :label="item.value"
      :disabled="item.disabled"
      @change="handleCheckChange(item.value)"
    >
      <span :style="item.style">
        {{ (item.getLabel && item.getLabel()) || item.label }}
      </span>
    </el-checkbox>
  </el-checkbox-group>
</template>
<script>
export default {
  props: [
    'value',
    'options',
    'clearable',
    'disabled',
    'multiple',
    'getOptions',
    '_fitWidth'
  ],

  computed: {
    optionsData() {
      return this.remoteOptions || this.options || []
    }
  },
  data() {
    return {
      style: {},
      loading: false,
      remoteOptions: null,
      checkboxArr: []
    }
  },
  watch: {
    value() {
      if (this.multiple) {
        this.checkboxArr = this.value
      } else {
        this.checkboxArr = [this.value]
      }
    }
  },
  async created() {
    if (this.multiple) {
      this.checkboxArr = this.value
    } else {
      this.checkboxArr = [this.value]
    }
    if (this.getOptions) {
      this.loading = true
      let options = await this.getOptions()
      this.loading = false
      this.remoteOptions = options || null
      this.$nextTick(this.compute_fitWidth)
    }
  },
  mounted() {
    this.compute_fitWidth()
  },
  updated() {
    this.compute_fitWidth()
  },
  methods: {
    compute_fitWidth() {
      if (!this._fitWidth) return
      let width = this._fitWidth
      if (this.$el.children) {
        let contentWidth = 0
        for (let i = 0; i < this.$el.children.length; i++) {
          const el = this.$el.children[i]
          contentWidth = contentWidth + el.clientWidth + 30
        }
        let multiple = Math.floor(contentWidth / width) + 1
        width = multiple * width
        if (multiple > 1) {
          width = width + 10
        }
      }
      let styleWidth = width + 'px'
      if (styleWidth === this.style.width) return
      this.style = { width: styleWidth }
    },
    handleCheckChange(value) {
      if (this.multiple) {
        this.$emit('input', this.checkboxArr)
      } else {
        if (this.clearable) {
          if (this.value === value) {
            this.checkboxArr = []
            this.$emit('input', '')
            this.$emit('change', '')
          } else {
            this.checkboxArr = [value]
            this.$emit('input', this.checkboxArr[0])
            this.$emit('change', this.checkboxArr[0])
          }
        } else {
          this.checkboxArr = [value]
          this.$emit('input', this.checkboxArr[0])
          this.$emit('change', this.checkboxArr[0])
        }
      }
    }
  }
}
</script>
