export default [
  {
    name: 'topup_promotion_rule',
    path: 'topup_promotion_rule',
    component: () =>
      import(/* webpackChunkName: "mobileTopup" */ './TopupPromotionRule.vue')
  },
  {
    name: 'topup_goods_manage',
    path: 'topup_goods_manage',
    component: () =>
      import(/* webpackChunkName: "mobileTopup" */ './GoodsManagement.vue')
  }
]
