import { Host, BaseURL } from '@/config'
import apiClient from './index'
import axios from 'axios'
import { Message } from 'element-ui'

export const FileSource = {
  Chat: 'chat',
  Product: 'product',
  Food: 'food',
  Customer: 'customer',
  Merchant: 'merchant',
  Avatar: 'avatar',
  BatchOpenAccount: 'importCust',
  MeterBill: 'meterBill',
  Live: 'live',
  Moments: 'moments',
  CustCancellation: 'cancelCust',
  MerCancellation: 'cancelMrchnt',
  Banner: 'banner',
  HouseRental: 'meterBill',
  Giftcard: 'giftcard',
  GameShop: 'gameshop',
  CustService: 'custsrvc'
}
/**
 * 上传文件至oss
 * @param file 文件对象
 * @param showError 当错误时 是否提示用户
 * @param source FileSource
 */
export async function upload(options) {
  return uploadFile(options.file, options.showError, options.source)
}
export async function uploadFile(file, showError = true, source) {
  if (!source) {
    Message.error('Bucket is required')
    return { code: '0', msg: 'file is required' }
  }
  if (!file) {
    if (showError) {
      Message.error('file is required')
    }
    return { code: '0', msg: 'file is required' }
  }
  /**
   * 获取oss临时上传信息
   */
  try {
    let res = await apiClient.post({
      url: 'alioss/upload/getPolicy',
      data: {
        custId: '',
        chnlTpCd: source
      },
      showError: showError
    })
    if (res) {
      let authData = res
      let key =
        authData.dir + authData.fileNm + '_' + file.name.replace(/\s/g, '')
      const data = new FormData()
      data.append('key', key)
      data.append('OSSAccessKeyId', authData.accessid)
      data.append('policy', authData.policy)
      data.append('Signature', authData.signature)
      data.append('success_action_status', '200')
      data.append('Content-Disposition', 'attachment;filename=' + file.name)
      data.append('file', file)
      const fileUrl = authData.host + key
      let host = authData.host
      if (location.href.indexOf('https://') !== -1) {
        host = host.replace('http://', 'https://')
      }
      let ossRes = await axios.request({
        method: 'POST',
        url: host,
        data: data,
        transformRequest: d => d
      })
      console.log(ossRes)
      if (ossRes.status === 200) {
        return {
          code: '0',
          data: fileUrl,
          filename: file.name,
          localFileNm: file.name,
          srvrFileNm: authData.fileNm,
          bucketNm: authData.bucketNm,
          bucketKey: key,
          fileExtnsn: file.type,
          fileSize: file.size
        }
      } else {
        throw new Error(ossRes.data)
      }
    }
  } catch (e) {
    console.log(e)
    const msg = e.message || 'Upload failed, Please check your network'
    if (showError) {
      Message.error(msg)
    }
    return { code: '1', msg: msg }
  }
  return { code: '1', msg: 'unknown error' }
}
/**
 * 获取OSS文件下载链接
 * @param fileId OSS fileId
 */
export async function getOSSDownloadUrl(fileId) {
  if (!fileId) {
    Message.error('file id can not be empty')
    return { code: '0', msg: 'file id can not be empty' }
  }
  return apiClient.post({
    url: '/common/file/downloadFile',
    data: {
      fileId
    }
  })
}
