import api from '@/api'
export const appId = '8bf59800c689435fba175324d50cdd33'

export const RoleType = {
  Host: 'host',
  Audience: 'audience'
}
let AgoraRTC = null
export function loadLiveSDK() {
  if (AgoraRTC) return AgoraRTC
  return import(/* webpackChunkName: "agora" */ 'agora-rtc-sdk-ng').then(m => {
    AgoraRTC = m.default
    return AgoraRTC
  })
}

/**
 * 获取RTC鉴权token
 * @param {*} channel 直播频道
 * @param {*} broadcast 是否发送视频端
 * @returns string token
 */
export function getRtcToken(channel, broadcast = false) {
  return api.post({
    url: 'alioss/agora/rtc/buildToken',
    data: {
      channelNm: channel, // 频道名称
      userNm: '0', //待鉴权用户的用户ID,无符号整数,保证唯一性,为0时,不鉴权.
      role: broadcast ? '1' : '2', //选填, 1:(默认）用户有发流权限;  2:仅有接收权限
      expireTime: 82800 //选填, 单位秒; 默认24小时，最大也是24小时
    }
  })
}
