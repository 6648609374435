import { ButtonPermissionMap } from '../../constant/ButtonPermission'
export default [
  {
    name: 'benefit_manage',
    path: '/benefit_manage',
    component: () =>
      import(
        /* webpackChunkName: "benefit_manage" */ './BenefitManage/BenefitManage.vue'
      )
  },
  {
    name: 'banner',
    path: '/banner',
    component: () =>
      import(/* webpackChunkName: "banner" */ './BannerManage.vue')
  },
  {
    name: 'banner_detail',
    path: '/banner_detail',
    meta: {
      permission: ButtonPermissionMap.Banner_Detail
    },
    component: () =>
      import(/* webpackChunkName: "banner" */ './BannerDetail.vue')
  },
  {
    name: 'membertype',
    path: 'membertype',
    component: () =>
      import(/* webpackChunkName: "membertype" */ './NewStaff.vue')
  },
  {
    name: 'roleManage',
    path: 'roleManage',
    component: () =>
      import(/* webpackChunkName: "roleManage" */ './NewRole.vue')
  },
  {
    name: 'systemParameterManage',
    path: 'systemParameterManage',
    component: () =>
      import(
        /* webpackChunkName: "systemParameterManage" */ './SystemParams.vue'
      )
  }
]
