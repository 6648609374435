const routes = []
if (process.env.VUE_APP_API_ENV !== 'production') {
  routes.push({
    path: '/__apitest',
    name: 'apitest',
    meta: {
      // 是否需要登录鉴权
      auth: false
    },
    component: () => import(/* webpackChunkName: "__apitest" */ './Index.vue')
  })
  routes.push({
    path: '/__emvqrcode',
    name: 'emvqrcode',
    meta: {
      // 是否需要登录鉴权
      auth: false
    },
    component: () =>
      import(/* webpackChunkName: "__emvqrcode" */ './EMVQrcode.vue')
  })
}

export default routes
