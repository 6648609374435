import dialogDragMixin from './DialogDrag'
import { Dialog } from 'element-ui'
export function installDialog() {
  Dialog.methods.handleClose = function () {
    if (typeof this.beforeClose === 'function') {
      this.beforeClose(this.hide)
    } else {
      this.hide()
    }
    // 关闭后重置dialog_warpper的position相关
    let aimDialog = this.$el.getElementsByClassName('el-dialog')[0]
    let wrapperList = document.getElementsByClassName('el-dialog__wrapper')
    let aimWrap = ''
    for (let i = 0; i < wrapperList.length; i++) {
      if (wrapperList[i].childNodes[0] == aimDialog) {
        aimWrap = wrapperList[i]
      }
    }
    window.setTimeout(() => {
      aimWrap.style.left = 0
      aimWrap.style.top = 0
    }, 500)
  }
  Dialog.mixins.push(dialogDragMixin)
}
