<template>
  <el-select
    :value="value"
    @input="$emit('input', $event)"
    :loading="loading"
    v-bind="$attrs"
    @change="$emit('change', $event)"
  >
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="(item.getLabel && item.getLabel()) || item.label"
      :value="item.value"
      :disabled="item.disabled"
    />
  </el-select>
</template>

<script>
export default {
  props: {
    // bizDscd BusinessDistinction
    // pdTpCd Product Type
    // pdTmpltCd Product Template
    // pdCd Product
    type: {
      validator: function (value) {
        // 这个值必须匹配下列字符串中的一个
        return ['bizDscd', 'pdTpCd', 'pdTmpltCd', 'pdCd'].includes(value)
      }
    },
    /**
     * parent value
     */
    bizDscd: String,
    pdTpCd: String,
    pdTmpltCd: String,
    value: String,

    /**
     * cbb service code
     */
    srvcCd: String,
    scrnId: String
  },
  data() {
    return {
      loading: false,
      options: []
    }
  },
  watch: {
    bizDscd(val) {
      this.$emit('input', '')
      this.fetchPdOption(val)
    },
    pdTpCd(val) {
      this.$emit('input', '')
      this.fetchPdOption(this.bizDscd, val)
    },
    pdTmpltCd(val) {
      this.$emit('input', '')
      this.fetchPdOption(this.bizDscd, this.pdTpCd, val)
    }
  },
  created() {
    if (this.type === 'bizDscd') {
      this.fetchBizDscd()
    } else {
      this.fetchPdOption(this.bizDscd, this.pdTpCd, this.pdTmpltCd)
    }
  },
  methods: {
    async fetchBizDscd() {
      this.options = []
      this.loading = true
      let res = await this.$api.requestCBB('PCM0038400', {
        CmnCdSvcGetCdListByCdNbrIn: {
          cdNbr: '80015'
        }
      })
      this.loading = false
      if (res.CmnCdSvcGetCdListByCdNbrOut) {
        this.options = res.CmnCdSvcGetCdListByCdNbrOut.tbl.map(item => {
          return { value: item.cd, label: item.cdNm }
        })
      }
    },
    async fetchPdOption(bizDscd, pdTpCd, pdTmpltCd) {
      console.log('fetchPdOption', this.type, bizDscd, pdTpCd, pdTmpltCd)
      this.options = []
      if (!bizDscd) {
        return
      }
      if (this.type === 'pdTmpltCd' && !pdTpCd) {
        return
      }
      if (this.type === 'pdCd' && !pdTmpltCd) {
        return
      }
      this.loading = true
      let res = await this.$api.requestCBB(this.srvcCd || 'SPD0010401', {
        header: {
          scrnId: this.scrnId
        },
        PdTxSrvcMgmtSvcIn: {
          bizDscd: bizDscd,
          pdTmpltCd: pdTmpltCd,
          pdTpCd: pdTpCd
        }
      })
      this.loading = false
      if (res.PdTxSrvcMgmtSvcOut) {
        this.options = res.PdTxSrvcMgmtSvcOut.tbl.map(item => {
          return { value: item.pdCd, label: item.pdNm }
        })
      }
    }
  }
}
</script>

<style></style>
