<template>
  <el-pagination
    background
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="pgNum"
    @update:current-page="$emit('update:pgNum', $event)"
    :page-sizes="pageSizes"
    :page-size="pageSize || 10"
    @update:page-size="$emit('update:pageSize', $event)"
    :total="total"
    layout="total,->,sizes, prev, pager, next, jumper"
  />
</template>
<script>
export default {
  name: 'MyPagination',
  props: ['pgNum', 'total', 'pageSize', 'viewKey'],
  data() {
    return {
      pageSizes: [10, 20, 30, 50, 100]
    }
  },
  created() {
    console.log('viewKey', this.viewKey)
  },
  methods: {
    handleSizeChange(val) {
      this.$emit('update:pageSize', val)
      this.$emit('size-change', val)
      this.savePagination(val)
    },
    handleCurrentChange(value) {
      this.$emit('current-change', value)
    },
    savePagination(val) {
      localStorage.setItem(`paginationPageSize-${this.viewKey}`, val)
    }
  }
}
</script>
