import i18n from '@/i18n'
i18n.mergeLocaleMessage('en', {
  "OpenAccount": "Open Account",
  "AddMember": "Add Member",
  "RemoveMember": "Remove Member",
  "Transfer": "Transfer",
  "Payment": "Payment",
  "BankTransfer": "Bank Transfer",
  "chat.requestMoney": "Trusty request money",
  "CashOut": "Cash Out",
  "RedPacket.title": "Send Red Packet",
  "FxExchangeBuy": "Fx Exchange Buy",
  "accountStr.fixed": "Fixed",
  "Repayment": "Repayment",
  "TrustyCoinsExchangeBuy": "Trusty Coins Exchange Buy"
})
i18n.mergeLocaleMessage('zh', {
  "OpenAccount": "开户",
  "AddMember": "添加成员",
  "RemoveMember": "移除成员",
  "Transfer": "转账",
  "Payment": "支付",
  "BankTransfer": "银行转账",
  "chat.requestMoney": "Trusty收钱",
  "CashOut": "提现",
  "RedPacket.title": "发红包",
  "FxExchangeBuy": "外汇买入",
  "accountStr.fixed": "定期",
  "Repayment": "还款",
  "TrustyCoinsExchangeBuy": "Trusty币兑入"
})
i18n.mergeLocaleMessage('mm', {
  "OpenAccount": "ဖွင့်ထားသောအကောင့်",
  "AddMember": "အဖွဲ့ဝင်ထည့်မည်",
  "RemoveMember": "အဖွဲ့ဝင်ကို ဖယ်ရှားမည်",
  "Transfer": "ငွေလွှဲ",
  "Payment": "ပေးချေမှု",
  "BankTransfer": "ဘဏ်ငွေလွှဲ",
  "chat.requestMoney": "Trusty ငွေတောင်းခံ",
  "CashOut": "ငွေထုတ်",
  "RedPacket.title": "အန်ပေါင်ပေးရန်",
  "FxExchangeBuy": "Fx Exchange ကိုဝယ်ပါ။",
  "accountStr.fixed": "စာရင်းသေ",
  "Repayment": "ပြန်ပေးဆပ်ခြင်း",
  "TrustyCoinsExchangeBuy": "Trusty အကြွေစေ့လဲလှယ် ဝယ်ယူခြင်း"
})
  