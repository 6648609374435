<i18n src="@/i18n/template/components/PrintPreview.json"></i18n>
<template>
  <el-dialog
    :title="$t('chat.preview')"
    append-to-body
    width="780px"
    :visible="visible"
    @update:visible="$emit('update:visible', $event)"
  >
    <iframe
      ref="content"
      style="
        display: block;
        margin: auto;
        height: 580px;
        width: 730px;
        overflow: auto;
      "
      frameborder="0"
      scrolling="auto"
    ></iframe>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="printClick">
        {{ 'Print' }}
      </el-button>
      <el-button @click="$emit('update:visible', false)">{{
        $t('cancel')
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'print-preview',
  props: {
    visible: Boolean,
    content: String
  },
  watch: {
    visible(val) {
      if (val) {
        this.$nextTick(() => {
          const iframe = this.$refs.content
          if (iframe) {
            iframe.contentDocument.body.innerHTML = `
            <style>
              @page {
                margin: 0;
              }
              table {
                font-size: inherit;
              }
            </style>
            ${this.content}
            `
          }
        })
      }
    }
  },
  methods: {
    printClick() {
      const iframe = this.$refs.content
      iframe.contentWindow.print()
    }
  }
}
</script>
