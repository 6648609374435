/**
 * API加密参数
 */
export const client_id = process.env.VUE_APP_client_id
export const client_secret = process.env.VUE_APP_client_secret
export const timeout = 185000
/**
 * API环境
 */
export const API_ENV = process.env.VUE_APP_API_ENV
export const Host = process.env.VUE_APP_HOST
export const BaseURL = Host + process.env.VUE_APP_URL_PATH
export const CBPBaseURL = BaseURL + process.env.VUE_APP_URL_CBP

export const WebSocket_URL = location.origin + '/ws-api/websocket'
/**
 * 当前运行平台 operation merchant teller
 */
export const Platform = process.env.VUE_APP_Platfrom

export const TRUSTY_TELLER_URL = process.env.VUE_APP_TRUSTY_TELLER_URL
export const TRUSTY_WEB_URL = process.env.VUE_APP_TRUSTY_WEB_URL
export const APP_SCHEME = process.env.VUE_APP_AppScheme
