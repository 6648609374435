import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ElementLocale from 'element-ui/lib/locale'
import en from './lang/en'
import zh from './lang/zh'
import mm from './lang/mm'

Vue.use(VueI18n)

let loaded = []
let elementLoad = {
  loader: lang => {
    let obj = {
      en: () => import('element-ui/lib/locale/lang/en'),
      mm: () => import('./element/mm'),
      zh: () => import('element-ui/lib/locale/lang/zh-CN')
    }
    return obj[lang]()
  },
  en: null,
  mm: null,
  zh: null
}

function setupLang(lang) {
  localStorage.lang = lang
  i18n.locale = lang
  changeElementI18n(lang)
}
function changeElementI18n(lang) {
  if (elementLoad[lang]) {
    return
  }
  return elementLoad.loader(lang).then(res => {
    let localeRes = res.default
    elementLoad[lang] = localeRes
    i18n.mergeLocaleMessage(lang, localeRes)
  })
}
export function changeLang(lang) {
  if (loaded.includes(lang)) {
    if (i18n.locale === lang) {
      return
    }
    setupLang(lang)
    return
  }
  // i18n.mergeLocaleMessage(lang, messages.default)
  setupLang(lang)
  return
}

let locale = localStorage.lang || 'en'
const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: 'en',
  silentTranslationWarn: true,
  formatFallbackMessages: true,
  messages: {
    en,
    zh,
    mm
  }
})

changeLang(locale)

ElementLocale.i18n((key, value) => i18n.t(key, value))

/**
 * 加载服务端api动态返回的多语言
 */
let apiLoader = null
export function loadApiLang() {
  if (!apiLoader) {
    apiLoader = import('./api/api.json').then(res => {
      let localeRes = res.default
      i18n.mergeLocaleMessage('en', localeRes.en)
      i18n.mergeLocaleMessage('zh', localeRes.zh)
      i18n.mergeLocaleMessage('mm', localeRes.mm)
    })
  }
  return apiLoader
}

export default i18n
