export default [
  {
    name: 'GoldRateSetting',
    path: 'gold/rate_setting',
    component: () =>
      import(/* webpackChunkName: "gold" */ './GoldRateSetting.vue')
  },
  {
    name: 'GoldRule',
    path: 'gold/rule',
    component: () => import(/* webpackChunkName: "gold" */ './GoldRule.vue')
  },
  {
    name: 'GoldPaymentManage',
    path: 'gold/payment_manage',
    component: () =>
      import(/* webpackChunkName: "gold" */ './PaymentManage.vue')
  },
  {
    name: 'GoldSettlement',
    path: 'gold/settlement',
    component: () => import(/* webpackChunkName: "gold" */ './Settlement.vue')
  },
  {
    name: 'GoldSettlementDetail',
    path: 'gold/settlement/detail',
    component: () =>
      import(/* webpackChunkName: "gold" */ './SettlementDetailList.vue')
  }
]
