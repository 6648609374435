<template>
  <el-popover placement="top-start" width="300" trigger="hover">
    <i slot="reference" class="el-icon-warning-outline"></i>
    <div class="item" v-for="(item, index) in data" :key="index">
      <span class="title">{{ item.title }}</span>
      <div class="content">{{ item.content }}</div>
    </div>
  </el-popover>
</template>
<script>
export default {
  props: ['data'],
  data() {
    return {}
  }
}
</script>
<style scoped>
.item {
  margin-bottom: 10px;
}
.title {
  font-size: 16;
  color: #101010;
}
.content {
  font-size: 14px;
  color: #797979;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}
</style>
