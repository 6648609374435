export default [
  {
    name: 'AgentManagement',
    path: '/agent/manage',
    component: () =>
      import(/* webpackChunkName: "agent_manage" */ './AgentManagement.vue')
  },
  {
    name: 'AgentResultAnalysis',
    path: '/agent/result_analysis',
    component: () =>
      import(/* webpackChunkName: "agent_manage" */ './AgentResultAnalysis.vue')
  },
  {
    name: 'AgentCustomerAnalysis',
    path: '/agent/customer_analysis',
    component: () =>
      import(
        /* webpackChunkName: "agent_manage" */ './AgentCustomerAnalysis.vue'
      )
  }
]
