import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import router from '../router'
import { addEventListener } from '@/util/event'

import LoadingBar from '@/components/LoadingBar'

Vue.use(Vuex)

function loadModules() {
  const context = require.context('./modules', true, /\.js$/)
  const modules = context.keys().reduce((modules, modulePath) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
    const value = context(modulePath)
    modules[moduleName] = value.default
    return modules
  }, {})
  return {
    context,
    modules
  }
}
const { modules } = loadModules()
const plugins = []
if (process.env.NODE_ENV === 'development') {
  plugins.push(createLogger())
}
const store = new Vuex.Store({
  modules,
  plugins
})

router.beforeEach((to, from, next) => {
  LoadingBar.start()
  // 需要登录
  if (to.meta?.loginAuth === false || store.state.user.login) {
    next()
  } else {
    next({ name: 'login', query: { from: to.fullPath } })
  }
})
router.afterEach((to, from, next) => {
  if (to.name === '404') {
    LoadingBar.error()
  } else {
    LoadingBar.finish()
  }
})
// 退出事件
addEventListener('LOGOUT_EVENT', () => {
  store.dispatch('user/logout', 'expired')
  router.replace({ name: 'login' })
})

export default store
