import i18n from '@/i18n'
i18n.mergeLocaleMessage('en', {
  "HaveApprovalAlert": "You have a new {type} application pending approval",
  "VillageDisburseSuccess": "Village disburse finished",
  "Failed": "Failed",
  "SystemMessage": "System Message",
  "Approval": "Approval",
  "success": "Success",
  "Reject": "Reject",
  "View": "View"
})
i18n.mergeLocaleMessage('zh', {
  "HaveApprovalAlert": "你有一个新的{type}申请等待审批",
  "VillageDisburseSuccess": "放款处理完成",
  "Failed": "失败",
  "SystemMessage": "系统消息",
  "Approval": "同意",
  "success": "成功",
  "Reject": "拒绝",
  "View": "查看"
})
i18n.mergeLocaleMessage('mm', {
  "HaveApprovalAlert": "သင့်တွင် {type}  အားအတည်ပြုရန် တစ်ခုရှိသည်။",
  "VillageDisburseSuccess": "ကျေးရွာငွေပေးချေမှု ပြီးဆုံးခြင်း",
  "Failed": "မအောင်မြင်ပါ",
  "SystemMessage": "System မက်ဆေ့ချ်",
  "Approval": "လက်ခံခြင်း",
  "success": "အောင်မြင်သည်",
  "Reject": "Reject",
  "View": "ကြည့်မည်"
})
  