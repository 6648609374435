export function convertServerMessage(id, sMsg) {
  let chatType = (sMsg.type + '').toLowerCase()
  let channel
  let ext = sMsg.ext || {}
  switch (chatType) {
    case 'chat':
      chatType = 'singlechat'
      channel = sMsg.from
      break
    case 'groupchat':
      chatType = 'groupchat'
      channel = sMsg.to
      break
    case 'chatroom':
      chatType = 'chatroom'
      channel = sMsg.to
      break
    case 'chatRoom':
      chatType = 'chatroom'
      channel = sMsg.to
      break
  }
  let messageType = (sMsg.contentsType + '').toLowerCase()
  switch (messageType) {
    case 'custom':
      messageType = ext.messageType
      break
    case 'audio':
      messageType = 'voice'
      break
    default:
      break
  }
  let msg = {
    id: id,
    mid: sMsg.id,
    channel: channel,
    to: sMsg.to,
    chatType: chatType,
    from: sMsg.from,
    action: sMsg.action,
    status: sMsg.status,
    time: Number(sMsg.time) || Date.now(),
    messageType: messageType,
    ext: sMsg.ext,
    filelength: sMsg.filelength,
    filename: sMsg.filename,
    filetype: sMsg.filetype,
    msg: sMsg.data,
    secret: sMsg.secret,
    url: sMsg.url,
    path: sMsg.path
  }
  return msg
}
