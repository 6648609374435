export default [
  {
    name: 'couponList',
    path: 'couponList',
    component: () => import(/* webpackChunkName: "couponList" */ './index.vue')
  },
  {
    name: 'couponApplied',
    path: '/couponList/couponApplied',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "couponList" */ './CouponApplied.vue')
  },
  {
    name: 'couponUsed',
    path: '/couponList/couponUsed',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "couponList" */ './CouponUsed.vue')
  },
  {
    name: 'couponRelease',
    path: '/couponList/release',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "couponList" */ './CouponRelease.vue')
  },
  {
    name: 'couponStatistics',
    path: '/couponList/couponStatistics',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "campaignStatistics" */ './statistics.vue')
  }
]
