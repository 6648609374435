import '@/i18n/template/api/export.js'
import api from './index'
import i18n from '@/i18n'

export const ExportType = {
  MobilePayment: '1',
  MerchantPayment: '2',
  DepositAccrualSettlement: '3',
  CustPaymentHistory: '4',
  CustTransferHistory: '5',
  CustPointHistory: '6',
  GrabRedPackageHistory: '7',
  CustInfo: '8',
  MerchantInfo: '9',
  MerchantOrderInfo: '10',
  TrustyCoinsHistory: '11',
  AccountHistory: '12',
  CashHistory: '13',
  CustomerException: '14',
  MerchantException: '15',
  MerchantPaymentHistory: '16',
  MerchantRealNameHistory: '17',
  CustomerRealNameHistory: '18',
  // 账单明细导出
  MeterBillDetail: '19',
  // 结算明细
  MeterBillSettleDetail: '20',
  // 可疑监控数据清单
  MonitorEventDataList: '21',
  // teller商户报表按商户
  AcquiringBusinessByMerchant: '22',
  // teller商户报表按地区
  AcquiringBusinessByLoaction: '23',
  // Village Disburse
  VillageDisburseHistory: '24',
  VillageDisburseHistoryDetail: '36',
  // Village Payment
  VillagePaymentHistory: '25',
  // teller Daylyreport
  DailyReport: '26',
  ElectricityBillTownshipTotal: '27',
  ElectricityBillDailyReportByTownship: '28',
  ElectricityBillbyLastPaymentDueList: '29',
  ElectricityBillbyTownshipReceivingReport: '30',
  AccountListByProduct: '31',
  MPortal_OrderList: '32',
  InquiryInternalAccount: '33',
  CustomerLiveHistory: '34',
  MerchantLiveHistory: '35',
  RedPacketReport: '37',
  FinanicalTransferForAllAccount: '38',
  EmeraldLoanSettlement: '39',
  EmeraldLoanBill: '40',
  // teller report TransactionListByTranaction&Channel
  TransactionListByTranactionChannel: '41',
  FinanicalTransaction: '42',
  DailyHomeRentalCharges: '45',
  DailyIncomeOfWater: '46',
  DailyIncomeOfDevelopAndSecuity: '47',
  MothlyRentalCharges: '48',
  MothlyIncomeOfOthers: '49',
  MothlyBalanceDueListForHomeRental: '50',
  DailyIncomeOfShopRental: '51',
  DailyOtherChargesIncomeOfShopRenter: '52',
  MothlyIncomeOfShopRental: '53',
  MothlyOtherChargesIcome: '54',
  MothlyDueListForShopRental: '55',
  BatchOpenAccountDetail: '57',
  MobileTopUpServiceDetail: '58',
  FixedDepositSettlement: '59',
  MerchantSettlementSum: '60',
  MerchantSettlementDetail: '61',
  TransactionListByProduct: '62',
  GLTransactionListByAccountCode: '63',
  GoldPaymentManage: '64',
  GoldExchangeList: '65',
  GoldSettleDetail: '66',
  FXProfitAndLossStatement: '67',
  DailyHomeRentalAndWater: '68',
  MonthReportByTwonship: '69',
  DailyReportByLedgerNo: '70',
  DailyReportByTownship: '71',
  DailyReportByLastDate: '72',
  InquireTransactionList: '',
  BatchOpenPaydayLoanAccountDetail: '73',
  PaydayLoanCreditLimit: '74',
  PaydayLoanLoanBalance: '75',
  PaydayLoanDailyReport: '76',
  PaydayLoanDetailReport: '77'
}

export const ExportTypeOptions = [
  { value: ExportType.MobilePayment, getLabel: () => i18n.t('MobilePayment') },
  {
    value: ExportType.MerchantPayment,
    getLabel: () => i18n.t('MerchantPayment')
  },
  {
    value: ExportType.DepositAccrualSettlement,
    getLabel: () => i18n.t('DepositAccrualSettlement')
  },
  {
    value: ExportType.CustPaymentHistory,
    getLabel: () => i18n.t('CustPaymentHistory')
  },
  {
    value: ExportType.CustTransferHistory,
    getLabel: () => i18n.t('CustTransferHistory')
  },
  {
    value: ExportType.CustPointHistory,
    getLabel: () => i18n.t('CustPointHistory')
  },
  {
    value: ExportType.GrabRedPackageHistory,
    getLabel: () => i18n.t('GrabRedPackageHistory')
  },
  { value: ExportType.CustInfo, getLabel: () => i18n.t('CustInfo') },
  { value: ExportType.MerchantInfo, getLabel: () => i18n.t('MerchantInfo') },
  {
    value: ExportType.MerchantOrderInfo,
    getLabel: () => i18n.t('MerchantOrderInfo')
  },
  {
    value: ExportType.TrustyCoinsHistory,
    getLabel: () => i18n.t('TrustyCoinsHistory')
  },
  {
    value: ExportType.AccountHistory,
    getLabel: () => i18n.t('AccountHistory')
  },
  { value: ExportType.CashHistory, getLabel: () => i18n.t('CashHistory') },
  {
    value: ExportType.CustomerException,
    getLabel: () => i18n.t('CustomerException')
  },
  {
    value: ExportType.MerchantException,
    getLabel: () => i18n.t('MerchantException')
  },
  {
    value: ExportType.MerchantPaymentHistory,
    getLabel: () => i18n.t('MerchantPaymentHistory')
  },
  {
    value: ExportType.MerchantRealNameHistory,
    getLabel: () => i18n.t('MerchantRealNameHistory')
  },
  {
    value: ExportType.CustomerRealNameHistory,
    getLabel: () => i18n.t('CustomerRealNameHistory')
  },
  {
    value: ExportType.MeterBillDetail,
    getLabel: () => i18n.t('ElectricityBillDetail')
  },
  {
    value: ExportType.MeterBillSettleDetail,
    getLabel: () => i18n.t('ElectricityBillSettlementDetail')
  },
  {
    value: ExportType.MonitorEventDataList,
    getLabel: () => i18n.t('MonitorEventDataList')
  },
  {
    value: ExportType.AcquiringBusinessByMerchant,
    getLabel: () => i18n.t('AcquiringBusinessByMerchant')
  },
  {
    value: ExportType.AcquiringBusinessByLoaction,
    getLabel: () => i18n.t('AcquiringBusinessByLoaction')
  },
  {
    value: ExportType.VillageDisburseHistory,
    getLabel: () => i18n.t('VillageDisburseHistory')
  },
  {
    value: ExportType.VillagePaymentHistory,
    getLabel: () => i18n.t('VillagePaymentHistory')
  },
  {
    value: ExportType.DailyReport,
    getLabel: () => i18n.t('DailyReport')
  },
  {
    value: ExportType.ElectricityBillTownshipTotal,
    getLabel: () => i18n.t('ElectricityBillTownshipTotalReport')
  },
  {
    value: ExportType.ElectricityBillDailyReportByTownship,
    getLabel: () => i18n.t('ElectricityBillDailyReportByTownship')
  },
  {
    value: ExportType.ElectricityBillbyLastPaymentDueList,
    getLabel: () => i18n.t('ElectricityBillbyLastPaymentDueList')
  },
  {
    value: ExportType.ElectricityBillbyTownshipReceivingReport,
    getLabel: () => i18n.t('ElectricityBillbyTownshipReceivingReport')
  },
  {
    value: ExportType.AccountListByProduct,
    getLabel: () => i18n.t('AccountListByProduct')
  },
  {
    value: ExportType.CustomerLiveHistory,
    getLabel: () => i18n.t('CustomerLiveHistory')
  },
  {
    value: ExportType.MerchantLiveHistory,
    getLabel: () => i18n.t('MerchantLiveHistory')
  },
  {
    value: ExportType.VillageDisburseHistoryDetail,
    getLabel: () => i18n.t('VillageDisburseHistoryDetail')
  },
  {
    value: ExportType.InquiryInternalAccount,
    getLabel: () => i18n.t('InquiryInternalAccount')
  },
  {
    value: ExportType.RedPacketReport,
    getLabel: () => i18n.t('RedPacketReport')
  },
  {
    value: ExportType.FinanicalTransferForAllAccount,
    getLabel: () => i18n.t('FinanicalTransferForAllAccount')
  },
  {
    value: ExportType.EmeraldLoanSettlement,
    getLabel: () => i18n.t('EmeraldLoanSettlement')
  },
  {
    value: ExportType.EmeraldLoanBill,
    getLabel: () => i18n.t('EmeraldLoanBill')
  },
  {
    value: ExportType.TransactionListByTranactionChannel,
    getLabel: () => i18n.t('TransactionListByTranactionChannel')
  },
  {
    value: ExportType.FinanicalTransaction,
    getLabel: () => i18n.t('FinanicalTransaction')
  },
  {
    value: ExportType.DailyHomeRentalCharges,
    getLabel: () => i18n.t('DailyReportforHomerentalcharges')
  },
  {
    value: ExportType.DailyIncomeOfWater,
    getLabel: () => i18n.t('DailyReportforDailyIncomeofWaterMeter')
  },
  {
    value: ExportType.DailyIncomeOfDevelopAndSecuity,
    getLabel: () => i18n.t('DailyReportforDailyIncomeofDevelopmentandSecuity')
  },
  {
    value: ExportType.MothlyRentalCharges,
    getLabel: () => i18n.t('MonthlyReportforRentalCharges')
  },
  {
    value: ExportType.MothlyIncomeOfOthers,
    getLabel: () => i18n.t('MonthlyReportforIncomeofOthersCharges')
  },
  {
    value: ExportType.MothlyBalanceDueListForHomeRental,
    getLabel: () => i18n.t('MonthBalanceDueListforHomeRentalCharges')
  },

  {
    value: ExportType.DailyIncomeOfShopRental,
    getLabel: () => i18n.t('DailyReportforIncomeofShopRental')
  },
  {
    value: ExportType.DailyOtherChargesIncomeOfShopRenter,
    getLabel: () => i18n.t('DailyReportforOtherChargesIncomeofShopRenter')
  },
  {
    value: ExportType.MothlyIncomeOfShopRental,
    getLabel: () => i18n.t('MonthlyReportforShopRentalIncomeofShopRenter')
  },
  {
    value: ExportType.MothlyOtherChargesIcome,
    getLabel: () => i18n.t('MonthlyReportforOtherChargesIncome')
  },
  {
    value: ExportType.MothlyDueListForShopRental,
    getLabel: () => i18n.t('MonthDueListforShopRental')
  }
]

export const MPortalTypeOptions = [
  {
    value: ExportType.AccountHistory,
    getLabel: () => i18n.t('AccountHistory')
  },
  {
    value: ExportType.MPortal_OrderList,
    getLabel: () => i18n.t('OrderList')
  }
]

export function exportFile(busTp, param) {
  return api.post({
    url: 'manager/export/addExportTask',
    data: {
      busTp,
      exportParam: JSON.stringify(param)
    }
  })
}
