import i18n from '@/i18n'
i18n.mergeLocaleMessage('en', {
  "MobilePayment": "Mobile Payment",
  "MerchantPayment": "Merchant payment",
  "DepositAccrualSettlement": "Cash Accrual Settlement",
  "CustPaymentHistory": "Cust Payment History",
  "CustTransferHistory": "Cust Transfer History",
  "CustPointHistory": "Cust Point History",
  "GrabRedPackageHistory": "Grab RedPackage History",
  "CustInfo": "Customer Infomation",
  "MerchantInfo": "Merchant Infomation",
  "MerchantOrderInfo": "Merchant Order Infomation",
  "TrustyCoinsHistory": "Trusty Coins History",
  "AccountHistory": "Account History",
  "CashHistory": "Cash History",
  "CustomerException": "Customer Exception",
  "MerchantException": "Merchant Exception",
  "MerchantPaymentHistory": "Merchant Payment History",
  "MerchantRealNameHistory": "Merchant Real Name History",
  "CustomerRealNameHistory": "Customer Real Name History",
  "ElectricityBillDetail": "Electric Bill Detail",
  "ElectricityBillSettlementDetail": "Electric Bill Settlement Detail",
  "MonitorEventDataList": "Monitor Event Data List",
  "AcquiringBusinessByMerchant": "Business Report by Merchant",
  "AcquiringBusinessByLoaction": "Business Report by Region",
  "VillageDisburseHistory": "Village Disburse History",
  "VillagePaymentHistory": "Village Payment History",
  "DailyReport": "Daily Report",
  "ElectricityBillTownshipTotalReport": "Township Total Report",
  "ElectricityBillDailyReportByTownship": "Daily Report",
  "ElectricityBillbyLastPaymentDueList": "By Last Payment Due List",
  "ElectricityBillbyTownshipReceivingReport": "By Township Receiving Report",
  "AccountListByProduct": "Account List By Product",
  "CustomerLiveHistory": "Customer Live History",
  "MerchantLiveHistory": "Merchant Live History",
  "VillageDisburseHistoryDetail": "Village Disburse History Detail",
  "InquiryInternalAccount": "Inquiry Internal Account",
  "RedPacketReport": "RedPacket Report",
  "FinanicalTransferForAllAccount": "Financial Transfer For All Account",
  "EmeraldLoanSettlement": "Emerald Loan Settlement",
  "EmeraldLoanBill": "Emerald Loan Bill",
  "TransactionListByTranactionChannel": "Transaction List By Transaction Channel",
  "FinanicalTransaction": "Finanical Transaction",
  "DailyReportforHomerentalcharges": "Home D-Income for Rental Charge",
  "DailyReportforDailyIncomeofWaterMeter": "Home D-Income for Water Charge",
  "DailyReportforDailyIncomeofDevelopmentandSecuity": "Home D-Income for Dev&Sec Charge",
  "MonthlyReportforRentalCharges": "Home M-Income for Rental Charge",
  "MonthlyReportforIncomeofOthersCharges": "Home M-Income for Other Charges",
  "MonthBalanceDueListforHomeRentalCharges": "Home Monthly Due",
  "DailyReportforIncomeofShopRental": "Shop D-Income for Rental Charge",
  "DailyReportforOtherChargesIncomeofShopRenter": "Shop D-Income for Other Charge",
  "MonthlyReportforShopRentalIncomeofShopRenter": "Shop M-Income for Rental Charge",
  "MonthlyReportforOtherChargesIncome": "Shop M-Income for Other Charges",
  "MonthDueListforShopRental": "Shop Monthly Due",
  "OrderList": "Order List"
})
i18n.mergeLocaleMessage('zh', {
  "MobilePayment": "手机支付",
  "MerchantPayment": "商户支付",
  "DepositAccrualSettlement": "Cash Accrual Settlement",
  "CustPaymentHistory": "客户支付历史",
  "CustTransferHistory": "客户转账历史",
  "CustPointHistory": "客户积分历史",
  "GrabRedPackageHistory": "抢红包历史",
  "CustInfo": "客户信息",
  "MerchantInfo": "商户信息",
  "MerchantOrderInfo": "商户订单信息",
  "TrustyCoinsHistory": "Trusty币历史",
  "AccountHistory": "账户历史",
  "CashHistory": "现金历史",
  "CustomerException": "客户异常信息",
  "MerchantException": "商户异常信息",
  "MerchantPaymentHistory": "商户支付历史",
  "MerchantRealNameHistory": "商户实名历史",
  "CustomerRealNameHistory": "客户实名历史",
  "ElectricityBillDetail": "电费账单明细",
  "ElectricityBillSettlementDetail": "电费账单结算明细",
  "MonitorEventDataList": "监控事件列表",
  "AcquiringBusinessByMerchant": "按商户统计的收单业务报表",
  "AcquiringBusinessByLoaction": "按地区统计的收单业务报表",
  "VillageDisburseHistory": "村庄放款历史",
  "VillagePaymentHistory": "村庄付款历史",
  "DailyReport": "日报",
  "ElectricityBillTownshipTotalReport": "电费乡镇总计报表",
  "ElectricityBillDailyReportByTownship": "电费日报",
  "ElectricityBillbyLastPaymentDueList": "电费最后付款到期清单",
  "ElectricityBillbyTownshipReceivingReport": "电费收单报表",
  "AccountListByProduct": "按产品列出的帐户列表",
  "CustomerLiveHistory": "客户直播历史记录",
  "MerchantLiveHistory": "商户直播历史记录",
  "VillageDisburseHistoryDetail": "放贷历史详情",
  "InquiryInternalAccount": "查询内部账户",
  "RedPacketReport": "红包报表",
  "FinanicalTransferForAllAccount": "所有账户的财务转账",
  "EmeraldLoanSettlement": "翡翠贷款结算",
  "EmeraldLoanBill": "翡翠贷款",
  "TransactionListByTranactionChannel": "交易列表（按交易渠道）",
  "FinanicalTransaction": "金融交易啊",
  "DailyReportforHomerentalcharges": "房屋租赁费用收入日报",
  "DailyReportforDailyIncomeofWaterMeter": "房屋水费账单收入日报",
  "DailyReportforDailyIncomeofDevelopmentandSecuity": "房屋开发费和安全费收入日报",
  "MonthlyReportforRentalCharges": "房屋租赁费用收入月报",
  "MonthlyReportforIncomeofOthersCharges": "其他费用收入月报",
  "MonthBalanceDueListforHomeRentalCharges": "房屋未支付租金月报",
  "DailyReportforIncomeofShopRental": "商铺租金收入日报",
  "DailyReportforOtherChargesIncomeofShopRenter": "商铺租赁其他费用收入日报",
  "MonthlyReportforShopRentalIncomeofShopRenter": "商铺租金收入月报",
  "MonthlyReportforOtherChargesIncome": "商铺租赁其他费用收入月报",
  "MonthDueListforShopRental": "商铺未支付租金月报",
  "OrderList": "订单列表"
})
i18n.mergeLocaleMessage('mm', {
  "MobilePayment": "Mobile Payment",
  "MerchantPayment": "Merchant payment",
  "DepositAccrualSettlement": "Cash Accrual Settlement",
  "CustPaymentHistory": "Cust Payment History",
  "CustTransferHistory": "Cust Transfer History",
  "CustPointHistory": "Cust Point History",
  "GrabRedPackageHistory": "Grab RedPackage History",
  "CustInfo": "Customer Infomation",
  "MerchantInfo": "Merchant Infomation",
  "MerchantOrderInfo": "Merchant Order Infomation",
  "TrustyCoinsHistory": "Trusty Coins History",
  "AccountHistory": "Account History",
  "CashHistory": "Cash History",
  "CustomerException": "Customer ခြွင်းချက်",
  "MerchantException": "Merchant ခြွင်းချက်",
  "MerchantPaymentHistory": "ကုန်သည် ငွေပေးချေမှုမှတ်တမ်း",
  "MerchantRealNameHistory": "ကုန်သည်အမည်ရင်း မှတ်တမ်း",
  "CustomerRealNameHistory": "သုံးစွဲသူအမည်ရင်း မှတ်တမ်း",
  "ElectricityBillDetail": "လျှပ်စစ်မီတာခအသေးစိတ်",
  "ElectricityBillSettlementDetail": "လျှပ်စစ်မီတာခ ငွေစာရင်းအသေးစိတ်",
  "MonitorEventDataList": "ဖြစ်ရပ်ဒေတာစာရင်းကို စောင့်ကြည့်ပါ။",
  "AcquiringBusinessByMerchant": "Merchant မှ လုပ်ငန်းအစီရင်ခံစာရယူခြင်း။",
  "AcquiringBusinessByLoaction": "ဒေသအလိုက် လုပ်ငန်းအစီရင်ခံစာရယူခြင်း",
  "VillageDisburseHistory": "ကျေးရွာ ငွေချမှု မှတ်တမ်း",
  "VillagePaymentHistory": "ကျေးရွာ ပေးချေမှု မှတ်တမ်း",
  "DailyReport": "နေ့စဥ် စာရင်း",
  "ElectricityBillTownshipTotalReport": "Electric Bill Township Total Report",
  "ElectricityBillDailyReportByTownship": "Electric Bill Daily Report",
  "ElectricityBillbyLastPaymentDueList": "Electric Bill by Last Payment Due List",
  "ElectricityBillbyTownshipReceivingReport": "Electric Bill by Township Receiving Report",
  "AccountListByProduct": "ထုတ်ကုန်အလိုက် အကောင့်စာရင်း",
  "CustomerLiveHistory": "Customer Live  မှတ်တမ်း",
  "MerchantLiveHistory": "Merchant Live မှတ်တမ်း",
  "VillageDisburseHistoryDetail": "ကျေးရွာငွေပေးချေမှုမှတ်တမ်း အသေးစိတ်",
  "InquiryInternalAccount": "Inquiry Internal အကောင့်",
  "RedPacketReport": "RedPacket အစီရင်ခံစာ",
  "FinanicalTransferForAllAccount": "အကောင့်အားလုံးအတွက် ငွေကြေးလွှဲပြောင်းမှု",
  "EmeraldLoanSettlement": "မြစိမ်းရောင်ချေးထုတ်ခြင်း",
  "EmeraldLoanBill": "မြစိမ်းရောင်ချေးငွေဘေလ်",
  "TransactionListByTranactionChannel": "Transaction Channel မှ ငွေပေးငွေယူစာရင်း",
  "FinanicalTransaction": "Finanical Transaction",
  "DailyReportforHomerentalcharges": "အိမ်လခ နေ့စဥ်၀င်ငွေ စာရင်း",
  "DailyReportforDailyIncomeofWaterMeter": "ရေမီတာခ နေ့စဥ်၀င်ငွေ စာရင်း",
  "DailyReportforDailyIncomeofDevelopmentandSecuity": "အိမ်သာယာရေးနှင့်လုံခြုံရေး ရန်ပုံငွေ နေ့စဥ်၀င်ငွေ စာရင်း",
  "MonthlyReportforRentalCharges": "အိမ်လခ လစဥ်၀င်ငွေ စာရင်း",
  "MonthlyReportforIncomeofOthersCharges": "အခြားသော အခကြေးငွေများ လစဥ်၀င်ငွေ စာရင်း",
  "MonthBalanceDueListforHomeRentalCharges": "Home Monthly Due",
  "DailyReportforIncomeofShopRental": "ဆိုင်လခ နေ့စဥ်၀င်ငွေ စာရင်း",
  "DailyReportforOtherChargesIncomeofShopRenter": "ဆိုင်မှအခြားသော အခကြေးငွေများ နေ့စဥ်၀င်ငွေ စာရင်း",
  "MonthlyReportforShopRentalIncomeofShopRenter": "ဆိုင်လခ လစဥ်၀င်ငွေ စာရင်း",
  "MonthlyReportforOtherChargesIncome": "ဆိုင်မှအခြားသော အခကြေးငွေများ လစဥ်၀င်ငွေ စာရင်း",
  "MonthDueListforShopRental": "Shop Monthly Due",
  "OrderList": "အော်ဒါစာရင်း"
})
  