import i18n from '@/i18n'
i18n.mergeLocaleMessage('en', {
  "loginFromOther": "Your account is already logged in on other device, please login again.",
  "messageRecallFailed": "Messsage recall failed"
})
i18n.mergeLocaleMessage('zh', {
  "loginFromOther": "您的账号已在其他设备登录,请重新登录",
  "messageRecallFailed": "消息撤回失败"
})
i18n.mergeLocaleMessage('mm', {
  "loginFromOther": "Your account is already logged in on other device, please login again.",
  "messageRecallFailed": "Messsage recall failed"
})
  