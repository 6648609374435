<template>
  <el-popover
    placement="bottom"
    title=""
    content=""
    width="500"
    :value="showCard"
    @input="popoverChange"
  >
    <div
      slot="reference"
      class="el-date-editor el-range-editor el-input__inner el-date-editor--monthrange el-range-editor--mini"
    >
      <i class="el-input__icon el-range__icon el-icon-date"></i>
      <input
        type="text"
        readonly="readonly"
        autocomplete="off"
        class="el-range-input"
        v-model="showText"
      />
      <template v-if="isRange">
        <span class="el-range-separator">-</span>
        <input
          type="text"
          readonly="readonly"
          autocomplete="off"
          class="el-range-input"
          v-model="showText2"
        />
      </template>
      <!-- <i class="el-input__icon el-range__close-icon"></i> -->
    </div>
    <div v-if="isRange" class="year-picker">
      <div class="year-picker-year">
        <el-button
          icon="el-icon-d-arrow-left"
          type="text"
          style="color: #303133"
          @click="changeBaseYear('prev', 'start')"
        ></el-button>
        <div class="year-picker-year__text">
          {{ yearStart }}~{{ yearStart + 10 }}
        </div>
        <el-button
          v-if="yearStart < yearStart2 - 10"
          icon="el-icon-d-arrow-right"
          type="text"
          style="color: #303133"
          @click="changeBaseYear('next', 'start')"
        ></el-button>
        <el-button
          v-if="yearStart2 > yearStart + 10"
          icon="el-icon-d-arrow-left"
          type="text"
          style="color: #303133"
          @click="changeBaseYear('prev', 'end')"
        ></el-button>
        <div class="year-picker-year__text">
          {{ yearStart2 }}~{{ yearStart2 + 10 }}
        </div>
        <el-button
          icon="el-icon-d-arrow-right"
          type="text"
          style="color: #303133"
          @click="changeBaseYear('next', 'end')"
        ></el-button>
      </div>
      <div class="year-picker-range-quarter">
        <div class="year-picker-quarter">
          <div
            v-for="item in yearList"
            :key="item"
            :class="{
              'year-picker-quarter-item': true,
              'year-picker-quarter-item--selected': isSelected(item),
              'year-picker-quarter-item--disabled': item > maxYear
            }"
            @click="changeYear(item)"
          >
            <span>{{ item }}</span>
          </div>
        </div>
        <div class="year-picker-quarter">
          <div
            v-for="item in yearList2"
            :key="item"
            :class="{
              'year-picker-quarter-item': true,
              'year-picker-quarter-item--selected': isSelected(item),
              'year-picker-quarter-item--disabled': item > maxYear
            }"
            @click="changeYear(item)"
          >
            <span>{{ item }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="year-picker">
      <!-- 年份切换 -->
      <div class="year-picker-year">
        <el-button
          icon="el-icon-d-arrow-left"
          type="text"
          style="color: #303133"
          @click="changeBaseYear('prev')"
        ></el-button>
        <div class="year-picker-year__text">
          {{ yearStart }}~{{ yearStart + 10 }}
        </div>
        <el-button
          icon="el-icon-d-arrow-right"
          type="text"
          style="color: #303133"
          @click="changeBaseYear('next')"
        ></el-button>
      </div>

      <div class="year-picker-quarter">
        <div
          v-for="item in yearList"
          :key="item"
          :class="{
            'year-picker-quarter-item': true,
            'year-picker-quarter-item--selected': isSelected(item),
            'year-picker-quarter-item--disabled': item > maxYear
          }"
          @click="changeYear(item)"
        >
          <span>{{ item }}</span>
        </div>
      </div>
    </div>
  </el-popover>
</template>

<script>
export default {
  name: 'YearPicker',
  props: {
    value: {
      type: [String, Array],
      default: ''
    },
    max: {
      type: [String, Number, Date],
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showCard: false,
      yearStart: null,
      yearStart2: null,
      rangeValueStart: null
    }
  },
  computed: {
    isRange() {
      return this.type === 'range'
    },
    //格式化当前选中的时间并展示
    showText() {
      if (this.value) {
        let value = this.value
        if (this.isRange) {
          value = this.value[0]
        }
        return value || ''
      }
      return ''
    },
    //格式化当前选中的时间并展示
    showText2() {
      if (this.value) {
        let value = this.value
        if (this.isRange) {
          value = this.value[1]
        }
        return value || ''
      }
      return ''
    },
    yearList() {
      let i = 0
      let list = []
      let max = this.isRange ? 9 : 10
      let maxYear = 999999
      if (this.max) {
        maxYear = new Date(this.max).getFullYear()
      }
      while (i <= max) {
        list.push(this.yearStart + i)
        i++
      }
      return list
    },
    yearList2() {
      let i = 0
      let list = []

      while (i <= 10) {
        list.push(this.yearStart2 + i)
        i++
      }
      return list
    },
    maxYear() {
      let maxYear = 99999
      if (this.max) {
        maxYear = new Date(this.max).getFullYear() || 99999
      }
      return maxYear
    }
  },
  created() {
    let start = null
    let end = null
    if (this.isRange) {
      start = this.value ? this.value[0] : null
      end = this.value ? this.value[1] : null
    } else {
      start = this.value
    }
    if (!start) {
      start = new Date().getFullYear()
    }
    start = parseInt(start / 10) * 10
    if (!end) {
      end = start + 10
    }
    end = parseInt(end / 10) * 10
    if (end === start) {
      end = start + 10
    }
    this.yearStart2 = end
    this.yearStart = start
  },
  methods: {
    isSelected(value) {
      if (this.isRange) {
        if (this.rangeValueStart) {
          return this.rangeValueStart === value
        }
        let valueArr = this.value || []
        return valueArr[0] === value || valueArr[1] === value
      }
      return this.value === value
    },
    popoverChange(val) {
      this.showCard = val
      if (val) {
        this.rangeValueStart = null
      }
    },
    //切换年份
    changeBaseYear(type = '', startOrEnd) {
      if (startOrEnd === 'end') {
        if (type == 'next') {
          this.yearStart2 = this.yearStart2 + 10
        } else if (type == 'prev') {
          if (this.yearStart2 <= 1900) return
          this.yearStart2 = this.yearStart2 - 10
        }
        return
      }
      if (type == 'next') {
        this.yearStart = this.yearStart + 10
      } else if (type == 'prev') {
        if (this.yearStart <= 1900) return
        this.yearStart = this.yearStart - 10
      }
    },
    changeYear(year) {
      if (Number(year) > this.maxYear) return
      if (!this.isRange) {
        this.$emit('input', year)
        this.$emit('change', year)
        return
      }
      if (this.rangeValueStart) {
        let start = this.rangeValueStart
        let end = year
        let valueArr = []
        if (Number(start) > Number(end)) {
          let temp = start
          start = end
          end = temp
        }
        valueArr = [start, end]
        this.$emit('input', valueArr)
        this.$emit('change', valueArr)
        this.showCard = false
        this.rangeValueStart = null
      } else {
        this.rangeValueStart = year
      }
    }
  }
}
</script>

<style lang="scss">
.year-picker-input {
  width: 200px;
}
.year-picker-year {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e4e7ed;
  .year-picker-year__text {
    flex: 1;
    text-align: center;
    line-height: 35px;
    font-size: 16px;
    font-weight: 500;
    color: #333;
  }
}
.year-picker-range-quarter {
  display: flex;
  flex-direction: row;
  .year-picker-quarter {
    flex: 1;
  }
  .year-picker-quarter:first-of-type {
    margin-right: 10px;
    border-right: 1px solid #e5e5e5;
    // .year-picker-quarter-item:last-of-type span {
    //   background: none !important;
    //   color: #aaa;
    // }
  }
}
.year-picker-quarter {
  margin-top: 10px;
  padding: 30px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.year-picker-quarter-item {
  width: 33%;
  padding: 0 6px;
  margin-bottom: 4px;
  box-sizing: border-box;
  font-size: 14px;
  color: #333;
  text-align: center;
  cursor: pointer;
  > span {
    display: block;
    padding: 4px 6px;
  }
}
.year-picker-quarter-item:hover > span {
  background-color: #e5e5e5;
  border-radius: 4px;
}
.year-picker-quarter-item--selected > span,
.year-picker-quarter-item--selected:hover > span {
  background-color: $--color-primary;
  color: #ffffff;
  border-radius: 4px;
}
.year-picker-quarter-item--disabled > span {
  background-color: inherit !important;
  color: #ccc !important;
  cursor: not-allowed;
}
</style>
