export default [
  {
    path: '/meter/rule',
    name: 'PaymentBusinessRules',
    component: () =>
      import(/* webpackChunkName: "meter" */ './Meter/MeterRule.vue')
  },
  {
    path: '/meter/list',
    name: 'ElectricityBillManagement',
    component: () =>
      import(/* webpackChunkName: "meter" */ './Meter/MeterList.vue')
  },
  {
    path: '/meter/list/detail',
    name: 'ElectricityBillDetail',
    component: () =>
      import(/* webpackChunkName: "meter" */ './Meter/MeterDetailList.vue')
  },
  {
    path: '/meter/settlement',
    name: 'ElectricityBillSettlementmanagement',
    component: () =>
      import(/* webpackChunkName: "meter" */ './Meter/MeterSettlement.vue')
  },
  {
    path: '/meter/settlement/detail',
    name: 'ElectricityBillSettlementDetail',
    component: () =>
      import(
        /* webpackChunkName: "meter" */ './Meter/MeterSettlementDetailList.vue'
      )
  },
  {
    path: '/meter/report/township_total',
    name: 'ElectricityBillTownshipTotalReport',
    component: () =>
      import(
        /* webpackChunkName: "meter_report" */ './Meter/Report/ElectricityBillTownshipTotal.vue'
      )
  },
  {
    path: '/meter/report/township_daily',
    name: 'ElectricityBillDailyReportByTownship',
    component: () =>
      import(
        /* webpackChunkName: "meter_report" */ './Meter/Report/ElectricityBillDailyReportByTownship.vue'
      )
  },
  {
    path: '/meter/report/payment_due',
    name: 'ElectricityBillbyLastPaymentDueList',
    component: () =>
      import(
        /* webpackChunkName: "meter_report" */ './Meter/Report/ElectricityBillbyLastPaymentDueList.vue'
      )
  },
  {
    path: '/meter/report/township_receiving',
    name: 'ElectricityBillbyTownshipReceivingReport',
    component: () =>
      import(
        /* webpackChunkName: "meter_report" */ './Meter/Report/ElectricityBillbyTownshipReceivingReport.vue'
      )
  },
  {
    path: '/meter/report/ayeyarwaddy',
    name: 'AyeyarwaddyElectricityBillReport',
    component: () =>
      import(
        /* webpackChunkName: "meter_report" */ './Meter/Report/Ayeyarwaddy/index.vue'
      )
  },
  {
    path: '/meter/report/mandalay',
    name: 'MandalayElectricityBillReport',
    component: () =>
      import(
        /* webpackChunkName: "meter_report" */ './Meter/Report/Mandalay/index.vue'
      )
  },
  {
    path: '/meter/report/naypyitaw',
    name: 'NayPyiTawElectricityBillReport',
    component: () =>
      import(
        /* webpackChunkName: "meter_report" */ './Meter/Report/NayPyiTaw/index.vue'
      )
  },
  {
    path: '/meter/report/yangon',
    name: 'YangonElectricityBillReport',
    component: () =>
      import(
        /* webpackChunkName: "meter_report" */ './Meter/Report/Yangon/index.vue'
      )
  },
  // 房租
  {
    path: '/rental/contract_manage',
    name: 'RentalContractManage',
    component: () =>
      import(
        /* webpackChunkName: "rental" */ './Rental/RentalContractManage.vue'
      )
  },
  {
    path: '/rental/bill_manage',
    name: 'RentalBillManage',
    component: () =>
      import(/* webpackChunkName: "rental" */ './Rental/RentalBillManage.vue')
  },
  {
    path: '/rental/payment',
    name: 'RentalPaymentManage',
    component: () =>
      import(/* webpackChunkName: "rental" */ './Rental/PaymentManage.vue')
  },
  {
    path: '/rental/cash_pay',
    name: 'RentalCashPayAndReduced',
    component: () =>
      import(
        /* webpackChunkName: "rental" */ './Rental/CashPaymentAndReduced.vue'
      )
  },
  {
    path: '/rental/rate_setting',
    name: 'RentalRateSetting',
    component: () =>
      import(/* webpackChunkName: "rental" */ './Rental/RentalRateSetting.vue')
  },
  {
    path: '/rental/prepaid',
    name: 'RentalPrepaid',
    component: () =>
      import(/* webpackChunkName: "rental" */ './Rental/RentalPrepaid.vue')
  },
  {
    path: '/rental/settlement',
    name: 'RentalSettlementManagement',
    component: () =>
      import(/* webpackChunkName: "rental" */ './Rental/RentalSettlement.vue')
  },
  {
    path: '/rental/settlement/detail',
    name: 'RentalSettlementDetail',
    component: () =>
      import(
        /* webpackChunkName: "rental" */ './Rental/RentalSettlementDetailList.vue'
      )
  },
  {
    path: '/report/daily/home_rental_charges',
    name: 'DailyHomeRentalCharges',
    component: () =>
      import(
        /* webpackChunkName: "rental_report" */ './Rental/Report/Mandalay/DailyHomeRentalCharges.vue'
      )
  },
  {
    path: '/report/daily/income_water',
    name: 'DailyIncomeOfWater',
    component: () =>
      import(
        /* webpackChunkName: "rental_report" */ './Rental/Report/Mandalay/DailyIncomeOfWater.vue'
      )
  },
  {
    path: '/report/daily/income_develop_secuity',
    name: 'DailyIncomeOfDevelopAndSecuity',
    component: () =>
      import(
        /* webpackChunkName: "rental_report" */ './Rental/Report/Mandalay/DailyIncomeOfDevelopAndSecuity.vue'
      )
  },
  {
    path: '/report/monthly/rental_charges',
    name: 'MothlyRentalCharges',
    component: () =>
      import(
        /* webpackChunkName: "rental_report" */ './Rental/Report/Mandalay/MothlyRentalCharges.vue'
      )
  },
  {
    path: '/report/monthly/income_others',
    name: 'MothlyIncomeOfOthers',
    component: () =>
      import(
        /* webpackChunkName: "rental_report" */ './Rental/Report/Mandalay/MothlyIncomeOfOthers.vue'
      )
  },
  {
    path: '/report/monthly/balance_due_home_rental',
    name: 'MothlyBalanceDueListForHomeRental',
    component: () =>
      import(
        /* webpackChunkName: "rental_report" */ './Rental/Report/Mandalay/MothlyBalanceDueListForHomeRental.vue'
      )
  },
  {
    path: '/report/daily/income_shop_rental',
    name: 'DailyIncomeOfShopRental',
    component: () =>
      import(
        /* webpackChunkName: "rental_report" */ './Rental/Report/Mandalay/DailyIncomeOfShopRental.vue'
      )
  },
  {
    path: '/report/daily/other_income_shop',
    name: 'DailyOtherChargesIncomeOfShopRenter',
    component: () =>
      import(
        /* webpackChunkName: "rental_report" */ './Rental/Report/Mandalay/DailyOtherChargesIncomeOfShopRenter.vue'
      )
  },
  {
    path: '/report/monthly/income_shop_renter',
    name: 'MothlyIncomeOfShopRental',
    component: () =>
      import(
        /* webpackChunkName: "rental_report" */ './Rental/Report/Mandalay/MothlyIncomeOfShopRental.vue'
      )
  },
  {
    path: '/report/monthly/other_charges_income',
    name: 'MothlyOtherChargesIcome',
    component: () =>
      import(
        /* webpackChunkName: "rental_report" */ './Rental/Report/Mandalay/MothlyOtherChargesIcome.vue'
      )
  },
  {
    path: '/report/monthly/due_list_shop',
    name: 'MothlyDueListForShopRental',
    component: () =>
      import(
        /* webpackChunkName: "rental_report" */ './Rental/Report/Mandalay/MothlyDueListForShopRental.vue'
      )
  },
  {
    path: '/report/daily/home_rental_and_water',
    name: 'DailyHomeRentalAndWater',
    component: () =>
      import(
        /* webpackChunkName: "rental_report" */ './Rental/Report/Mandalay/DailyHomeRentalAndWater.vue'
      )
  }
]
