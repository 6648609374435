export default [
  {
    name: 'EmeraldBillManage',
    path: '/emerlad_manage',
    component: () =>
      import(/* webpackChunkName: "emerald" */ './EmeraldBillManage.vue')
  },
  {
    name: 'EmeraldBillSettlement',
    path: '/emerlad_settlement',
    component: () =>
      import(/* webpackChunkName: "emerald" */ './EmeraldBillSettlement.vue')
  },
  {
    name: 'EmeraldBulkDisburseManage',
    path: '/emerlad_bulk',
    component: () =>
      import(
        /* webpackChunkName: "emerald" */ './EmeraldBulkDisburseManage.vue'
      )
  },
  {
    name: 'EmeraldBulkDisburseManageDetail',
    path: '/emerlad_bulk/detail',
    component: () =>
      import(
        /* webpackChunkName: "emerald" */ './EmeraldBulkDisburseManageDetail.vue'
      )
  },
  {
    name: 'EmeraldDisburseManage',
    path: '/emerlad_disburse',
    component: () =>
      import(/* webpackChunkName: "emerald" */ './EmeraldDisburseManage.vue')
  },
  {
    name: 'EmeraldFastDisburse',
    path: '/emerlad_disburse/fast_disburse',
    component: () =>
      import(/* webpackChunkName: "emerald" */ './EmeraldFastDisburse.vue')
  },
  {
    name: 'EmeraldDisburse',
    path: '/emerlad_disburse/disburse',
    component: () =>
      import(/* webpackChunkName: "emerald" */ './EmeraldDisburse.vue')
  },
  {
    name: 'EmeraldPaymentManagement',
    path: '/emerlad_payment',
    component: () =>
      import(/* webpackChunkName: "emerald" */ './EmeraldPaymentManagement.vue')
  },
  {
    name: 'EmeraldRepayment',
    path: '/emerlad_payment/repayment',
    component: () =>
      import(/* webpackChunkName: "emerald" */ './EmeraldRepayment.vue')
  }
]
