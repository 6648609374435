<template>
  <div class="el-pagination is-background">
    <div class="el-pagination__rightwrapper">
      <button
        type="button"
        :disabled="prevDisabled"
        class="btn-prev"
        @click="preClick"
      >
        <i class="el-icon el-icon-arrow-left"></i>
      </button>
      <ul class="el-pager">
        <li class="number">{{ currentPage }}</li>
      </ul>
      <button
        type="button"
        class="btn-next"
        :disabled="nextDisabled"
        @click="nextClick"
      >
        <i class="el-icon el-icon-arrow-right"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'no-total-pagination',
  props: {
    currentPage: {
      type: Number,
      default: () => 1
    },
    hasMore: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    prevDisabled() {
      return !this.currentPage || this.currentPage <= 1
    },
    nextDisabled() {
      return this.hasMore !== true
    }
  },
  methods: {
    preClick() {
      let page = this.currentPage - 1
      page = page < 1 ? 1 : page
      this.$emit('current-change', page)
      this.$emit('update:current-page', page)
    },
    nextClick() {
      this.$emit('current-change', this.currentPage + 1)
      this.$emit('update:current-page', this.currentPage + 1)
    }
  }
}
</script>

<style></style>
