<template>
  <div class="chat-window">
    <div
      class="chat-call"
      :class="isFullScreen && 'chat-call-full'"
      v-if="show"
      id="playerContainer"
      @mousedown="onMousedown"
      @mouseup="onMouseup"
      @mousemove="onMousemove"
    >
      <div class="content">
        <div class="zoomout" @click="zoomoutClick">
          <img src="../../../../assets/images/chat/zoomout.png" />
        </div>
        <div class="user-info" v-if="isCalling || calltype === 'voice'">
          <chat-avatar
            class="user-info-avatar"
            :size="100"
            :url="getUserInfo(callConversation.ownerId).avatarurl"
          />
          <span class="user-info-name">
            {{
              getUserInfo(callConversation.ownerId).nickname ||
              callConversation.ownerId
            }}
          </span>
          <span class="load-text" v-if="isCalling">{{ desc }}</span>
        </div>
        <div
          class="main-window"
          id="large"
          v-if="!isCalling && calltype === 'video'"
        />
        <div
          class="sub-window"
          id="small"
          v-if="!isCalling && calltype === 'video'"
        />
      </div>
      <span class="chat-time" v-if="!isCalling">{{
        chatTime | formatDuration
      }}</span>
      <ul class="tab-bar">
        <li :class="{ stop: true, isStop }" @click="setOperationVoice"></li>
        <li class="over" @click="leaveClick"></li>
        <li
          :class="{ silence: true, isSilence }"
          @click="setCustomerVoice"
        ></li>
      </ul>
    </div>
  </div>
</template>

<script>
import store from '@/projects/operation/store'
import {
  loadLiveSDK,
  getRtcToken,
  appId
} from '@/projects/operation/views/Live/agora'
import { Message } from 'element-ui'

import ChatCall from './index'
import { addEventListener, removeEventListener } from '@/util/event'
let AgoraRTC = null

export default {
  props: {
    type: {
      type: String,
      default: 'voice'
    }
  },
  data() {
    return {
      callConversation: store.state.conversation.callConversation,
      show: false,
      calltype: this.type, // voice
      isCalling: true,
      time: 0,
      isSilence: false,
      desc: 'waiting...',
      isStop: false,
      currentClient: null,
      isFullScreen: false,
      localVideoTrack: null,
      localAudioTrack: null,
      remoteAudioTrack: null,
      chatTime: 0
    }
  },
  async created() {
    if (!this.callConversation) return
    AgoraRTC = await loadLiveSDK()
    if (AgoraRTC) {
    }
    addEventListener('RTCInvitationStatusChange', this.onInvitationStatusChange)
    if (!this.timer) {
      this.timer = setTimeout(() => {
        Message.error('No Answered')
        this.leaveClick()
      }, 120000)
    }
  },
  // beforeDestroy() {
  //   removeEventListener('RTCInvitationStatusChange')
  // },
  methods: {
    getUserInfo(username) {
      return store.state.chat.usersInfo[username] || { username }
    },
    async removeTrack() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      if (this.chatTimer) {
        clearTimeout(this.chatTimer)
        this.chatTimer = null
      }
      if (this.currentClient) {
        await this.currentClient.leave()
        this.currentClient = null
      }
      if (this.localAudioTrack) {
        this.localAudioTrack.close()
        this.localAudioTrack = null
      }
      if (this.localVideoTrack) {
        this.localVideoTrack.close()
        this.localAudioTrack = null
      }
      await store.dispatch('conversation/updateCallConversation', null)
      removeEventListener('RTCInvitationStatusChange')
      ChatCall.hide()
    },
    startChatTimer() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      if (!this.chatTimer) {
        this.chatTimer = setInterval(() => {
          this.chatTime = this.chatTime + 1
        }, 1000)
      }
    },
    onInvitationStatusChange(data) {
      if (this.callConversation.reqChatId !== data.conversation.id) {
        return
      }
      // 当有人接受邀请后
      if (data.status === 'accept') {
        this.startChatTimer()
        //  呼叫方 开始进入频道
        if (this.isCalling) {
          let realCalltype = data.calltype
          this.joinChannel({
            conversation: this.callConversation,
            calltype: realCalltype
          })
          if (this.calltype !== realCalltype) {
            this.calltype = realCalltype
          }
          this.isCalling = false
        }
        return
      }
      switch (data.status) {
        case 'refuse':
        case 'busy':
        case 'hangup':
        case 'cancel': // 主动方取消呼叫（未接通）
          this.status = data.status
          Message.warning(data.status)
          this.removeTrack()
          let operateTp
          if (data.status === 'hangup') {
            operateTp = '6'
          } else {
            operateTp = '5'
          }
          this.$api.post({
            url: '/custsrvc/adoVdo/operateTrnsfrAdoVdo',
            data: {
              reqId: this.callConversation.reqChatId,
              operateTp: operateTp,
              streamId: this.callConversation.reqChatId
            }
          })
          break
      }
    },
    async joinChannel({ conversation, calltype }) {
      this.currentClient = null
      let token = await getRtcToken(conversation.reqChatId, true)
      if (!token) return
      const client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' })
      client.on('user-published', async (user, mediaType) => {
        this.remoteVideoTrack = null
        this.remoteAudioTrack = null
        await client.subscribe(user, mediaType)
        if (mediaType === 'audio') {
          const remoteAudioTrack = user.audioTrack
          remoteAudioTrack.play()
          this.remoteAudioTrack = remoteAudioTrack
        } else {
          const remoteVideoTrack = user.videoTrack
          remoteVideoTrack.play(document.getElementById('large'))
          this.remoteVideoTrack = remoteVideoTrack
        }
      })
      client.on('user-unpublished', user => {
        const playerContainer = document.getElementById(conversation.reqChatId)
        if (playerContainer) {
          playerContainer.remove()
        }
      })
      await client.join(appId, conversation.reqChatId, token, 11)
      const localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack()
      localAudioTrack.play()
      if (calltype === 'video') {
        const localVideoTrack = await AgoraRTC.createCameraVideoTrack()
        localVideoTrack.play(document.getElementById('small'))
        await client.publish([localVideoTrack, localAudioTrack])
        this.localVideoTrack = localVideoTrack
      } else {
        await client.publish(localAudioTrack)
      }
      this.localAudioTrack = localAudioTrack
      this.currentClient = client
    },

    leaveClick() {
      this.removeTrack()
      store.dispatch('message/sendMessage', {
        msg: {
          messageType: 'rtc',
          chatType: 'chatRoom',
          to: this.callConversation.reqChatId,
          ext: {
            action: 'cancel',
            conversation: {
              id: this.callConversation.reqChatId,
              type: 'chatRoom'
            },
            calltype: this.calltype,
            from: store.state.user.imId
          }
        }
      })
      this.$api.post({
        url: '/custsrvc/adoVdo/operateTrnsfrAdoVdo',
        data: {
          reqId: this.callConversation.reqChatId,
          operateTp: '7',
          streamId: this.callConversation.reqChatId
        }
      })
    },
    setCustomerVoice() {
      if (this.isCalling) return
      const { isSilence } = this
      this.isSilence = !isSilence
      if (this.isSilence) {
        if (this.remoteAudioTrack) {
          this.remoteAudioTrack.setVolume(0)
        }
      } else {
        if (this.remoteAudioTrack) {
          this.remoteAudioTrack.setVolume(100)
        }
      }
    },
    setOperationVoice() {
      if (this.isCalling) return
      const { isStop } = this
      this.isStop = !isStop
      if (this.isStop) {
        console.log('关闭语音', this.localAudioTrack)
        this.localAudioTrack.setMuted(true)
      } else {
        console.log('打开语音')
        this.localAudioTrack.setMuted(false)
      }
    },
    zoomoutClick() {
      this.isFullScreen = !this.isFullScreen
    },
    onMousedown(e) {
      if (this.isFullScreen) return
      this.flag = true
      let div = document.getElementById('playerContainer')
      this.x = e.clientX - div.offsetLeft
      this.y = e.clientY - div.offsetTop
    },
    onMouseup() {
      if (this.isFullScreen) return
      this.flag = false
    },
    onMousemove(event) {
      if (!this.flag || this.isFullScreen) return
      let div = document.getElementById('playerContainer')
      div.style.left = event.clientX - (this.x || 0) + 'px'
      div.style.top = event.clientY - (this.y || 0) + 'px'
    }
  }
}
</script>

<style lang="scss">
.chat-window {
  .chat-call {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 20px;
    top: 10px;
    width: 400px;
    height: 500px;
    background: #25252d;
    cursor: pointer;
    .zoomout {
      display: flex;
      position: absolute;
      right: 10px;
      top: 5px;
      width: 30px;
      height: 30px;
    }
    .content {
      flex: 1;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .user-info {
        display: flex;
        flex-direction: column;
        height: 100%;
        text-align: center;
        margin-top: 20px;
        align-self: flex-start;
        .user-info-name {
          margin-top: 10px;
          color: #fff;
        }
        .load-text {
          margin-top: 50px;
          color: #fff;
        }
      }
      .main-window {
        height: 100%;
        width: 100%;
        background: #25252d;
      }
      .sub-window {
        width: 25%;
        height: 30%;
        background: #25252d;
        position: absolute;
        z-index: 9;
        right: 10px;
        top: 60px;
        border: 1px solid #ffffff;
      }
    }
    .chat-time {
      margin-bottom: 30px;
      text-align: center;
      color: #fff;
    }
    .tab-bar {
      height: 54px;
      background-image: linear-gradient(180deg, #292933 7%, #212129 100%);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      li {
        height: 54px;
        width: 125px;
        cursor: pointer;
        &.silence {
          background: url('../../../../assets/images/chat/loudspeaker.png')
            no-repeat center;
          background-size: 30px 30px;
          &.isSilence {
            background: url('../../../../assets/images/chat/loudspeaker_disabled.png')
              no-repeat center;
            background-size: 30px 30px;
          }
        }
        &.over {
          background: url('../../../../assets/images/chat/phone-off.png')
            no-repeat center;
          background-size: 40px 40px;
        }
        &.stop {
          background: url('../../../../assets/images/chat/voice.png') no-repeat
            center;
          background-size: 30px 30px;
          &.isStop {
            background: url('../../../../assets/images/chat/voice_muted.png')
              no-repeat center;
            background-size: 30px 30px;
          }
        }
      }
    }
  }
  .chat-call-full {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    position: absolute;
    right: 0px !important;
    top: 0px !important;
    left: 0px !important;
    bottom: 0px !important;
    background: #25252d;
    cursor: pointer;
  }
}
</style>
