import '@/i18n/template/projects/operation/store/modules/user.js'
import { setAuthData, clearAuthData, onAuthDateRefresh } from '@/api/tokenApi'
import { setCbbHeader } from '@/api/index'
import sockjs from '@/lib/sockjs/index'
import { Message } from 'element-ui'
import i18n from '@/i18n'

let initalAuthData = localStorage.authData || ''
let userInfo = {}
if (initalAuthData) {
  try {
    initalAuthData = JSON.parse(initalAuthData)
    // token是否过期
    if (initalAuthData.expires_time > Date.now()) {
      setAuthData(initalAuthData)
      userInfo = initalAuthData.userInfo || {}
      // 兼容缓存的旧数据
      if (userInfo.roleId && !userInfo.roleList) {
        userInfo.roleList = [userInfo.roleId]
      }
      if (userInfo.loginType === 'teller') {
        setCbbHeader({
          custId: '',
          staffId: userInfo.custId,
          deptId: userInfo.deptId,
          userGrpCd: userInfo.userGrpCd,
          chnlDscd: userInfo.chnlDscd
        })
      } else {
        setCbbHeader({
          staffId: userInfo.loginId
        })
      }
    }
  } catch (e) {
    userInfo = {}
  }
}

onAuthDateRefresh(newAuthData => {
  try {
    let oldAuth = JSON.parse(localStorage.authData)
    localStorage.authData = JSON.stringify({
      ...oldAuth,
      access_token: newAuthData.access_token,
      refresh_token: newAuthData.refresh_token,
      user_secret: newAuthData.user_secret,
      expires_in: newAuthData.expires_in,
      expires_time: newAuthData.expires_time
    })
  } catch (e) {}
})

export default {
  namespaced: true,
  state: {
    login: !!userInfo.userId,
    roleList: userInfo.roleList || [],
    nickname: userInfo.nickname || '',
    userNm: userInfo.userNm || '',
    userId: userInfo.userId || '',
    staffId: userInfo.loginType === 'teller' ? userInfo.custId : '',
    imId: userInfo.imId || '',
    imPwd: userInfo.imId || '',
    status: 'Online',
    statusIndex: 0
  },
  getters: {},
  actions: {
    login(context, authData) {
      let data = authData.userInfo
      let imId = data.loginId.toLowerCase()
      if (data.ext) {
        try {
          data.nickname = JSON.parse(data.ext).nickNm
        } catch (e) {}
      }
      data.userId = data.loginId
      data.nickname = data.nickname
      data.imId = imId
      data.imPwd = imId
      if (userInfo.loginType === 'teller') {
        setCbbHeader({
          custId: '',
          staffId: userInfo.custId,
          deptId: userInfo.deptId,
          userGrpCd: userInfo.userGrpCd,
          chnlDscd: userInfo.chnlDscd
        })
        data.staffId = userInfo.custId
      } else {
        setCbbHeader({
          staffId: data.loginId
        })
      }
      context.commit('login', data)
      localStorage.authData = JSON.stringify(authData)
      setAuthData(authData)
      return Promise.resolve(userInfo)
    },
    logout(context, logoutType) {
      if (logoutType === 'expired' && context.state.login) {
        // 防重复提示
        Message.error(i18n.t('loginExpired'))
      }
      localStorage.removeItem('authData')
      clearAuthData()
      setCbbHeader()
      context.dispatch('chat/logout', null, { root: true })
      context.commit('logout')
    },
    statusChange(context, data) {
      context.commit('statusChange', data)
    }
  },
  mutations: {
    login(state, data) {
      state.login = true
      state.nickname = data.nickname
      state.userNm = data.userNm
      state.roleList = data.roleList
      if (data.roleId && !data.roleList) {
        state.roleList = [data.roleId]
      }
      state.userId = data.userId
      state.staffId = data.staffId
      state.imId = data.imId
      state.imPwd = data.imPwd
    },
    logout(state) {
      state.login = false
      state.nickname = ''
      state.roleList = []
      state.userId = null
      state.staffId = ''
      state.userNm = ''
      state.imId = null
      state.imPwd = null
    },
    statusChange(state, data) {
      state.status = data
    }
  }
}
