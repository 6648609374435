import Vue from 'vue'
import { ButtonPermissionMap } from '../../constant/ButtonPermission'
export default {
  namespaced: true,
  state: {
    permissions: {}
  },
  getters: {},
  actions: {
    initPermissionList(context, data) {
      context.commit('updatePermissionList', data)
    }
  },
  mutations: {
    updatePermissionList(state, data = []) {
      state.permissions = data.reduce((a, b) => {
        a[b] = true
        return a
      }, {})
    }
  }
}
Vue.mixin({
  methods: {
    globalButtonPermission(value) {
      value = ButtonPermissionMap[value]
      if (Array.isArray(value)) {
        for (let code of value) {
          if (this.$store.state.permission.permissions[code]) {
            return true
          }
        }
      } else {
        if (this.$store.state.permission.permissions[value]) {
          return true
        }
      }
      return false
    }
  }
})
Vue.directive('permission', {
  inserted(el, binding, vnode) {
    let { value, arg } = binding
    if (arg && !value) {
      value = ButtonPermissionMap[arg]
    }

    let disabled = true
    if (Array.isArray(value)) {
      for (let code of value) {
        if (vnode.context.$store.state.permission.permissions[code]) {
          disabled = false
          break
        }
      }
    } else if (value) {
      disabled = !vnode.context.$store.state.permission.permissions[value]
    }
    if (disabled) {
      el.remove()
    }
  }
})
