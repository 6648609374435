export default [
  {
    name: 'basicstatistics',
    path: 'basicstatistics',
    component: () =>
      import(
        /* webpackChunkName: "basicstatistics" */ './NewCustServiceAnalysis.vue'
      )
  },
  {
    name: 'report_income',
    path: '/report/income',
    component: () =>
      import(/* webpackChunkName: "report_income" */ './IncomeExpenditrue.vue')
  },
  {
    name: 'report_user',
    path: '/report/user',
    component: () => import(/* webpackChunkName: "report_user" */ './User.vue')
  },
  {
    name: 'report_live',
    path: '/report/live',
    component: () => import(/* webpackChunkName: "report_live" */ './Live.vue')
  },
  {
    name: 'points_analysis',
    path: '/points_analysis',
    component: () =>
      import(
        /* webpackChunkName: "points_analysis" */ './PointsAnalysis/PointsAnalysis.vue'
      )
  },
  {
    name: 'report_offline_promotion',
    path: '/report/offline_promotion',
    component: () =>
      import(
        /* webpackChunkName: "report_offline_promotion" */ './OfflinePromotion.vue'
      )
  },
  {
    name: 'report_merchants_analyzing',
    path: '/report/merchants_analyzing',
    component: () =>
      import(
        /* webpackChunkName: "report_merchants_analyzing" */ './MerchantsAnalyzing.vue'
      )
  },
  {
    name: 'report_transaction_analyzing',
    path: '/report/transaction_analyzing',
    component: () =>
      import(
        /* webpackChunkName: "report_transaction_analyzing" */ './TransactionAnalyzing.vue'
      )
  },
  {
    name: 'report_cash_counter_analyzing',
    path: '/report/cash_counter_analyzing',
    component: () =>
      import(
        /* webpackChunkName: "report_cash_counter_analyzing" */ './CashCounterAnalyzing.vue'
      )
  }
]
