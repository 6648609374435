<i18n src="@/i18n/template/components/ExportTaskDialog.json"></i18n>
<template>
  <el-dialog
    :title="$t('ExportTask')"
    append-to-body
    width="900px"
    :visible="visible"
    @update:visible="$emit('update:visible', $event)"
  >
    <div class="task-list">
      <search-box
        v-model="searchForm"
        :config="searchConfig"
        @search="onSearch"
      />
      <el-table v-loading="loading" :data="data" border stripe>
        <el-table-column
          prop="creTmstmp"
          :label="$t('CreateTime')"
          min-width="200px"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.creTmstmp | time }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="taskSts" :label="$t('Status')" min-width="150px">
          <template slot-scope="scope">
            <p>
              <i v-if="scope.row.taskSts === '1'" class="el-icon-loading"></i>
              {{ $util.getLabel(scope.row.taskSts, searchConfig[1].options) }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="errMsg"
          :label="$t('Remark')"
          min-width="200px"
        ></el-table-column>
        <el-table-column fixed="right" :label="$t('operate')" width="120px">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.taskSts === '3'"
              type="text"
              icon="el-icon-refresh-right"
              @click="retryClick(scope.row)"
              >{{ $t('Retry') }}
            </el-button>
            <el-button
              v-if="scope.row.taskSts === '2'"
              type="text"
              icon="el-icon-download"
              @click="downloadClick(scope.row)"
              >{{ $t('Download') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <my-pagination
        style="margin-top: 20px"
        @current-change="handleCurrentChange"
        :pgNum.sync="pgNum"
        :page-size.sync="pgCnt"
        :total="total"
        :view-key="$options.name"
        @size-change="fetchData(pgNum)"
      />
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'task',
  props: {
    visible: Boolean,
    busTp: String
  },
  watch: {
    busTp(val) {
      this.fetchData()
    },
    visible(val) {
      if (val) {
        this.fetchData(1)
      }
    }
  },
  created() {
    if (this.busTp && this.visible) {
      this.fetchData()
    }
  },
  data() {
    return {
      data: [],
      processingList: [],
      total: 0,
      pgNum: 1,
      pgCnt: this.$getPgCnt(),
      loading: false,
      searchForm: {
        taskSts: '',
        startDt: this.dayjs().subtract(1, 'month').format('YYYY/MM/DD'),
        endDt: this.dayjs().format('YYYY/MM/DD')
      },
      searchConfig: [
        {
          type: 'date-range',
          startDateKey: 'startDt',
          endDateKey: 'endDt',
          getLabel: () => this.$t('createTime')
        },
        {
          key: 'taskSts',
          getLabel: () => this.$t('Status'),
          type: 'select',
          options: [
            // 任务状态，1-执行中、2-执行成功、3-执行失败、4-取消
            { value: '1', getLabel: () => this.$t('Processing') },
            { value: '2', getLabel: () => this.$t('success') },
            { value: '3', getLabel: () => this.$t('Failed') }
            // { value: '4', getLabel: () => this.$t('cancel') }
          ]
        }
      ]
    }
  },
  methods: {
    startChekProcessingList() {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.timer = setInterval(async () => {
        // 有处理中的才检查状态更新
        if (this.processingList.length === 0) {
          return
        }
        let processingList = this.processingList || []
        let res = await this.$api.post({
          url: 'manager/export/queryExportTask',
          data: {
            pgNum: this.pgNum,
            pgCnt: this.$getPgCnt()
          }
        })
        let newStatusMap = {}
        for (let item of res.taskList) {
          newStatusMap[item.id] = item
          let index = processingList.indexOf(item.id)
          if (item.taskSts === '1') {
            if (index === -1) {
              processingList.push(item.id)
            }
            continue
          }
          if (index !== -1) {
            processingList.splice(index, 1)
          }
        }
        let newList = []
        let statusChanged = false
        for (let item of this.data) {
          // 是否有状态更新
          if (
            item.id in newStatusMap &&
            item.taskSts !== newStatusMap[item.id].taskSts
          ) {
            statusChanged = true
            newList.push(newStatusMap[item.id])
          } else {
            newList.push(item)
          }
        }
        if (statusChanged) {
          this.data = newList
        }
      }, 5000)
    },
    onSearch() {
      this.fetchData()
    },
    async fetchData(page = 1) {
      this.loading = true
      let res = await this.$api.post({
        url: 'manager/export/queryExportTask',
        data: {
          busTp: this.busTp,
          taskSts: this.searchForm.taskSts,
          startDt: this.$util.toCBPDate(this.searchForm.startDt),
          endDt: this.$util.toCBPDate(this.searchForm.endDt),
          pgNum: page,
          pgCnt: this.pgCnt
        }
      })
      this.loading = false
      if (res) {
        this.data = res.taskList
        this.total = res.totalCount
        this.pgNum = page
        let processingList = []
        for (let item of res.taskList) {
          if (item.taskSts === '1' && processingList.indexOf(item.id) === -1) {
            processingList.push(item.id)
          }
        }
        this.processingList = processingList
        if (processingList.length > 0) {
          this.startChekProcessingList()
        }
      }
    },
    async retryClick(item) {
      let loading = this.$loading()
      let res = await this.$api.post({
        url: 'manager/export/retryExportTask',
        data: {
          id: item.id
        }
      })
      loading.close()
      if (res) {
        this.data = this.data.map(it => {
          if (item.id === it.id) {
            it.taskSts = '1'
          }
          return it
        })
      }
    },
    async cancelClick(item) {
      let loading = this.$loading()
      let res = await this.$api.post({
        url: 'manager/export/cancelExportTask',
        data: {
          id: item.id
        }
      })
      loading.close()
      if (res) {
        this.data = this.data.map(it => {
          if (item.id === it.id) {
            it.taskSts = '4'
          }
          return it
        })
      }
    },
    async downloadClick(item) {
      let loading = this.$loading()
      let res = await this.$api.post({
        url: 'manager/export/loadUrl',
        data: {
          id: item.id
        }
      })
      loading.close()
      if (res.signUrl) {
        this.$util.downloadFile(res.signUrl)
      }
    },
    handleCurrentChange(page) {
      this.fetchData(page)
    }
  }
}
</script>

<style lang="scss"></style>
