import { ButtonPermissionMap } from '../../constant/ButtonPermission'
export default [
  {
    path: '/monitoring_list',
    name: 'monitoring_list',
    component: () => import(/* webpackChunkName: "monitoring" */ './List.vue')
  },
  {
    path: '/monitoring_detail',
    name: 'monitoring_detail',
    meta: {
      permission: ButtonPermissionMap.MonitoringDetail
    },
    component: () => import(/* webpackChunkName: "monitoring" */ './detail.vue')
  }
]
