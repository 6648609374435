import i18n from '@/i18n'
i18n.mergeLocaleMessage('en', {
  "NeverExpire": "Never expire",
  "Quarter1": "Quarter 1",
  "Quarter2": "Quarter 2",
  "Quarter3": "Quarter 3",
  "Quarter4": "Quarter 4",
  "SCRNITM#yr": "Year"
})
i18n.mergeLocaleMessage('zh', {
  "NeverExpire": "永不过期",
  "Quarter1": "一季度",
  "Quarter2": "二季度",
  "Quarter3": "三季度",
  "Quarter4": "四季度",
  "SCRNITM#yr": "年"
})
i18n.mergeLocaleMessage('mm', {
  "NeverExpire": "Never expire",
  "Quarter1": "Quarter 1",
  "Quarter2": "Quarter 2",
  "Quarter3": "Quarter 3",
  "Quarter4": "Quarter 4",
  "SCRNITM#yr": "နှစ်"
})
  