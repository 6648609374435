import Vue from 'vue'

let bus = null

function getVueBus() {
  if (!bus) {
    bus = new Vue()
  }
  return bus
}

export const EventTypes = {
  NewNotification: 'NewNotification',
  NotificationClick: 'NotificationClick',
  NewLive: 'NewLive'
}

export function addEventListener(event, fn) {
  getVueBus().$on(event, fn)
}
export function removeEventListener(event, fn) {
  getVueBus().$off(event, fn)
}
export function emitEvent(event, data) {
  getVueBus().$emit(event, data)
}
