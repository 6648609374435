import WebIM from '../../webim/WebIM'
import { Message } from 'element-ui'
export default {
  namespaced: true,
  state: {
    loginStatus: 'pending',
    username: '',
    usersInfo: {}
  },
  getters: {},
  actions: {
    login(context, { username, pwd }) {
      if (!username) {
        Message.error('IM Username is null')
        context.commit('changeLoginStatus', 'error')
        return
      }
      username = username.toLowerCase()
      context.commit('changeLoginStatus', 'pending')
      console.log(' ==IM登录 ==', username)

      if (WebIM.conn.isOpened()) {
        console.log('--IMSocket已连接--')
        context.commit('login', username)
        context.dispatch('getUsersInfo', { usernames: username })
        return
      }
      console.log('--IMSocket还未连接--')
      return new Promise((resolve, reject) => {
        let options = {
          user: username,
          pwd: pwd,
          appKey: WebIM.config.appkey,
          success: res => {
            console.log('IM登录 success ==============', res)
            context.commit('login', username)
            context.dispatch('getUsersInfo', { usernames: username })
            context.dispatch('conversation/getConversationList', null, {
              root: true
            })
            resolve()
          },
          error: () => {
            context.commit('changeLoginStatus', 'error')
            console.log('IM登录 error ==============')
            reject()
          }
        }
        WebIM.conn.open(options)
      })
    },
    async logout(context) {
      context.commit('logout')
      context.commit('message/clear', [], { root: true })
      context.dispatch('conversation/onExit', [], { root: true })
      setTimeout(() => {
        // 发送结束会话 消息后 再退出
        WebIM.conn.close()
      }, 2000)
    },
    getUsersInfo({ state, commit }, { usernames, cache = true }) {
      console.log(usernames, '---')
      usernames = usernames ? [].concat(usernames) : []
      usernames = usernames.filter(username => {
        if (!username) return false
        if (cache) {
          let item = state.usersInfo[username]
          if (!item || !item.utime) return true
          // 缓存一个小时
          return item.utime < Date.now()
        }
        return true
      })
      if (usernames.length === 0) return Promise.resolve(state.usersInfo)
      return WebIM.conn
        .fetchUserInfoById(usernames)
        .then(res => {
          let data = res.data || {}
          commit('updateUserInfo', { usernames, data })
          return state.usersInfo
        })
        .catch(e => {
          console.log(e, 'getUsersInfo')
          return state.usersInfo
        })
    }
  },
  mutations: {
    changeLoginStatus(state, status) {
      state.loginStatus = status
    },
    login(state, username) {
      state.loginStatus = 'success'
      state.username = username
    },
    logout(state) {
      state.loginStatus = 'pending'
      state.username = ''
    },
    updateUserInfo(state, { usernames, data }) {
      let usersInfo = { ...state.usersInfo }
      for (let username of usernames) {
        let user = Object.assign(
          { username },
          usersInfo[username],
          data[username]
        )
        let gender = user.gender ? user.gender + '' : ''
        if (gender === '0') {
          gender = 'unknow'
        } else if (user.gender === '1') {
          gender = 'male'
        } else if (user.gender === '2') {
          gender = 'female'
        }
        user.gender = gender
        if (typeof user.ext === 'string') {
          try {
            user.ext = JSON.parse(user.ext)
          } catch (e) {
            user.ext = {}
          }
        }
        user.utime = Date.now() + 300000
        usersInfo[username] = user
      }
      state.usersInfo = usersInfo
    }
  }
}
