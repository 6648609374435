export default [
  {
    name: 'merchant_manage',
    path: '/merchant_manage',
    component: () =>
      import(/* webpackChunkName: "merchant_manage" */ './index.vue')
  },
  {
    name: 'merchant_detail',
    path: '/merchant_manage/detail',
    component: () =>
      import(/* webpackChunkName: "merchant_manage" */ './detail.vue')
  },
  {
    name: 'merchant_exception',
    path: '/merchant_exception',
    component: () =>
      import(
        /* webpackChunkName: "merchant_exception" */ './Exception/MerchantException.vue'
      )
  },
  {
    name: 'industry_category',
    path: '/industry_category',
    component: () =>
      import(
        /* webpackChunkName: "industry_category" */ './IndustryCategory.vue'
      )
  }
]
