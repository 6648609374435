<template>
  <el-cascader
    :props="addressPickerProps"
    :value="value"
    :disabled="disabled"
    @input="onInput"
    filterable
    collapse-tags
  ></el-cascader>
</template>

<script>
import { Cascader } from 'element-ui'
const cacheMap = {}
export default {
  name: 'CityPicker',
  props: ['value', 'disabled', 'multiple'],
  components: {
    'el-cascader': Cascader
  },
  data() {
    const regionLoader = async (node, resolve) => {
      const { level, value } = node
      if (level > 0 && cacheMap[value]) return resolve(cacheMap[value])
      if (level === 0) {
        if (cacheMap.level1) {
          setTimeout(() => {
            resolve(cacheMap.level1)
          }, 10)
          return
        }
        const res = await this.$api.post({
          url: '/mbrshpLvl/addressArea/listProvince',
          data: {
            countryCode: 'MM'
          }
        })
        console.log('listProvince', res)
        const nodes = res.map(item => {
          return {
            value: item.code,
            label: item.name,
            leaf: false
          }
        })
        // cacheMap[value] = nodes
        cacheMap.level1 = nodes
        resolve(nodes)
      } else if (level === 1) {
        const res = await this.$api.post({
          url: '/mbrshpLvl/addressArea/listCity',
          data: {
            provinceCode: value
          }
        })
        console.log('listCity', res)
        const nodes = res.map(item => {
          return {
            value: item.code,
            label: item.name,
            leaf: true
          }
        })
        cacheMap[value] = nodes
        resolve(nodes)
      } else {
        resolve()
      }
    }
    return {
      addressPickerProps: {
        lazy: true,
        lazyLoad: regionLoader,
        multiple: this.multiple
      }
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style></style>
