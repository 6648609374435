import '@/i18n/template/projects/operation/store/modules/notification.js'
import Vue from 'vue'
import { Notification, MessageBox, Message } from 'element-ui'
import router from '../../router'
import api from '@/api'
import i18n from '@/i18n'
import { WebSocket_URL } from '@/config'
import { emitEvent, EventTypes } from '@/util/event'
import sockjs from '@/lib/sockjs/index'
import ChatCall from '../../components/ChatVideoVoiceCall'
import store from '..'
const PageURL = {
  ClearDevice: '/approval/device_clear'
}

export default {
  namespaced: true,
  state: {
    /*
     * 菜单页面消息提醒数量 key: 菜单URL value： badge数量
     **/
    msgCount: {
      [PageURL.ClearDevice]: 0
    }
  },
  actions: {
    async startSockJs(context) {
      // window.testNofity = msg => {
      //   context.dispatch('addNotfication', msg)
      // }
      await sockjs.init()
      sockjs.startConnet(WebSocket_URL, context.rootState.user.userId, msg => {
        context.dispatch('addNotfication', msg)
      })
      context.dispatch('initClearDeviceList')
    },
    stopSockJs(context) {
      sockjs.disconnect()
    },
    async initClearDeviceList(context) {
      let res = await api.post({
        url: 'operation/approval/queryUnbindDeviceAplyList',
        data: {
          aprvlStsCd: '00',
          pageNum: 1,
          pageSize: 1
        }
      })
      if (res) {
        context.commit('updateClearDeviceCount', res.total)
      }
    },
    addNotfication(context, msg) {
      console.log('onNotification', msg)
      let typeText = ''
      let contentText = ''
      let notifyType = 'info'
      emitEvent(EventTypes.NewNotification, msg)
      switch (msg.type) {
        case 'unbindDevice':
          typeText = 'Clear Customer Device'
          contentText = i18n.t('HaveApprovalAlert', { type: typeText })
          context.commit('updateClearDeviceCount', msg.aplyCnt)
          break
        case 'lvMontior': // 新开播
          emitEvent(EventTypes.NewLive)
          return
        case 'monitorEvent': // 可疑监控
          // monitorEventCnt 数量
          contentText = 'New suspicious surveillance'
          break
        case 'vlageDsbrse': // village disburse
          notifyType = 'success'
          contentText = i18n.t('VillageDisburseSuccess')
          if (msg.totCnt !== msg.successCnt) {
            notifyType = 'error'
            contentText =
              i18n.t('VillageDisburseSuccess') +
              `\n(${i18n.t('Failed')}: ${msg.failCnt})`
          }
          break
        case 'offline': // 被踢下线
          context.dispatch('user/logout')
          MessageBox.confirm(
            'Your Permission Has been change, please login again',
            {
              closeOnClickModal: false,
              showCancelButton: false,
              showClose: false
            }
          ).finally(() => {
            router.replace({ name: 'login' })
          })
          return
        case 'trnsfrAdoVdo': //转音视频服务
          break
        default:
          return
      }
      const vcomp = new Vue({})
      const h = vcomp.$createElement
      let notify = null
      if (msg.type === 'trnsfrAdoVdo') {
        if (store.state.conversation.callConversation) {
          // 有正在通话的
          return
        }
        notify = Notification({
          title: i18n.t('SystemMessage'),
          type: notifyType,
          duration: 0,
          showClose: false,
          message: h(
            'div',
            {
              style: {
                cursor: 'pointer',
                textAlign: 'left'
              }
            },
            [
              `${
                store.state.chat.usersInfo[msg.custId].nickname
              } want to apply for ${
                msg.mediaTp === '1' ? 'voice' : 'video'
              } service, Number of queues ${msg.queueNum}`,
              h('div', {
                style: {
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingLeft: '10px'
                }
              }),
              h(
                'span',
                {
                  style: {
                    color: '#4D03D4',
                    fontSize: '14px'
                  },
                  on: {
                    click: async () => {
                      let res = await api.post({
                        url: 'custsrvc/adoVdo/operateTrnsfrAdoVdo',
                        data: {
                          reqId: msg.reqId,
                          operateTp: '4',
                          streamId: msg.reqId
                        }
                      })
                      if (res) {
                        notify.close()
                        // 回拨
                        await store.dispatch('conversation/enterConversation', {
                          reqChatId: msg.reqId,
                          ownerId: msg.custId
                        })
                        await store.dispatch(
                          'conversation/updateCallConversation',
                          {
                            reqChatId: msg.reqId,
                            ownerId: msg.custId
                          }
                        )
                        ChatCall.show({
                          type: msg.mediaTp === '1' ? 'voice' : 'video'
                        })
                        store.dispatch('message/sendMessage', {
                          msg: {
                            channel: msg.reqId,
                            to: msg.reqId,
                            chatType: 'chatroom',
                            messageType: 'rtc',
                            ext: {
                              action: 'invite',
                              calltype: msg.mediaTp === '1' ? 'voice' : 'video',
                              conversation: {
                                id: msg.reqId,
                                type: 'chatroom'
                              },
                              users: [
                                {
                                  username: msg.custId,
                                  uid: 10
                                },
                                {
                                  username: store.state.user.imId,
                                  uid: 11
                                }
                              ]
                            }
                          },
                          onSendSuccess: () => {}
                        })
                      }
                    }
                  }
                },
                i18n.t('Approval')
              ),
              h(
                'span',
                {
                  style: {
                    color: '#4D03D4',
                    fontSize: '14px',
                    paddingLeft: '40px'
                  },
                  on: {
                    click: async () => {
                      notify.close()
                      let res = await api.post({
                        url: 'custsrvc/adoVdo/operateTrnsfrAdoVdo',
                        data: {
                          reqId: msg.reqId,
                          operateTp: '3',
                          streamId: msg.reqId
                        }
                      })
                      if (res) {
                        Message.success(i18n.t('success'))
                      }
                    }
                  }
                },
                i18n.t('Reject')
              )
            ]
          ),
          onClick: () => {}
        })
        return
      }
      notify = Notification({
        title: i18n.t('SystemMessage'),
        type: notifyType,
        message: h(
          'div',
          {
            style: {
              cursor: 'pointer',
              textAlign: 'left',
              whiteSpace: 'pre-line'
            }
          },
          [
            contentText,
            h(
              'span',
              {
                style: {
                  color: '#4D03D4',
                  fontSize: '14px',
                  paddingLeft: '10px'
                }
              },
              i18n.t('View') + '>'
            )
          ]
        ),
        onClick: () => {
          notify.close()
          emitEvent(EventTypes.NotificationClick, msg)
          if (msg.type === 'monitorEvent') {
            router.push({ name: 'monitoring_list' })
          } else if (msg.type === 'unbindDevice') {
            router.push({ name: 'approval_device_clear' })
          } else if (msg.type === 'vlageDsbrse') {
            router.push({
              name: 'EmeraldBulkDisburseManageDetail',
              query: { id: msg.bulkId }
            })
          }
        }
      })
    }
  },
  mutations: {
    updateClearDeviceCount(state, data) {
      state.msgCount = {
        ...state.msgCount,
        [PageURL.ClearDevice]: data
      }
    }
  }
}
